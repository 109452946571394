import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { IncidentsService } from 'src/app/modules/shared/services/incidents.service';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { DocumentCategoryService } from 'src/app/modules/shared/services/documentCategory.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { IncidentSubTypes } from 'src/app/modules/incidents/enums/incidentSubTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { SeverityViewModel } from 'src/app/modules/shared/models/severityViewModel';
import { ZoneViewModel } from 'src/app/modules/shared/viewModels/zoneViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { IDropdownState } from 'src/app/modules/shared/models/interfaces/dropdown-state.interface';
import { EmployeeViewModel } from 'src/app/modules/shared/viewModels/employeeViewModel';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { IncidentStatuses } from 'src/app/modules/incidents/enums/incidentStatuses';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { IncidentDetailsItemViewModel } from 'src/app/modules/incidents/viewModels/incidentDetailsItemViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import * as moment from 'moment';
import { SearchDropdownComponent } from 'src/app/modules/shared/components/rebuild/search-dropdown/search-dropdown.component';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { FilterToViewModelUtilityService } from 'src/app/modules/shared/services/utilities/filterToViewModelUtilityService';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { UploadTypes } from 'src/app/modules/shared/enums/uploadTypes';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FixedFilters } from 'src/app/modules/shared/enums/fixedFilters.enum';
import { IncidentMapComponent } from 'src/app/modules/shared/components/common/maps/incident-map/incident-map.component';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { LocationUtilities } from 'src/app/modules/shared/utilities/location.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FixedZoneFiltersService } from 'src/app/modules/shared/services/fixed-zone-filters.service';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { IncidentCategoriesService } from 'src/app/modules/settings/services/incidents/incident-categories.service';
import { IncidentCategoryViewModel } from 'src/app/modules/incidents/viewModels/incidentCategoryViewModel';

@Component({
  selector: 'app-incident-add-modal',
  templateUrl: './incident-add-modal.component.html',
  styleUrls: ['./incident-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentAddModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('incident-map ') incidentMap: IncidentMapComponent;
  @ViewChild('searchDropdown') searchDropdownComponent: SearchDropdownComponent;
  @Input() incidentSubType: IncidentSubTypes = IncidentSubTypes.General;
  @Output() closed = new EventEmitter();

  // added for PIR linking
  public itemCreated: Subject<{item: IncidentDetailsItemViewModel, objectType: ObjectTypes}> = new Subject();

  modalHeader = 'Add an Incident';
  currentAccount: Account;
  currentEmployee: Employee;
  incident: IncidentDetailsItemViewModel = new IncidentDetailsItemViewModel();
  beads: ThreadedBead[] = [
    { icon: 'edit', description: 'Details', active: true },
    { icon: 'location_on', description: 'Location', active: false },
    { icon: 'create_new_folder', description: 'Uploads', active: false },
    { icon: 'supervised_user_circle', description: 'Assign', active: false },
  ];

  severityCheckpoint: SeverityViewModel = this.incidentsManager.getSeverityByValue(1);
  currentStep: number = 1;
  quickAddTitle: string = '';
  showConfidentialStatuses: boolean = false;
  subscriptions: Subscription[] = [];
  zonesViewModels: ZoneViewModel[] = [];
  areasViewModels: AreaViewModel[] = [];
  mapZones: ZoneViewModel[] = [];
  mapAreas: AreaViewModel[] = [];
  // Enums
  filterTypes = FilterTypes;
  incidentSubTypes: { key: IncidentSubTypes; value: string }[] = EnumUtilities.items(IncidentSubTypes);
  privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  privacyStatus: { key: PrivacyStatuses; value: string };
  privacyStatusesEnum = PrivacyStatuses;
  activeEditAreaByFilterTypes: { [key: number]: boolean } = {};
  loading: boolean = false;
  showPrivacyStatus: boolean = true;
  showDueDateAndStartDate: boolean = false;
  documentCategories: DocumentCategoryViewModel[] = [];
  objectType: ObjectTypes = ObjectTypes.IncidentItem;
  // Override form with a pre-populated Incident
  usePopulatedIncident: boolean = false;
  uploadDocumentViewModels: UploadViewModel[] = [];
  useDefaultLocation: boolean = false;
  defaultLocation: LocationViewModel;
  showPickers = false;
  start: string;
  due: string;
  selectedZone: FilterViewModel;
  areaFilters: FilterViewModel[] = [];
  zoneFilters: FilterViewModel[] = [];
  applicableAreaFilters: FilterViewModel[] = [];
  public markerType = MarkerType.INCIDENT;
  public localisedIncident = 'Incident';
  public localisedOwners: string;
  public localisedDepartments: string;
  public localisedZones: string;
  public localisedAreas: string;
  //new
  isTouched: boolean = false;
  public categoryId: number;
  public selectedCategory: IncidentCategoryViewModel;
  public preselectedCategoryId: number;
  public preselectedCategoryFilter: FilterViewModel;
  public initialSelectedZone: FilterViewModel;
  public incidentForm: UntypedFormGroup;
  public filters: FilterViewModel[] = [];
  public readonly T = T;
  public readonly objectTypes = ObjectTypes;
  private zones: ZoneViewModel[] = [];
  filteredStatuses: { key: PrivacyStatuses; value: string }[];

  constructor(
    private readonly incidentCategoriesService: IncidentCategoriesService,
    public readonly incidentsManager: IncidentsManager,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    public readonly bsModalRef: BsModalRef,
    private readonly incidentsService: IncidentsService,
    private readonly router: Router,
    private readonly documentService: DocumentService,
    private readonly documentCategoryService: DocumentCategoryService,
    private readonly cachingService: CachingService,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly localisationService: LocalisationService,
    private readonly confirmationService: ConfirmationService,
    private readonly alertService: AlertService,
    private readonly filterToViewModelUtilityService: FilterToViewModelUtilityService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly locationService: LocationService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private fb: UntypedFormBuilder,
    private readonly translateService: TranslateService,
    private readonly fixedZoneService: FixedZoneFiltersService,
    private readonly wtValidators: WtValidators,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.closed.emit();

    this.initModalData();

    if(this.filters && this.filters.length > 0) {
      const categoryFilter = this.filters.find(x => x.filterType === FilterTypes.Incident_Category);
      if(categoryFilter) {
        this.preselectedCategoryId = +categoryFilter.filterValue;
      }
    }

    if (this.preselectedCategoryId) {
      this.getSelectedIncidentCategory(this.preselectedCategoryId);

      if(this.preselectedCategoryFilter) this.filters.push(this.preselectedCategoryFilter);
    }

    this.locationService.getZones().subscribe((res) => {
      this.zones = res;
      this.initNewIncident();
      this.setZonesAndAreas();

      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });

    this.incidentForm = this.fb.group({
      title: [
        this.quickAddTitle.length ? this.quickAddTitle : '',
        { validators: [Validators.required, this.wtValidators.title(3, 250), this.wtValidators.restrictedChars([';', '<', '>', '!'])] },
      ],
      description: ['', Validators.maxLength(1000)],
    });

    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.localiseItems();
    this.areaFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Area);
    this.zoneFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Zone);
    this.privacyStatus = this.privacyStatuses.find((x) => x.key === PrivacyStatuses.Open);

    if (this.incidentSubType === IncidentSubTypes.Job) {
      this.showDueDateAndStartDate = !this.showDueDateAndStartDate;
      this.showPrivacyStatus = !this.showPrivacyStatus;
    }

    this.subscriptions.push(
      this.documentCategoryService.getDocumentCategories().subscribe((res) => (this.documentCategories = res))
    );

    this.subscriptions.push(
      this.accountSettingsService.getUseDefaultLocationSetting().subscribe((res) => {
        this.useDefaultLocation = res;
        this.accountSettingsService.getDefaultLocation().subscribe((res) => {
          this.defaultLocation = res;
          if (this.useDefaultLocation && !this.usePopulatedIncident) {
            this.incident.latitude = this.defaultLocation.latitude;
            this.incident.longitude = this.defaultLocation.longitude;
            this.incident.locationDetails = this.defaultLocation.locationDetails;
          }
        });
      })
    );
    this.translateBeads();
  }

  ngAfterViewInit() {
    this.changeBeadsReference();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  get incidentChannelId() {
    return this.incidentsManager.getIncidentChannelId();
  }

  onDropdownStateChanged(dropdownState: IDropdownState, filterType: FilterTypes) {
    if (!dropdownState.isToggled) {
      if (filterType) {
        return (this.activeEditAreaByFilterTypes[filterType] = false);
      }

      return;
    }
    // debugger

    Object.keys(this.activeEditAreaByFilterTypes)
      .map((k) => +k as FilterTypes)
      .filter((k) => k !== filterType)
      .forEach((k) => (this.activeEditAreaByFilterTypes[k] = false));

    if (filterType) {
      this.activeEditAreaByFilterTypes[filterType] = true;
    }

    this.changeDetectorRef.markForCheck();

    let element: HTMLElement = dropdownState.wrapper;

    while (element) {
      if (element.offsetTop) {
        break;
      }

      element = element.parentElement;
    }
  }

  changeBeadsReference() {
    // changing the array reference ensures
    // change detection will be triggered and
    // the threaded beads' widths will be updated
    // accordingly
    this.beads = this.beads.slice();
    this.changeDetectorRef.markForCheck();
  }

  get currentStepIsValid(): boolean {
    return this.validateCurrentStep(this.currentStep);
  }

  validateCurrentStep(step: number) {
    switch (step) {
      case 1:
        return this.incidentForm.valid;
      case 2:
        return true;
      case 3:
        return true;
      case 4:
        return true;
      default:
        return true;
    }
  }

  get currentStepButton(): string {
    const continueTxt = this.translateService.instant(T.common.continue);
    const skipTxt = this.translateService.instant(T.common.skip);
    switch (this.currentStep) {
      case 1:
        return continueTxt;
      case 2:
        return continueTxt;
      case 3:
        return this.uploadDocumentViewModels.length ? continueTxt : skipTxt;
      case 4:
        return this.filters.filter((f) => f.filterType === FilterTypes.Owner).length ||
          this.filters.filter((f) => f.filterType === FilterTypes.Department).length ||
          this.incident.reportedBy.length ||
          this.incident.privacyStatus ||
          this.filters.filter((f) => f.filterType === FilterTypes.Tag).length
          ? continueTxt
          : skipTxt;
      default:
        return this.translateService.instant(T.common.confirm);
    }
  }

  initNewIncident() {
    this.incident = new IncidentDetailsItemViewModel();
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.incident.title = this.quickAddTitle.length ? this.quickAddTitle : '';
    this.incident.description = '';
    this.incident.reportedBy = '';
    this.incident.accountId = this.currentEmployee.accountId;
    this.incident.createdBy = new EmployeeViewModel(
      this.currentEmployee.id,
      this.currentEmployee.firstName,
      this.currentEmployee.surname,
      0
    );
    this.incident.severity = this.severityCheckpoint.severity;
    this.incident.filters = [];
    this.incident.privacyStatus = PrivacyStatuses.Open;
    this.incident.filters.push(
      this.addFilter(
        FilterTypes.Privacy_Status,
        PrivacyStatuses.Open.toString(),
        this.localisationService.localiseFilterValueByFilterType(PrivacyStatuses.Open, FilterTypes.Privacy_Status)
      )
    );
    this.incident.incidentItemType = IncidentItemTypes.Incident;
    this.incident.filters.push(
      this.addFilter(FilterTypes.Incident_Type, IncidentItemTypes.Incident.toString(), this.localisedIncident)
    );
    this.incident.type = this.incidentSubType;
    this.incident.filters.push(
      this.addFilter(
        FilterTypes.Incident_Severity,
        this.severityCheckpoint.severity.toString(),
        this.severityCheckpoint.description
      )
    );
    if (this.currentAccount.fixedFilters === FixedFilters.Event) {
      this.incident.eventId = Number(this.cachingService.selectedEventID) || 0;
      this.incident.filters.push(this.addFilter(FilterTypes.Event, this.incident.eventId.toString(), ''));
    } else if (this.currentAccount.fixedFilters === FixedFilters.VenueZone) {
      const zoneId = +this.cachingService.selectedZoneID;
      if (zoneId > 0) {
        this.initialSelectedZone = this.fixedZoneService.fixedFilter;
        this.filters.push(this.initialSelectedZone);
      }
    }
    if (this.useDefaultLocation) {
      this.incident.locationDetails = 'n/a';
      this.incident.latitude = 0;
      this.incident.longitude = 0;
    } else if (this.defaultLocation) {
      this.incident.locationDetails = this.defaultLocation.locationDetails;
      this.incident.latitude = this.defaultLocation.latitude;
      this.incident.longitude = this.defaultLocation.longitude;
    }

    if (this.incidentSubType === IncidentSubTypes.Job) {
      this.start = new Date().toISOString();
      this.due = new Date().toISOString();
    }

    if (this.categoryId) {
      this.getSelectedIncidentCategory(this.categoryId);
    }

    this.incident = Object.assign({}, this.incident);
    this.incident.incidentChannelId = this.currentEmployee.defaultIncidentChannel;
    this.incident.filters.push(this.addFilter(FilterTypes.Incident_Channel, this.incidentChannelId.toString(), ''));
    this.changeDetectorRef.detectChanges();
  }

  addFilter(filterType: FilterTypes, value: unknown, text: string, dateProperty: FilterDateOptions = undefined): FilterViewModel {
    const filter = new FilterViewModel();
    filter.filterType = filterType;
    filter.filterValue = value;
    filter.filterText = text;
    filter.dateProperty = dateProperty;
    return filter;
  }

  initPreselectedZone() {
    const zoneId = +this.cachingService.selectedZoneID;
    this.initialSelectedZone = this.zoneFilters.find((x) => x.filterValue == zoneId);
    this.filters.push(this.initialSelectedZone);

    this.handleFilterChange(this.filters);
  }

  initModalData() {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.filteredStatuses = this.localisationService
      .getLocalizedIncidentPrivacyStatuses()
      .filter((s) => s.key !== PrivacyStatuses.Open);
  }

  // DETAILS PAGE CHANGES
  onSeverityCheckpointSelected(severityCheckpoint: SeverityViewModel) {
    this.incident.severity = severityCheckpoint.severity;
    this.severityCheckpoint = severityCheckpoint;
  }

  onPrivacyStatusSelected(privacyStatus: { key: PrivacyStatuses; value: string }) {
    this.privacyStatus = privacyStatus;
    this.incident.privacyStatus = privacyStatus.key;
  }

  onMakeConfidentialChecked(state: boolean) {
    if (state) {
      this.incident.privacyStatus = PrivacyStatuses.Confidential;
    } else {
      this.incident.privacyStatus = PrivacyStatuses.Open;
    }
    this.showConfidentialStatuses = state;
    this.privacyStatus = this.privacyStatuses.find((s) => s.key == this.incident.privacyStatus);
    if (!state) {
      //this.searchDropdownComponent.toggleDropdown(new Event('click'));
    }
    this.changeDetectorRef.markForCheck();
  }

  //LOCATION PAGE CHANGES
  onIncidentLocationUpdated(location: LocationViewModel) {
    this.incident.locationDetails = location.locationDetails;
    this.incident.latitude = location.latitude;
    this.incident.longitude = location.longitude;
  }

  // UPLOADS PAGE CHANGES
  updateUploadViewModels(uploadViewModels: UploadViewModel[]) {
    this.uploadDocumentViewModels = [];
    this.uploadDocumentViewModels = uploadViewModels;
  }

  onBeadClicked(bead: ThreadedBead) {
    const beadPosition = this.beads.findIndex((b) => b === bead) + 1;

    if (beadPosition < this.currentStep) {
      this.currentStep = beadPosition;
    } else if (beadPosition === this.currentStep) {
      return;
    } else {
      if (!this.currentStepIsValid) {
        return;
      }

      this.currentStep = beadPosition;
    }

    if (this.currentStep === 1) {
      this.incident = Object.assign({}, this.incident);

      this.changeDetectorRef.detectChanges();
    }

    this.updateBeads();
    this.changeDetectorRef.markForCheck();
  }

  get severityColor(): string {
    return this.incidentsManager.getColorBySeverity(this.incident.severity);
  }

  // GENERAL FORM METHODS
  private increaseStep() {
    this.currentStep++;
  }

  private decreaseStep() {
    this.currentStep--;
    if (this.currentStep === 1) {
      this.incident = Object.assign({}, this.incident);
      this.changeDetectorRef.detectChanges();
    }
  }

  private updateBeads() {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i < this.currentStep && this.currentStep < 5; i++) {
      this.beads[i].active = true;
    }

    if (this.currentStep === 5) {
      this.beads.forEach((b) => (b.active = true));
    }
  }

  private onIncreaseStep() {
    this.increaseStep();
    this.updateBeads();
    // we need to markForCheck since we have not changed any references
    this.changeDetectorRef.markForCheck();
  }

  private onDecreaseStep() {
    this.decreaseStep();
    this.updateBeads();
    // we need to markForCheck since we have not changed any references
    this.changeDetectorRef.markForCheck();
  }

  // INCIDENT CONFIRMATION
  onConfirmAsDraft() {
    if (this.currentStep === 5) {
      this.incident.status = IncidentStatuses.Draft;
      this.addIncident();
    }
    this.onIncreaseStep();
  }

  onConfirm() {
    if (this.currentStep === 5) {
      this.incident.status = IncidentStatuses.Open;
      this.addIncident();
    }

    this.onIncreaseStep();

    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onSaveAndExit() {
    this.incident.status = IncidentStatuses.Open;
    this.incident.filters.push(this.addFilter(FilterTypes.Incident_Status, IncidentStatuses.Open.toString(), ''));
    this.addIncident();
  }

  addIncident() {
    this.loading = true;
    this.incident.filters = this.filters;

    if (this.incidentForm.valid) {
      this.incident.title = this.incidentForm.controls.title.value.trim() as string;
      this.incident.description = this.incidentForm.controls.description.value.trim() as string;
    }

    this.incident.filters.push(this.addFilter(FilterTypes.Date, this.due, '', FilterDateOptions.Due_Date));
    this.incident.filters.push(this.addFilter(FilterTypes.Incident_Status, IncidentStatuses.Open.toString(), ''));

    if (!this.incident.startTime) {
      this.incident.startTime = moment().toISOString();
      this.incident.filters.push(this.addFilter(FilterTypes.Date, moment().toString(), '', FilterDateOptions.Start_Date));
    } else {
      this.incident.filters.push(this.addFilter(FilterTypes.Date, this.start, '', FilterDateOptions.Start_Date));
    }

    //Added to make sure the Event association works correctly
    const preselectedEventFilter = this.filters.find(f => f.filterType === this.filterTypes.Event);
    if(preselectedEventFilter) this.incident.eventId = +preselectedEventFilter.filterValue;

    this.incidentsService.add(this.incident).subscribe((validateModel) => {
      void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedIncident }));
      this.incident = validateModel.returnModel as IncidentDetailsItemViewModel;
      this.itemCreated.next({item: validateModel.returnModel as IncidentDetailsItemViewModel, objectType: this.objectType});

      this.uploadDocumentViewModels.forEach((element) => {
        element.globalObjectId = this.incident.id;
      });

      this.documentService.addDocuments(this.uploadDocumentViewModels).subscribe(() => {
        this.currentStep = 6;
        this.loading = false;

        const model = validateModel.returnModel as IncidentDetailsItemViewModel;
        this.objectEventEmitters.broadcastObjectAdded(model.id, this.objectType, model);
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  onAddAnotherIncident() {
    this.currentStep = 1;
    this.filters = [];
    this.quickAddTitle = '';
    this.uploadDocumentViewModels = [];
    this.severityCheckpoint = this.incidentsManager.getSeverityByValue(1);
    this.updateBeads();
    this.initNewIncident();
  }

  onCancel() {
    if (this.currentStep === 1) {
      this.clearUploads();
      return this.bsModalRef.hide();
    }

    this.onDecreaseStep();

    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  private clearUploads() {
    if (this.uploadDocumentViewModels && this.uploadDocumentViewModels.length) {
      this.uploadDocumentViewModels.forEach((uploadModel) => {
        if (uploadModel.uploadType === UploadTypes.File) {
          this.subscriptions.push(this.documentService.deleteUnassignedFile(uploadModel.fileName).subscribe());
        }
      });
    }
  }

  confirmClose() {
    if (this.currentStep === 6) {
      this.onClose();
    } else {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.onClose();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    }
  }
  onClose() {
    this.clearUploads();
    this.closed.emit();
    return this.bsModalRef.hide();
  }

  onEdit(currentStep: number) {
    this.currentStep = currentStep;
    this.updateBeads();
  }

  onViewIncidentDetails() {
    void this.router.navigate(['/v2/control/list/' + this.incident.id.toString()]);
    this.onClose();
  }

  // SUMMARY METHODS

  get incidentCategorySummary(): string {
    const matchingFilters = this.filters.filter((f) => f.filterType === FilterTypes.Incident_Category);
    return matchingFilters ? matchingFilters.map((l) => l.filterText).join(', ') : '';
  }

  get incidentTagSummary(): string {
    const matchingFilters = this.filters.filter((f) => f.filterType === FilterTypes.Tag);
    return matchingFilters ? matchingFilters.map((l) => l.filterText).join(', ') : '';
  }
  get incidentPrivacyString(): string {
    if (isNaN(this.incident.privacyStatus)) {
      return 'n/a';
    }

    return this.privacyStatuses.find((e) => e.key === this.incident.privacyStatus).value;
  }

  get incidentDepartmentSummary(): string {
    const matchingFilters = this.filters.filter((f) => f.filterType === FilterTypes.Department);
    return matchingFilters ? matchingFilters.map((l) => l.filterText).join(', ') : '';
  }

  get incidentOwnerSummary(): string {
    const matchingFilters = this.filters.filter((f) => f.filterType === FilterTypes.Owner);
    return matchingFilters ? matchingFilters.map((l) => l.filterText).join(', ') : '';
  }

  onEndDateChanged(dateAsIsoString: string) {
    this.due = dateAsIsoString;
  }

  onStartDateChanged(dateAsIsoString: string) {
    this.start = dateAsIsoString;
  }

  onStartTimeChanged(dateAsObj: string) {
    this.incident.startTime = dateAsObj;
  }

  onEndTimeChanged(dateAsObj: string) {
    this.incident.endTime = dateAsObj;
  }

  onShowPickersChanged(state: boolean) {
    if (!state) {
      this.incident.endTime = undefined;
      this.incident.startTime = undefined;
    }
    this.showPickers = state;
  }

  //#region rebuild
  handleFilterChange(filters: FilterViewModel[], filterType: FilterTypes = null) {
    let newAppliedFilters = [...filters];

    if (filterType && filterType === FilterTypes.Incident_Category) {
      const categoryId = filters?.find(x => x.filterType === FilterTypes.Incident_Category)?.filterValue as number;

      if(categoryId > 0) {
        this.getSelectedIncidentCategory(categoryId);
      } else {
        newAppliedFilters = this.removeSelectedAssociatedCategoryFilters(filters);
      }
    }
    this.selectedZone = filters.find((f) => f.filterType === FilterTypes.Zone);
    this.filters = newAppliedFilters;
    this.setZonesAndAreas();

    if (filters.length > 1) {
      this.isTouched = true;
    }

    this.changeDetectorRef.detectChanges();
  }

  getFiltersByType(filterType: FilterTypes): FilterViewModel[] {
    let result: FilterViewModel[] = [];

    const matchingFilters = this.filters.filter((f) => f.filterType === filterType);

    if (matchingFilters) {
      result = matchingFilters;
    }

    return result;
  }

  getFilterValuesByFilterType(filterType: FilterTypes): string {
    const matchingFilterValues = this.filters.filter((f) => f.filterType === filterType).map((x) => x.filterText);

    return matchingFilterValues.join('; ');
  }

  setInitialZonePin() {
    this.incident = LocationUtilities.setLocationDetails(this.mapZones, this.incident) as IncidentDetailsItemViewModel;

    this.changeDetectorRef.detectChanges();
  }

  setZonesAndAreas() {
    this.mapZones = this.filterToViewModelUtilityService.getZoneViewModelsFromFilters(this.filters, this.zones);
    this.mapAreas = this.filterToViewModelUtilityService.getAreaViewModelsFromFilters(this.filters);

    this.setApplicableFilters();
    this.changeDetectorRef.markForCheck();
  }

  private setApplicableFilters() {
    if (this.mapZones && this.mapZones.length) {
      const areas: FilterViewModel[] = [];
      this.mapZones.forEach((zone) => {
        const zoneAreas = this.areaFilters.filter((a) => +a.relatedObjectId === zone.id);

        if (zoneAreas) {
          areas.push(...zoneAreas);
        }
      });
      this.applicableAreaFilters = areas.slice();

      this.filters.forEach((filter) => {
        if (filter.filterType === FilterTypes.Area) {
          if (!this.mapZones.some((zone) => zone.id === filter.relatedObjectId)) {
            this.filters = this.filters.filter((x) => x.filterValue != filter.filterValue);
          }
        }
      });

      if (!this.incident.latitude && !this.incident.longitude && this.filters.some((x) => x.filterType === FilterTypes.Zone)) {
        this.setInitialZonePin();
      }
    } else {
      this.mapAreas = [];
      this.applicableAreaFilters = [];
      this.filters = this.filters.filter((x) => x.filterType !== FilterTypes.Area);
    }
  }

  private translateBeads() {
    this.beads[0].description = this.translateService.instant(T.common.details);
    this.beads[1].description = this.translateService.instant(T.common.location.one);
    this.beads[2].description = this.translateService.instant(T.common.uploads);
    this.beads[3].description = this.translateService.instant(T.common.assign);
  }

  private localiseItems() {
    this.localisedIncident = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem);
    this.localisedOwners = this.localisationService.localiseFilterType(FilterTypes.Owner, true);
    this.localisedDepartments = this.localisationService.localiseFilterType(FilterTypes.Department, true);
    this.localisedZones = this.localisationService.localiseObjectType(ObjectTypes.Zone, true);
    this.localisedAreas = this.localisationService.localiseObjectType(ObjectTypes.Area, true);
  }

  public getSelectedIncidentCategory(categoryId: number) {
    this.loading = true;
    this.incidentCategoriesService.details(categoryId).subscribe(res => {
      this.selectedCategory = res;

      if (this.selectedCategory.severity !== 0) {
        this.incident.severity = this.selectedCategory.severity;
        this.severityCheckpoint = this.incidentsManager.getSeverityByValue(this.selectedCategory.severity);
      }

      if (this.selectedCategory.privacyStatus !== PrivacyStatuses.Open) {

        this.privacyStatus = this.privacyStatuses.find((x) => x.key === this.selectedCategory.privacyStatus);
        this.incident.privacyStatus = this.privacyStatus.key;
        this.showConfidentialStatuses = true;
      }

      this.selectedCategory.filters.forEach(filter => {
        if (filter.filterType === FilterTypes.Owner || filter.filterType === FilterTypes.Department || filter.filterType === FilterTypes.User_Group) {
          if (this.filters.find(x => x.filterType === filter.filterType && x.filterValue == filter.filterValue) === undefined) {
            this.filters.push(filter);
          }
        }
        if (filter.filterType === FilterTypes.Description) {
          this.filters.push(filter);
          this.incident.description = filter.filterValue as string;
          this.incidentForm.controls.description.setValue(this.incident.description);
        }
      });

      this.loading = false;
      this.changeDetectorRef.detectChanges();
    })
  }

  public removeSelectedAssociatedCategoryFilters(appliedFilters: FilterViewModel[]): FilterViewModel[] {
    this.incident.severity = null;

    this.selectedCategory?.filters.forEach(f => {
      if (f.filterType === FilterTypes.Owner || f.filterType === FilterTypes.Department || f.filterType === FilterTypes.User_Group) {
        appliedFilters = appliedFilters.filter(x => x.filterValue !== f.filterValue);
      }

      if (f.filterType === FilterTypes.Description) {
        appliedFilters = appliedFilters.filter(x => x.filterValue !== f.filterValue);

        this.incident.description = null;;
        this.incidentForm.controls.description.setValue('');
      }

      if (f.filterType === FilterTypes.Incident_Category) {
        appliedFilters = appliedFilters.filter(x => x.filterType !== FilterTypes.Incident_Category);
      }
    });

    this.selectedCategory = null;
    this.preselectedCategoryId = null;

    return appliedFilters;
  }
}
