<div #wrapper class="select-account">
  <div class="select-account-section select-account-section-idle">
    Select an Account
    <div class="select-account-section-close" (click)="bsModalRef.hide()">
      <div class="responsive-flex-wrapper">
        <i class="material-icons">close</i>
      </div>
    </div>
  </div>
  <div class="select-account-content">
    <div class="select-account-content-card" *ngFor="let account of accounts" (click)="onLoginWithAccount(account)">
      <div class="select-account-content-card-image-account">
        <img
          [src]="localUrl + '/Resource/assets/clients/logo/' + account.logo"
          class="select-account-content-card-image-account-image"
        />
        <div class="select-account-content-card-image-account-account">{{ account.title }}</div>
      </div>

      <div class="select-account-content-card-last-logged-in">
        {{ loggedInDays(account) }}
        <div *ngIf="!account.isActive" class="select-account-content-card-last-logged-in-inactive-indicator">
          inactive
        </div>
        <div class="responsive-flex-wrapper" *ngIf="account.isActive">
          <i class="material-icons">arrow_forward</i>
        </div>
      </div>
    </div>
  </div>
  <div class="select-account-section select-account-section-action" (click)="onLogout()">Logout</div>
</div>
