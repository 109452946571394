<ng-container *ngIf="!loading">
  <div #left class="left custom-scrollbar" [class.collapsed]="collapsed">
    <ng-container *ngIf="!collapsed">
      <ng-container *ngTemplateOutlet="leftSideTemplate"></ng-container>
      <ng-container *ngIf="!useCustomList && selectedItem && showSelectedItem">
        <div class="helper-text">
          <ng-template [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{ item: selectedItem }">
          </ng-template>
        </div>
      </ng-container>
      <div class="helper-text" *ngIf="!useCustomList && showHelperAndSort">
        {{ T.common.other_items | translate: { items: itemName } }} ({{ items?.length }}):
        <app-button-transparent [text]="T.common.sort | translate" class="ml-auto"></app-button-transparent>
      </div>
      <div class="internal-paginated-cards-wrapper" *ngIf="isDataLoaded">
        <app-paginated-cards
          [items]="items"
          *ngIf="!useCustomList"
          [cardTemplate]="cardTemplate"
          [pageChangeSubject]="pageChangeSubject"
        >
        </app-paginated-cards>
      </div>
      <ng-container *ngIf="useCustomList">
        <ng-container *ngTemplateOutlet="customListComponentTemplate"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="right" [class.collapsed]="collapsed">
    <div class="toggle-wrapper" (click)="onExpandCollapse($event)">
      <div class="responsive-flex-wrapper">
        <i class="material-icons">skip_previous</i>
      </div>
    </div>
    <div class="right__inner-wrapper custom-scrollbar">
      <ng-container *ngTemplateOutlet="rightSideTemplate"></ng-container>
    </div>
  </div>
</ng-container>
<app-loading *ngIf="loading" [displayLogo]="true">
  <p>{{ T.common.loading | translate }}...</p>
</app-loading>
