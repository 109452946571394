<!-- Desktop filters -->
<ng-container *ngIf="!mobile">
  <div
    class="desktop-filters"
    resized
    (resizable)="onResized()"
    ngBlockScreen
    (blockScreenClicked)="onBlockScreenClicked()"
  >
    <app-fixed-filter-lozenges></app-fixed-filter-lozenges>
    <ng-container *ngIf="!hidePartialBasicFilters">
      <div class="desktop-filters-lozenges">
        <!-- Regular lozenges filteredFilters -->
        <ng-container *ngFor="let ft of filteredFilters | keyvalue">
          <ng-container *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag">
            <app-filters-lozenge-container
              #desktopFilterLozenge
              [appliedFiltersByType]="ft.value"
              [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
              [isSingleSelect]="
                getIsSingleSelectHeaderFilter(ft.value[0].displayForGlobalObjectType, ft.value[0].filterType)
              "
              (filtersUpdated)="onFiltersChanged($event)"
            >
            </app-filters-lozenge-container>
          </ng-container>
        </ng-container>
        <!-- Tag Lozenges -->
        <ng-container *ngFor="let ft of filteredTagsFilters | keyvalue">
          <ng-container>
            <app-filters-lozenge-container
              #desktopTagFilterLozenge
              [appliedFiltersByType]="ft.value"
              (filtersUpdated)="onFiltersChanged($event)"
            >
            </app-filters-lozenge-container>
          </ng-container>
        </ng-container>
      </div>

      <div class="desktop-filters-buttons" [class.loading-filters]="isFiltersLoading">
        <div
          class="desktop-filters-buttons-section desktop-filters-buttons-section-expand-collapse"
          *ngIf="appliedFilters.length"
          [class.desktop-filters-buttons-section-expand-collapse-disabled]="isHiddenEmpty"
          (click)="!isHiddenEmpty ? onExpandCollapse() : undefined"
        >
          <i class="material-icons">{{ expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
          <div class="desktop-filters-idle-description">
            {{ T.common.count_filters | keyPlural: appliedFilters.length | translate: { count: appliedFilters.length } }}
          </div>
        </div>
        <div
          class="desktop-filters-buttons-section"
          [class.desktop-filters-buttons-section-separator]="appliedFilters.length"
          (click)="onAdd()"
        >
          <i class="material-icons material-icons-idle">add_circle_outline</i>
          <i class="material-icons material-icons-hover">add_circle</i>
          <div
            data-intercom-target="Add Filter"
            class="desktop-filters-action-description"
            *ngIf="!appliedFilters.length"
          >
            {{ T.common.add_filter | translate }}
          </div>
        </div>

        <div
          *ngIf="!isAccountHubUrl()"
          class="desktop-filters-buttons-section"
          [class.desktop-filters-buttons-section-separator]="appliedFilters.length"
          (click)="onAddFavorite()"
        >
          <i class="material-icons material-icons-idle">favorite_border</i>
          <i class="material-icons material-icons-hover">favorite</i>
          <div class="desktop-filters-action-description" *ngIf="!appliedFilters.length">
            {{ T.common.my_filters | translate }}
          </div>
        </div>
        <div
          class="desktop-filters-buttons-section desktop-filters-buttons-section-clear"
          *ngIf="appliedFilters.length"
          (click)="onClear()"
        >
          <i class="material-icons material-icons-idle">highlight_off</i>
          <i class="material-icons material-icons-hover">cancel</i>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="desktop-filters-expanded" *ngIf="expanded">
    <!-- Regular lozenges  -->
    <ng-container *ngFor="let ft of hiddenFilterTypes | keyvalue">
      <ng-container *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag">
        <app-filters-lozenge-container
          #hiddenDesktopFilterLozenge
          [appliedFiltersByType]="ft.value"
          [isSingleSelect]="
            getIsSingleSelectHeaderFilter(ft.value[0].displayForGlobalObjectType, ft.value[0].filterType)
          "
          [allAllowedFilters]="filters"
          [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
          (filtersUpdated)="onFiltersChanged($event)"
        >
        </app-filters-lozenge-container>
      </ng-container>
    </ng-container>

    <!-- Tag Lozenges -->
    <ng-container *ngFor="let ft of hiddenTagsFilterTypes | keyvalue">
      <app-filters-lozenge-container
        #hiddenDesktopTagFilterLozenge
        [appliedFiltersByType]="ft.value"
        [isSingleSelect]="getIsSingleSelectHeaderFilter(ft.value[0].displayForGlobalObjectType, ft.value[0].filterType)"
        (filtersUpdated)="onFiltersChanged($event)"
      >
      </app-filters-lozenge-container>
    </ng-container>
  </div>
</ng-container>

<!-- Desktop filters -->

<!--Mobile-->
<ng-container *ngIf="mobile">
  <div
    class="mobile-filters"
    resized
    (resizable)="onResized()"
    ngBlockScreen
    (blockScreenClicked)="onBlockScreenClicked()"
  >
    <app-fixed-filters-indicators (click)="onExpandCollapse()"></app-fixed-filters-indicators>
    <div
      *ngIf="!isAccountHubUrl()"
      class="mobile-filters-action"
      [class.loading-filters]="isFiltersLoading"
      (click)="onExpandCollapse()"
    >
      <ng-container *ngIf="!appliedFilters.length; else appliedFiltersCounter">
        <i class="material-icons">add_circle_outline</i>
        <div class="mobile-filters-action-description">{{ T.common.add_filter | translate }}</div>
      </ng-container>
      <ng-template #appliedFiltersCounter>
        <i class="material-icons material-icons-expand-collapse">{{
          expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
        }}</i>
        <div class="mobile-filters-action-description">{{ appliedFilters.length }} Filters</div>
      </ng-template>
    </div>
    <div *ngIf="!isAccountHubUrl()" class="mobile-filters-action ms-auto" (click)="onAddFavorite()">
      <i class="material-icons">favorite_outline</i>
    </div>
    <div class="mobile-filters-container" *ngIf="expanded">
      <div style="flex: 1">
        <div class="mobile-filters-container-lozenges">
          <app-fixed-filter-lozenges></app-fixed-filter-lozenges>

          <!-- Regular lozenges -->
          <ng-container *ngFor="let ft of filteredFilters | keyvalue">
            <ng-container *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag">
              <app-filters-lozenge-container
                #mobileFilterLozenge
                [appliedFiltersByType]="ft.value"
                [allAllowedFilters]="filters"
                [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
                (filtersUpdated)="onFiltersChanged($event)"
              >
              </app-filters-lozenge-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="mobile-filters-container-actions" (click)="onExpandCollapse()">
        <div class="mobile-filters-container-actions-buttons">
          <app-button-rectangle
            style="width: 45%"
            [fitToContainer]="true"
            [size]="'small'"
            [buttonTheme]="'danger'"
            [text]="'Clear All'"
            (clicked)="onClear()"
          ></app-button-rectangle>
          <app-button-rectangle
            style="width: 45%"
            [fitToContainer]="true"
            [size]="'small'"
            [buttonTheme]="'primary'"
            [text]="'Add Filter'"
            (clicked)="onAdd()"
          ></app-button-rectangle>
        </div>
        <app-button-bar-rounded
          [fitToContainer]="true"
          [isOpen]="true"
          [text]="'Hide Filters'"
        ></app-button-bar-rounded>
      </div>
    </div>
  </div>
  <app-filters-add
    *ngIf="mobileFiltersAddContainerVisible"
    [mobile]="mobile"
    [filters]="filters"
    [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
    (closed)="onCloseAdd()"
    (filterAdded)="onFiltersTypeAdd($event)"
    (tagGroupSelected)="onTagGroupSelected($event)"
  ></app-filters-add>

  <app-filter-tag
    *ngIf="selectedTagGroup"
    [tagGroup]="selectedTagGroup"
    [mobile]="mobile"
    [filters]="filters"
    [appliedFilters]="appliedFilters"
    [filterTypeSelectorViewModel]="tagFilterTypeSelectorViewModel"
    class="filters-inline-edit-container-absolute"
    (FilterChanged)="onFiltersChanged($event)"
  >
  </app-filter-tag>

  <app-filters-favorite
    *ngIf="mobileFiltersAddFavoriteContainerVisible"
    [mobile]="mobile"
    [appliedFilters]="appliedFilters"
    [favoriteFilters]="favoriteFilters"
    (favoriteFiltersApplied)="onFavoriteFiltersApplied($event)"
    (closed)="onCloseAddFavorite()"
  ></app-filters-favorite>
</ng-container>
<!--Mobile-->
