<div class="card">
  <div resized (resizable)="onResized($event)" class="card-body">
    <div class="card-title">
      <h5
        class="location"
        #dropdownWeatherlocations
        [class.dropdown-toggle]="true"
        style="font-size: 1rem; cursor: pointer"
        [app-popup]="{
          templateRef: weatherLocationTemplate,
          position: 'bottom',
          arrowPosition: ['top', 'center'],
          paddingless: true
        }"
      >
        {{ weatherLocationSelected ? weatherLocationSelected.locationDetails : 'n/a' }}
      </h5>

      <a class="drag-handler">
        <i class="material-icons"> drag_handle </i>
      </a>
    </div>

    <ng-template #weatherLocationTemplate>
      <app-dropdown-text-item
        *ngFor="let w of weatherLocations"
        [dropdownItem]="w"
        [bindProperty]="'locationDetails'"
        [selected]="weatherLocationSelected.locationDetails === w.locationDetails"
        (dropdownItemSelected)="onWeatherLocationSelected($event)"
      >
      </app-dropdown-text-item>
    </ng-template>

    <ng-container *ngIf="!loading">
      <div *ngIf="weatherAPIResponse" #forecastContainer id="forecast-container">
        <div *ngFor="let item of dailyData; let i = index" class="day-column">
          <div class="day-label">{{ getWeekDayName(item.dt) }}</div>
          <div class="icon-forecast">
            <img src="{{ getWeatherIcon(item) }}" />
          </div>
          <div class="temp-range">
            <span class="max-temp">{{ getDailyMinTemp(item) }}°</span>
            <span class="min-temp">{{ getDailyMaxTemp(item) }}°</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="loading">
    <app-loading [displayLogo]="true">
      <p>{{ loadingMessage }}</p>
    </app-loading>
  </ng-container>
</div>
