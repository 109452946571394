import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ButtonThemes } from '../../../../enums/styles/buttonThemes.enum';
import { ButtonClasses } from '../../../../enums/styles/buttonClasses.enum';
import { ButtonSizes } from '../../../../enums/styles/buttonSizes.enum';

@Component({
  selector: 'app-button-rectangle',
  templateUrl: './button-rectangle.component.html',
  styleUrls: ['./button-rectangle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonRectangleComponent {
  @Input() icon: string;
  @Input() svg: string;
  @Input() size: ButtonSizes = ButtonSizes.Small;
  @Input() text: string;
  @Input() fitToContainer = false;
  @Input() disabled = false;
  @Input() buttonTheme: ButtonThemes = ButtonThemes.Primary;
  @Input() isTextTruncate = false;
  @Input() loading = false;
  @Input() borderColor = '';
  @Input() textColor = '';
  @Input() selected = false;
  @Input() showDropdownArrow: boolean = false;

  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

  getButtonClasses() {
    const classesArr: ButtonClasses[] = [];
    classesArr.push(ButtonClasses[this.size]);
    classesArr.push(ButtonClasses[this.buttonTheme]);
    if (this.isTextTruncate) {
      classesArr.push(ButtonClasses.truncated);
    }
    if (this.selected) {
      classesArr.push(ButtonClasses.selected);
    }
    return classesArr;
  }

  onClick(e: Event) {
    if (!this.loading) {
      this.clicked.emit(e);
    }
  }
}
