import { DocumentTypes } from 'src/app/modules/shared/enums/documentTypes';
import { UploadTypes } from 'src/app/modules/shared/enums/uploadTypes';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';

export class AttachmentsUtilities {
  private static imageExtensions = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
  private static videoExtensions = ['mp4', 'mkv', 'avi', 'wmv', 'flv', 'mpeg', 'mov'];
  private static documentExtensions = ['doc', 'docx', 'pdf', 'csv', 'txt', 'xls', 'xlsx', 'xlsm'];

  public static getDocumentIcon(documentType: DocumentTypes, ext: string, iconSize: string = '30px'): string {
    if (documentType === DocumentTypes.Link) {
      return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">link</i>`;
    } else if (documentType === DocumentTypes.Video) {
      return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">videocam</i>`;
    } else if (documentType === DocumentTypes.Image) {
      return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">image</i>`;
    } else {
      if (ext === 'pdf') {
        return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">picture_as_pdf</i>`;
      } else if (ext === 'xls' || ext === 'xlsx' || ext === 'xlsm') {
        return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">grid_on</i>`;
      } else return `<i style="font-size: ${iconSize}; color: #DCE1E4;" class="material-icons icon">description</i>`;
    }
  }

  public static getDocumentType(document: DocumentViewModel): {type: DocumentTypes, ext: string} {
    let documentType: DocumentTypes;
    let ext: string;

    if (document.uploadType === UploadTypes.Hyperlink) {
      documentType = DocumentTypes.Link;
    } else if (document.filename) {
      const splitted = document.filename.split('.');
      ext = splitted[splitted.length - 1].toLowerCase();
    }

    if (this.imageExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Image;
    } else if (this.videoExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Video;
    } else if (this.documentExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Document;
    }

    return { type: documentType, ext: ext };
  }

  /// <summary> verify if the provided extension is an image extension </summary>
  public static VerifyImageExtension(ext: string): boolean {
    return this.imageExtensions.indexOf(ext) !== -1;
  }
}
