<div class="list-search-input-wrapper" [formGroup]="form">
  <div class="list-search-input-inner-wrapper">
    <input
      #inputElement
      type="text"
      [placeholder]="placeholder"
      formControlName="input"
      [style.padding]="input.value ? '10px 58px 10px 10px' : '10px 34px 10px 10px'"
    />
    <div class="responsive-flex-wrapper" *ngIf="input.value" (click)="onClearInput()">
      <i class="material-icons">close</i>
    </div>
    <div class="responsive-flex-wrapper">
      <i class="material-icons">search</i>
    </div>
  </div>
</div>
