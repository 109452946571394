<!-- Desktop filters -->

<ng-container *ngIf="!mobile">
  <div
    class="desktop-filters"
    resized
    (resizable)="onResized()"
    ngBlockScreen
    (blockScreenClicked)="onBlockScreenClicked()"
  >
    <div *ngIf="!hideAddFilterButton" class="desktop-filters-buttons" [class.loading-filters]="isFiltersLoading" >
      <div
        class="desktop-filters-buttons-section desktop-filters-buttons-section-expand-collapse"
        *ngIf="appliedFilters.length"
        [class.desktop-filters-buttons-section-expand-collapse-disabled]="isHiddenEmpty"
        (click)="!isHiddenEmpty ? onExpandCollapse() : undefined"
      >
        <i class="material-icons">{{ expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
        <div class="desktop-filters-idle-description">
          {{ T.common.count_filters | keyPlural: filterCount | translate: { count: filterCount } }}
        </div>
      </div>
      <div
        class="desktop-filters-buttons-section"
        [class.desktop-filters-buttons-section-separator]="filterCount"
        (click)="onAdd()"
      >
        <i class="material-icons material-icons-idle">add_circle_outline</i>
        <i class="material-icons material-icons-hover">add_circle</i>
        <div class="desktop-filters-action-description" *ngIf="!filterCount">
          {{ T.common.add_filter | translate }}
        </div>
      </div>
      <div
        class="desktop-filters-buttons-section desktop-filters-buttons-section-clear"
        *ngIf="filterCount"
        (click)="onClear()"
      >
        <i class="material-icons material-icons-idle">highlight_off</i>
        <i class="material-icons material-icons-hover">cancel</i>
      </div>
    </div>

    <div class="desktop-filters-lozenges">
      <!-- used by tags..-->

      <!-- Regular lozenges-->
      <ng-container *ngFor="let ft of filteredFilters | keyvalue">
        <ng-container
          *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag && !isFixedAndHiddenReportTemplateFilter(ft.value[0])"
        >
          <app-filters-lozenge-container
            #desktopFilterLozenge
            [appliedFiltersByType]="ft.value"
            [allAllowedFilters]="filters"
            [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
            (filtersUpdated)="onFiltersChanged($event)"
          >
          </app-filters-lozenge-container>
        </ng-container>
      </ng-container>

      <!-- Tag Lozenges -->
      <ng-container *ngFor="let ft of filteredTagsFilters | keyvalue">
        <ng-container>
          <app-filters-lozenge-container
            #desktopTagFilterLozenge
            [appliedFiltersByType]="ft.value"
            (filtersUpdated)="onFiltersChanged($event)"
          >
          </app-filters-lozenge-container>
        </ng-container>
      </ng-container>
    </div>

    <!-- <app-filter-tag *ngIf="selectedTagGroup" [tagGroup]="selectedTagGroup" [mobile]="mobile" [filters]="filters"
      [appliedFilters]="appliedFilters" [filterTypeSelectorViewModel]="tagFilterTypeSelectorViewModel"
      class="filters-inline-edit-container-absolute" (FilterChanged)="onFiltersChanged($event)">
    </app-filter-tag> -->
  </div>

  <div class="desktop-filters-expanded" *ngIf="expanded">
    <!-- Regular lozenges  -->
    <ng-container *ngFor="let ft of hiddenFilterTypes | keyvalue">
      <ng-container *ngIf="ft.value[0].filterType !== filterTypesEnum.Tag">
        <app-filters-lozenge-container
          #hiddenDesktopFilterLozenge
          [appliedFiltersByType]="ft.value"
          [allAllowedFilters]="filters"
          [filterTypeSelectorViewModels]="filterTypeSelectorViewModels"
          (filtersUpdated)="onFiltersChanged($event)"
        >
        </app-filters-lozenge-container>
      </ng-container>
    </ng-container>

    <!-- Tag Lozenges -->
    <!-- Should be hidden -->
    <ng-container *ngFor="let ft of hiddenTagsFilterTypes | keyvalue">
      <ng-container>
        <app-filters-lozenge-container
          #hiddenDesktopTagFilterLozenge
          [appliedFiltersByType]="ft.value"
          (filtersUpdated)="onFiltersChanged($event)"
        >
        </app-filters-lozenge-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
