<div class="image-preview">
  <div class="image-prevew-wrapper">
    <button class="close-btn" (click)="changeState()">
      <i class="material-icons"> close </i>
    </button>
    <img class="image" [src]="imageUrl" />
    <div>
      <span class="image-title">{{ title }}</span>
      <span class="image-description">{{ description }}</span>
    </div>
  </div>
</div>
