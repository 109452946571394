<div class="show-hide-scale-guide-wrapper">
  <div class="row">
    <div
      *ngIf="showScaleGuideSelectSeverityText"
      [ngClass]="showScaleGuideSelectSeverityText ? 'col-12 col-sm-6' : 'col-12'"
    >
      <div class="scale-title" *ngIf="showScaleGuideSelectSeverityText">
        {{ T.control.select_severity | translate }}
      </div>
    </div>
    <div *ngIf="!isMobile" [ngClass]="showScaleGuideSelectSeverityText ? 'col-12 col-sm-6' : 'col-12'">
      <div class="show-hide-scale-guide" *ngIf="scaleGuideShown" (click)="onShowHideScaleGuideClick($event)">
        {{ T.control.hide_scale_guide | translate }}
        <div class="responsive-flex-wrapper">
          <i class="material-icons">info</i>
        </div>
      </div>
      <div class="show-hide-scale-guide" *ngIf="!scaleGuideShown" (click)="onShowHideScaleGuideClick($event)">
        {{ T.control.show_scale_guide | translate }}
        <div class="responsive-flex-wrapper">
          <i class="material-icons">info</i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="wrapper">
  <div class="separators-wrapper">
    <div
      class="separator"
      #separator
      *ngFor="let separator of separators; let i = index"
      [style.width]="100 / separators.length + '%'"
    ></div>
  </div>
  <div class="checkpoints-state-indicators-wrapper">
    <div
      #checkpointStateIndicator
      class="checkpoint-state-indicator"
      *ngFor="let checkpoint of severities; let i = index"
      [style.background-color]="getProperColor(checkpoint.color)"
      [style.left]="
        'calc(' + (100 / separators.length) * i + '% - ' + checkpointStateIndicatorLeftShiftingByIndex[i].shifting + ')'
      "
    ></div>
  </div>
  <div class="checkpoints-wrapper">
    <div
      #checkPoint
      class="checkpoint"
      *ngFor="let checkpoint of severities; let i = index"
      [style.background-color]="getProperColor(checkpoint.color, false)"
      [style.left]="'calc(' + (100 / separators.length) * i + '% - 4px)'"
    >
      <div
        class="checkpoint-area"
        (mouseover)="onCheckpointHover(i)"
        (mouseout)="onCheckpointHover(i)"
        (click)="onCheckpointClick(i, checkpoint)"
      ></div>
      <app-helium-bubble [color]="getProperColor(checkpoint.color, false)" [value]="checkpoint.severity">
      </app-helium-bubble>
    </div>
  </div>
</div>
<div *ngIf="isMobile || scaleGuideShown" class="scale-guide-wrapper">
  <div class="scale-guide" *ngFor="let s of severities">
    <div class="scale-guide-severity">{{ s.severity }}</div>
    <div class="scale-guide-description">{{ s.description }}</div>
  </div>
</div>
