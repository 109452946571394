import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ButtonClasses } from '../../../../enums/styles/buttonClasses.enum';
import { ButtonSizes } from 'src/app/modules/shared/enums/styles/buttonSizes.enum';

@Component({
  selector: 'app-button-transparent',
  templateUrl: './button-transparent.component.html',
  styleUrls: ['./button-transparent.component.scss'],
})
export class ButtonTransparentComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() textColor = ButtonClasses['primary-text'];
  @Input() disabled = false;
  @Input() isOpen = false;
  @Input() icon: string;
  @Input() iconFontSize = 24;
  @Input() size: string = ButtonSizes.Medium;
  @Input() dropdown = false;

  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

  buttonClasses: string[] = [];

  ngOnInit(): void {
    this.setButtonClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.textColor || changes.size) {
      this.setButtonClasses();
    }
  }

  getButtonClass(): string[] {
    const classesArr: ButtonClasses[] = [];

    classesArr.push(ButtonClasses[this.textColor] ? ButtonClasses[this.textColor] : '');
    classesArr.push(ButtonClasses[this.size] ? ButtonClasses[this.size] : '');
    return classesArr;
  }

  setButtonClasses(): void {
    const classesArr = [];

    classesArr.push(ButtonClasses[this.textColor] ? ButtonClasses[this.textColor] : '');
    classesArr.push(ButtonClasses[this.size] ? ButtonClasses[this.size] : '');
    this.buttonClasses = classesArr;
  }

  onClick(e: Event) {
    this.clicked.emit(e);
  }
}
