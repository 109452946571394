<div
  *ngIf="appliedFiltersByType.length > 0 && !isFixedFilter"
  #filterLozenge
  class="filter-lozenge"
  [class.filter-lozenge-disabled]="editMode"
  [style.padding]="hasIcon ? '8px' : ''"
  [style.max-width]="isDate ? 'unset' : ''"
>
    <ng-container [ngSwitch]="selectorType">
      <ng-container *ngSwitchCase="filterSelectorTypes.Dropdown">
        <i class="material-icons" [style.color]="'var(--wt-red)'" (click)="onRemoved()">highlight_off</i>
        <i class="material-icons" *ngIf="exclude">block</i>
        <div class="lozenge-texts-wrapper" [app-tooltip]="{message: lozengeText, stylingVersion: 2}">
          <div class="filter-lozenge-bold">{{ lozengeLabel }}:</div>
          <div class="filter-lozenge-value">{{ lozengeText }}</div>
          <div class="filter-lozenge-overflowing-count" *ngIf="overflowingCount">+{{ overflowingCount }}</div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="filterSelectorTypes.Single_Checkbox">
        <i class="material-icons" [style.color]="'var(--wt-red)'" (click)="onRemoved()">highlight_off</i>
        <div class="filter-lozenge-value" [app-tooltip]="{message: lozengeText, stylingVersion: 2}">{{ lozengeLabel }}</div>
      </ng-container>
      <ng-container *ngSwitchCase="filterSelectorTypes.Multiple_Checkboxes">
        <i class="material-icons" [style.color]="'var(--wt-red)'" (click)="onRemoved()">highlight_off</i>
        <i class="material-icons" *ngIf="exclude">block</i>
        <div class="lozenge-texts-wrapper" [app-tooltip]="{message: lozengeText, stylingVersion: 2}">
          <div class="filter-lozenge-bold">{{ lozengeLabel }}:</div>
          <div class="filter-lozenge-value">{{ lozengeText }}</div>
          <div class="filter-lozenge-overflowing-count" *ngIf="overflowingCount">+{{ overflowingCount }}</div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="filterSelectorTypes.Date">
        <i class="material-icons" [style.color]="'var(--wt-red)'" (click)="onRemoved()">highlight_off</i>
        <div class="lozenge-texts-wrapper" [app-tooltip]="{message: lozengeText, stylingVersion: 2}">
          <div class="filter-lozenge-bold">{{ lozengeLabel }}:</div>
          <div class="filter-lozenge-value">{{ lozengeText }}</div>
        </div>
      </ng-container>
    </ng-container>
</div>

<div
  *ngIf="appliedFiltersByType.length > 0 && isFixedFilter"
  class="new-filter-lozenge"
>
  <ng-container [ngSwitch]="filterType">
    <ng-container *ngSwitchCase="filterTypes.Incident_Channel">
      <!-- <i class="material-icons" [style.color]="isChannelApplied ? 'var(--wt-red)' : 'var(--wt-grey-dark)'"
        >record_voice_over</i
      > -->
      <div class="filter-lozenge-value">
        {{ filterValue && filterValue !== '0' ? lozengeText + ' Active' : lozengeText }}
      </div>
      <i class="material-icons dropdown-icon">expand_more</i>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.Event">
      <!-- <i class="material-icons" [style.color]="isEventApplied ? 'var(--wt-red)' : 'var(--wt-grey-dark)'">event</i> -->
      <div class="filter-lozenge-value">{{ lozengeText }}</div>
      <i class="material-icons dropdown-icon">expand_more</i>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.Zone">
      <!-- <i class="material-icons" [style.color]="isZoneApplied ? 'var(--wt-red)' : 'var(--wt-grey-dark)'"
        >location_searching</i
      > -->
      <div class="filter-lozenge-value">{{ lozengeText }}</div>
      <i class="material-icons dropdown-icon">expand_more</i>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</div>
