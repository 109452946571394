import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { GanttCachingService } from '../../gantt/services/ganttCaching.service';
import { GanttColumnSize } from '../../gantt/utilities/gantt-column-size';
import { WeatherDataCachingService } from './weather-data/weather-data-caching.service';
import { WeatherDataCache } from '../models/weatherDataCache';
import { WeatherDataService } from './weather-data.service';

let inMemoryStorage: object = {};

@Injectable({
  providedIn: 'root',
})
export class WtStorageService {
  private isNativePlatform: boolean;

  constructor() {
    this.isNativePlatform = Capacitor.getPlatform() !== 'web';
  }

  getItem(key: string): string | null {
    if (this.isNativePlatform) {
      return inMemoryStorage[key];
    } else {
      return localStorage.getItem(key);
    }
  }

  removeItem(key: string): void {
    if (this.isNativePlatform) {
      delete inMemoryStorage[key];
      void Preferences.remove({ key: key });
    } else {
      localStorage.removeItem(key);
    }
  }

  setItem(key: string, data: string): void {
    if (this.isNativePlatform) {
      inMemoryStorage[key] = data;
      void Preferences.set({ key: key, value: data });
    } else {
      localStorage.setItem(key, data);
    }
  }

  clear(): void {
    if (this.isNativePlatform) {
      inMemoryStorage = {};
      void Preferences.clear();
    }

    //We want to keep the dashboard layout, gantt column sizes cache and weather data cache
    const dashboardLayout = JSON.parse(localStorage.getItem('dashboardLayout')) as unknown;
    const ganttColumnSizesCache = JSON.parse(localStorage.getItem(GanttCachingService.localStorageKey)) as GanttColumnSize[];
    const weatherDataCache = JSON.parse(localStorage.getItem(WeatherDataCachingService.localStorageKey)) as WeatherDataCache[];

    localStorage.clear();

    if (dashboardLayout) {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboardLayout));
    }

    if(ganttColumnSizesCache) {
      localStorage.setItem(GanttCachingService.localStorageKey, JSON.stringify(ganttColumnSizesCache));
    }

    if(weatherDataCache) {
      //We want to remove the weather data cache that is older than the predefined expiration time
      const nowTimestamp = new Date().getTime();
      const filteredWeatherDataCache = weatherDataCache.filter((d) => nowTimestamp - d.timestamp < WeatherDataService.cacheExpireTime);

      localStorage.setItem(WeatherDataCachingService.localStorageKey, JSON.stringify(filteredWeatherDataCache));
    }
  }

  /**
   * Because till this moment OAuthStorage works it synchronous data , we need to load the storage in memory
   */
  async loadAllDataInMemoryFromStorage() {
    const keysObj = await Preferences.keys();
    for (const key of keysObj.keys) {
      await Preferences.get({ key }).then((res) => {
        inMemoryStorage[key] = res.value;
      });
    }
  }
}
