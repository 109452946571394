import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() name: string;
  @Input() size: 'inherit' | 'teeny' | 'tiny' | 'small' | 'regular' = 'inherit';
  @Input() variant: 'outlined' | 'round';

  get classnames() {
    const classnames = ['icon'];
    classnames.push(this.variant ? `material-icons-${this.variant}` : 'material-icons');
    classnames.push(`icon-${this.size}`);

    return classnames;
  }
}
