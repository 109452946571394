<div
  class="wrapper"
  [title]="hoverTitle"
  (click)="onClick($event)"
>
    <div
      class="map-control-btn"
      [ngClass]="{ 'mobile-btn': isMobile }"
    >
      <i class="material-icons clear-icon">clear</i>
    </div>
</div>
