<div #wrapper class="onboarding-header" [class.divider-shadow]="currentAccount.isHubAccount">
  <div class="onboarding-header-top" [class.divider-line]="!currentAccount.isHubAccount">
    <img [src]="logoUrl" class="onboarding-header-top-client-logo" />
    <div class="onboarding-header-top-text">
      <div class="onboarding-header-top-text-title">{{ currentAccount.title }}</div>
      <div class="onboarding-header-top-text-info">Set your preferences for this account</div>
    </div>
    <img
      [src]="mobile ? '/assets/images/wetrack-square.png' : '/assets/images/wetrack_logo_colour_rgb.png'"
      class="onboarding-header-top-wetrack-logo"
    />
  </div>
  <div *ngIf="!currentAccount.isHubAccount" class="onboarding-header-beads">
    <app-threaded-beads [beads]="beads" (beadClicked)="onBeadClicked($event)"></app-threaded-beads>
  </div>
</div>

<div class="onboarding-body">
  <div class="onboarding-body-content">
    <ng-container *ngIf="loading">
      <app-loading>Completing your Onboarding....</app-loading>
    </ng-container>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="currentStep === 1">
        <div class="onboarding-body-section" style="margin: 0">
          <div class="onboarding-body-section-heading">Set your notification preferences</div>
          <div class="onboarding-body-section-description">
            You can makes changes to these later in the settings screen.
          </div>
        </div>

        <app-employee-notifications
          [handleUpdateInternally]="true"
          [showAlerts]="false"
          [employeeId]="currentEmployee.id"
        >
        </app-employee-notifications>
      </ng-container>

      <ng-container *ngIf="currentStep === 2">
        <div class="onboarding-body-section" *ngIf="currentAccount.pdfHelpFileName?.length">
          <div class="onboarding-body-section-heading">Download guidelines</div>
          <div class="onboarding-body-section-description">
            You can download {{ currentAccount.title }}’s guidelines for using WeTrack below:
          </div>

          <button
            type="button"
            class="btn action-btn btn-block filter-button onboarding-body-section-download-button"
            (click)="onDownloadGuidelines()"
          >
            <i class="material-icons">save_alt</i>
            <strong>Download</strong>&nbsp;(10MB pdf format)
          </button>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12">
            <div class="onboarding-body-section">
              <div class="onboarding-body-section-description">
                <strong>Help Centre:</strong> Access a help centre with articles, videos, tips and more by clicking the
                Help button (question mark) in the navigation menu.
              </div>

              <div class="onboarding-body-section-description">
                <strong>Send Support Request:</strong> Talk to one of our support team by clicking the Help button (question
                mark) in the navigation menu.<br />
              </div>

              <img [src]="helpCenterImage" class="onboarding-body-section-image" />
            </div>
          </div>
          <div class="col-12 col-sm-12">

          </div>
          <div class="onboarding-body-section">
            <div class="onboarding-body-section-heading">Accessing your modules</div>
            <div class="onboarding-body-section-description">
              {{ getModuleAccessText() }}
            </div>

            <img [src]="moduleSelectorImage" class="onboarding-body-section-image" />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStep === 3">
        <div class="onboarding-body-section">
          <div class="onboarding-body-section-heading">Terms of Use and Privacy Policy</div>
          <div class="onboarding-body-section-description">
            Please confirm you have read and agree to WeTrack's Terms of Use to complete onboarding:<br /><br />
            <a [href]="getAccountTermsOfUse()" target="_blank" class="terms-of-use">
              <i class="material-icons-outlined">handshake</i> &nbsp; Terms of Use </a
            ><br /><br />

            <app-checkbox-with-label
              label="I accept WeTrack's Terms of Use"
              intialState="unchecked"
              [indeterminate]="false"
              (clicked)="onTermsOfUseChecked($event)"
            >
            </app-checkbox-with-label>
          </div>
        </div>

        <div class="onboarding-body-section">
          <div class="onboarding-body-section-heading">You’re ready to get started</div>
          <div class="onboarding-body-section-description">
            Select a module below to finish account setup and start using WeTrack:
          </div>

          <ng-container *ngFor="let module of moduleTypes">
            <ng-container *ngIf="canSeeModule(module)">
              <div class="onboarding-body-section-module">
                <div class="onboarding-body-section-module-header">
                  <img [src]="getModuleIcon(module)" class="onboarding-body-section-module-header-image" />

                  <div class="onboarding-body-section-module-header-title">
                    {{ getModuleName(module) }} -
                    <span class="module-description">{{ getModuleDescription(module) }}</span>
                  </div>
                </div>

                <button
                  type="button"
                  class="btn action-btn btn-block filter-button"
                  [disabled]="!termsOfUseChecked"
                  (click)="goToURL(module)"
                >
                  Get Started
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="onboarding-body-button" *ngIf="currentStep === 1 || currentStep === 2">
    <button type="button" class="btn action-btn filter-button" [class.btn-block]="mobile" (click)="onContinue()">
      Continue
    </button>
  </div>
</div>
