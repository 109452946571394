<div
  class="map-control-btn search-button-wrapper"
  [ngClass]="{ 'mobile-btn': isMobile, expanded: expanded, collapsed: !expanded }"
  title="Click to search for location"
>
  <div style="height: 100%; display: flex; align-items: center">
    <i class="material-icons search-icon" (click)="onSearchIconClick($event)"> search </i>
  </div>

  <input
    #address_search
    class="search-input"
    [ngClass]="isAddressSearchType && expanded ? 'visible' : 'hidden'"
    placeholder="Type any address or place here..."
  />
  <input
    #what_three_words_search
    class="search-input"
    [ngClass]="isWhatThreeWordsSearchType && expanded ? 'visible' : 'hidden'"
    placeholder="Type 3 Word Address..."
  />
  <input
    #zones_areas_search
    class="search-input"
    [ngClass]="isZonesAreasSeachType && expanded ? 'visible' : 'hidden'"
    placeholder="Type zone/area title..."
    (keyup)="onZoneAreaSearchInputChange($event, zones_areas_search.value)"
  />
  <ng-container *ngIf="isZonesAreasSeachType && expanded">
    <div class="zones-areas-search-result-container custom-scrollbar">
      <div class="result" *ngFor="let item of filteredZonesAreas" (click)="onZoneAreaSeachResultClicked($event, item)">
        <i *ngIf="item.type === 0" class="icon material-icons">location_searching</i>
        <i *ngIf="item.type === 1" class="icon material-icons">my_location</i>
        <span>{{ item.title }}</span>
      </div>
    </div>
  </ng-container>

  <div *ngIf="expanded" style="height: 100%">
    <i class="material-icons arrow-down-icon" (click)="onDropdownIconClick($event)"> arrow_drop_down </i>
  </div>
  <div *ngIf="dropdownOpen" class="list-wrapper">
    <a *ngIf="enableAddressSearch" (click)="onSearchTypeChange($event, searchTypes.ADDRESS_PLACES)">Google Maps Address</a>
    <a *ngIf="enableWhat3WordSearch" (click)="onSearchTypeChange($event, searchTypes.WHAT3WORDS)">what3words Address</a>
    <a *ngIf="enableZonesAreasSearch" (click)="onSearchTypeChange($event, searchTypes.ZONES_AREAS)">Zones & Areas</a>
  </div>
</div>
