<app-modal>
  <app-modal-header [title]="'Notifications (' + unread + ' Unread)'" (closed)="onClose()"></app-modal-header>
  <app-modal-body>
    <div class="tab-content">
      <app-toggle-button
        [objects]="notificationTypeOptions"
        [bindProperty]="'value'"
        [fillWidth]="true"
        [currentlySelectedObject]="selectedOption"
        (clicked)="changeNotificationType($event)"
      ></app-toggle-button>

      <ng-container *ngIf="filteredNotifications && filteredNotifications.length">
        <div class="row">
          <div class="col-12 notification-container" *ngFor="let notification of filteredNotifications">
            <app-employee-avatar [employee]="notification.createdBy"></app-employee-avatar>
            <div class="comment-container">
              <div class="d-flex">
                <div class="title" [innerHTML]="notification.message"></div>
                <div class="clear-link">
                  <a (click)="clear(notification)">Clear</a>
                </div>
              </div>
              <div class="description">
                <app-details-icon [outlined]="true" [type]="getIconType(notification)" [size]="16">
                </app-details-icon>
                <a class="description-link" (click)="goToObjectDetails(notification)">{{
                  notification.itemTitle
                }}</a>
              </div>
              <div class="d-flex">
                <div class="unread" *ngIf="!notification.isRead"></div>
                <div class="updated">{{ getReceivedText(notification) }}</div>
                <div class="link-holder" *ngIf="!notification.isRead">
                  <a (click)="markAsRead(notification)">Mark Read</a>
                </div>
                <div class="link-holder" *ngIf="notification.isRead">
                  <a (click)="markAsUnread(notification)">Mark Unread</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 notification-container" *ngIf="showMoreButtonVisible">
            <app-button-bar-rounded
              text="Show More"
              secondText="Show Less"
              fitToContainer="true"
              [isOpen]="false"
              (clicked)="showMore()"
            ></app-button-bar-rounded>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!filteredNotifications || !filteredNotifications.length">
        <div class="d-flex mt-32">
          <div class="empty-state-icon">
            <i class="material-icons">self_improvement</i>
            <div class="empty-state-heading">Peace at last</div>
            <div class="empty-state-helper-text">No updates to show you at this time</div>
          </div>
        </div>
      </ng-container>
    </div>
  </app-modal-body>
  <div class="d-none d-md-block mb-24 notification-container border-bottom"></div>
  <div *ngIf="isMobile" class="mobile-buttons">
    <app-button-rectangle
      [size]="ButtonSizes.Small"
      [buttonTheme]="ButtonThemes.White"
      [text]="'Mark All Read'"
      [disabled]="!filteredNotifications || !filteredNotifications.length"
      class="mr-8"
      (clicked)="markAllRead()"
    >
    </app-button-rectangle>
    <app-button-rectangle
      [size]="ButtonSizes.Small"
      [buttonTheme]="ButtonThemes.White"
      [text]="'Clear All'"
      [disabled]="!filteredNotifications || !filteredNotifications.length"
      class="mr-8"
      (clicked)="clearAll()"
    >
    </app-button-rectangle>
    <app-button-rectangle [size]="ButtonSizes.Small" [text]="'Notification Settings'" (click)="openSettings()">
    </app-button-rectangle>
  </div>
  <app-modal-footer>
    <app-button-transparent
      *ngIf="!isMobile"
      [text]="'Mark All Read'"
      [disabled]="!filteredNotifications || !filteredNotifications.length"
      class="mr-8"
      (clicked)="markAllRead()"
    >
    </app-button-transparent>
    <app-button-transparent
      *ngIf="!isMobile"
      [text]="'Clear All'"
      [disabled]="!filteredNotifications || !filteredNotifications.length"
      class="mr-8"
      (clicked)="clearAll()"
    >
    </app-button-transparent>
    <app-button-rectangle *ngIf="!isMobile" [text]="'Notification Settings'" (click)="openSettings()">
    </app-button-rectangle>
  </app-modal-footer>
</app-modal>
