import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { Pillars } from 'src/app/modules/sustainability/enums/pillars';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-suggested-card',
  templateUrl: './suggested-card.component.html',
  styleUrl: './suggested-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedCardComponent implements OnInit {
  @Input() objectType: ObjectTypes;
  @Input() suggestedItem: any;
  @Input() showSelectButton: boolean = true;
  @Input() isTheCardSelected: boolean = false;
  @Input() isWithinAddRiskModal: boolean = false;

  @Output() cardSelected = new EventEmitter();
  @Output() prefillFormEvent = new EventEmitter<{ item: any, notEditable: boolean }>();

  public readonly T = T;
  public objectTypes = ObjectTypes;
  public filterTypes = FilterTypes;
  public icon: string;
  public iconColor: string = '';

  public filters: FilterViewModel[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if(this.objectType === this.objectTypes.Indicator) {
      const sustainabilityScopeValue =
            this.suggestedItem.sustainabilityScope ?
            Math.round(this.suggestedItem.sustainabilityScope) :
            this.suggestedItem.sustainabilityScope;

      const categoryFilter = FilterUtilities.GenerateFilter(this.filterTypes.Indicator_Category, Math.round(this.suggestedItem.indicatorCategoryId));
      this.filters.push(categoryFilter);

      const scopeFilter = FilterUtilities.GenerateFilter(this.filterTypes.Sustainability_Scope, sustainabilityScopeValue);
      this.filters.push(scopeFilter);

      this.setIconBasedOnPillar();
    }
  }

  setIconBasedOnPillar(): void {
    if (this.suggestedItem.pillar) {
      switch (this.suggestedItem.pillar) {
        case Pillars.Environmental:
          this.icon = 'grass';
          this.iconColor = 'text-success';
          break;
        case Pillars.Social:
          this.icon = 'emoji_people';
          this.iconColor = 'text-primary';
          break;
        case Pillars.Governance:
          this.icon = 'account_balance';
          this.iconColor = 'text-danger';
          break;
      }
    }
  }

  onCardSelected(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.cardSelected.emit();
  }

  prefillForm(item: any) {
    this.prefillFormEvent.emit({ item, notEditable: true });
  }
}
