<div class="row" #wrapper>
  <div class="col-12 col-md-6 col-lg-4">
    <app-filter-dropdown-new
      [filterByModuleObjectType]="false"
      [filterType]="subscriberFilterType"
      [appliedFilters]="subscriberFilters"
      [disableMargin]="true"
      [typeahead]="true"
      [label]="T.common.assign_subscriber_s | translate"
      (onFilterUpdate)="handleSubscriberChange($event, true)"
      *ngIf="canEdit"
    >
    </app-filter-dropdown-new>
    <ng-container *ngIf="subscribers.length > 0">
      <div class="subscriber-wrapper m-t-8" *ngFor="let subscriber of subscribers">
        <div class="row">
          <div class="col-10">
            <div class="subscriber-salutation-wrapper">
              <div [innerHTML]="subscriber.avatarHtml"></div>
              <span class="subscriber-salutation">{{ subscriber.firstName + ' ' + subscriber.surname }}</span>
            </div>
          </div>
          <!-- <div class="d-none d-sm-flex col-sm-5">
            <div class="department-wrapper">
              <i class="material-icons department-icon">
                people
              </i>
              <span>{{subscriber.departmentTitle}}</span>
            </div>
          </div> -->
          <div class="col-2">
            <a class="remove-subscriber-btn" *ngIf="canEdit" (click)="removeSubscription(subscriber)">
              <i class="material-icons">delete_forever</i>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="subscribers.length === 0">
      <div class="no-subscribers-wrapper">
        <i class="material-icons">people</i>
        <span class="no-subscribers-heading">{{ T.common.no_subscribers | translate }}</span>
      </div>
    </ng-container>
  </div>

  <div class="col-12 col-md-6 col-lg-4 mt-16-sm">
    <app-filter-dropdown-new
      [filterByModuleObjectType]="false"
      [filterType]="subscriberGroupFilterType"
      [appliedFilters]="subscriberFilters"
      [disableMargin]="true"
      [typeahead]="true"
      [label]="T.common.assign_group_s | translate"
      (onFilterUpdate)="handleSubscriberChange($event, false)"
      *ngIf="canEdit"
    >
    </app-filter-dropdown-new>

    <ng-container *ngIf="groupSubscribers.length > 0">
      <div class="subscriber-wrapper m-t-8" *ngFor="let subscriber of groupSubscribers">
        <div class="row">
          <div class="col-10">
            <div class="subscriber-salutation-wrapper">
              <div>
                <div class="avatar-preview avatar-preview-32">
                  <div Class="avatar-initials-circle">
                    <div Class="avatar-initials-circle__inner">
                      <div Class="avatar-initials-circle__wrapper">
                        <div Class="avatar-initials-circle__content">
                          {{ getGroupInitials(subscriber) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="subscriber-salutation">{{ subscriber.title }}</span>
            </div>
          </div>
          <div class="col-2">
            <a class="remove-subscriber-btn" *ngIf="canEdit" (click)="removeGroupSubscription(subscriber)">
              <i class="material-icons">delete_forever</i>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="groupSubscribers.length === 0">
      <div class="no-subscribers-wrapper">
        <i class="material-icons">people</i>
        <span class="no-subscribers-heading">{{ T.common.no_subscriber_groups | translate }}</span>
      </div>
    </ng-container>
  </div>
</div>
