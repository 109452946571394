<button class="square-icon-btn" (click)="onClick($event)" [ngClass]="{ 'badge-support': badge }" [disabled]="disabled">
  <ng-container *ngIf="!loading">
    <div *ngIf="badge && badgeCount > 0" class="badge">{{ badgeCount }}</div>
    <div class="square-icon-btn-container" [ngClass]="getButtonClasses()">
      <i class="material-icons" *ngIf="icon">{{ icon }}</i>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <app-button-loader [ngClass]="getButtonClasses()"></app-button-loader>
  </ng-container>
</button>
