<div #wrapper class="wrapper" (click)="onClick($event)">
  <div [title]="title" class="map-control-btn">
    <i *ngIf="controlButtonAvailable else inactiveTemplate"
      class="material-icons w3w-icon"
      [ngClass]="isbuttonToggled ? 'active' : 'inactive'">
        grid_on
    </i>
    <ng-template #inactiveTemplate>
      <i class="material-icons w3w-icon disabled">grid_on</i>
    </ng-template>
  </div>
</div>
