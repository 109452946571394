<div class="attachments-list-view-wrapper">
  <app-responsive-table
    ngImagePreview
    [objects]="documents"
    [tableHeaders]="attachmentsTableHeaders()"
    [selectableObjects]="false"
    [wholeRowSelection]="false"
    [adoptParentHeight]="false"
    [useSearch]="false"
    (objectClicked)="onAttachmentClicked($event)"
  ></app-responsive-table>
</div>

<ng-template #attachmentFileName let-object="object">
  <div class="file-name-wrapper">
    <div class="fine-name-icon d-flex" [innerHTML]="getDocumentIcon(object) | safeHtml"></div>
    <ng-container *ngIf="object.title">
      <div class="fine-name-title">{{ object.title }}</div>
    </ng-container>
    <ng-container *ngIf="!object.title">
      <div class="fine-name-title">{{ object.filename }}</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #attachmentActions let-object="object">
  <div class="actions-wrapper">
      <div class="action-download d-flex" (click)="downloadAttachment($event, object)">
        <i *ngIf="getAttachmentDocumentType(object) !== attachmentTypes.Link" class="material-icons action-icon"
          >download</i
        >
      </div>
    <div *ngIf="canDelete" class="action-delete" (click)="onEditClicked($event, object)">
      <i class="material-icons action-icon">edit</i>
    </div>
    <div *ngIf="canDelete" class="action-delete" (click)="deleteAttachment($event, object)">
      <i class="material-icons action-icon">delete</i>
    </div>
  </div>
</ng-template>
