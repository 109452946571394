import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { EditableFieldTypes } from '../../../enums/editableFieldTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { UserAgentService } from '../../../services/user-agent.service';
import { TranslateService } from '@ngx-translate/core';
import { IconUtilities } from '../../../utilities/icon.utilities';

@Component({
  selector: 'app-details-header',
  templateUrl: './details-header.component.html',
  styleUrls: ['./details-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsHeaderComponent<T> implements OnInit, OnDestroy {
  // TODO: Use an interface for the type
  @Input() item: T;
  @Input() canEdit: boolean;
  @Input() localisedItem: string;
  @Input() listUrl: string;
  @Input() useBrowserBackNavigation: boolean = false;
  @Input() logoTemplate: TemplateRef<any>;
  @Input() useSimpleTitleEdit: boolean = false;
  @Input() useBackButtonRightMargin: boolean = true;
  @Input() showBackNavigation: boolean = true;
  @Input() moreButtonTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @Input() filterType: FilterTypes;
  @Input() customSimpleEditableFieldWidth: string;
  @Input() showRefCode: boolean = true;
  @Input() showId: boolean = false;
  @Input() showTaskDetailsButtons: boolean = false;
  @Input() showIncidentDetailsButtons: boolean = false;
  @Input() canCloseIncident: boolean = true;
  @Input() isIncidentClosed: boolean;
  @Input() showJobDetailsButtons: boolean = false;
  @Input() canCompleteJob: boolean = true;
  @Input() canCompleteTask: boolean = true;
  /**
   * If true, the additional buttons will be shown in the header
   */
  @Input() showAdditionalButtons: boolean = false;
  /**
   * If true, the description will be shown in the header
   */
  @Input() showDescription: boolean = false;
  /**
   * Additional text field property to be shown in the header (e.g. 'Additional information for risks')
   */
  @Input() additionalTextFieldProp: string;
  /**
   * Label to be used for the Additioanl text field (e.g. 'Additional information'), will also be used as the placeholder
   */
  @Input() additionalTextFieldLabel: string;
  /**
   * Wheather to show the headline status component
   */
  @Input() showHeadlineStatus: boolean = false;
  /**
   * Wheather to show the more button
   */
  @Input() showMoreButton: boolean = true;

  @Input() isPublicIncidentReport: boolean = false;

  @Input() showIndicatorDetailsButtons: boolean = false;

  @Output() titleUpdated = new EventEmitter<string>();

  /**
   * Emits the new description when it is updated
   */
  @Output() descriptionUpdated = new EventEmitter<string>();
  /**
   * Emits the new additional field value when it is updated
   */
  @Output() additionalFieldUpdated = new EventEmitter<string>();
  /**
   * Emits the whole object when the headline status is updated
   */
  @Output() headlineStatusChange = new EventEmitter<unknown>();
  @Output() addDependencyClicked = new EventEmitter();
  @Output() addCommentClicked = new EventEmitter();
  @Output() taskCompleteClicked = new EventEmitter();
  @Output() quickIncidentBtnClicked = new EventEmitter();
  @Output() addIncidentCommentClicked = new EventEmitter();
  @Output() createJobClicked = new EventEmitter();
  @Output() addJobCommentClicked = new EventEmitter();
  @Output() completeJobClicked = new EventEmitter();
  @Output() exportToPdfClicked = new EventEmitter();
  @Output() addQuestionsClicked = new EventEmitter();
  @Output() duplicateClicked = new EventEmitter();
  @Output() createRiskAssessmentClicked = new EventEmitter();

  isMobile: boolean;
  iconSvg: string;
  readonly editableFieldTypes = EditableFieldTypes;
  public readonly T = T;
  public descriptionExpanded: boolean = false;
  public additionalFieldExpanded: boolean = false;
  public localisedRiskAssessment: string = this.translateService.instant(T.defaultLocalizations.risk_assessment.one);
  private readonly subscriptions = new Subscription();
  public isLinkedToRiskAssessment = false;

  constructor(
    private readonly userAgentService: UserAgentService,
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.userAgentService.isXSmall$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      })

    );

    if (this.filterType) {
      this.iconSvg = IconUtilities.getSvgForFilterType(this.filterType, 32, true, '#939699');
    }

    this.setIsLinkedToRiskAssessment();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setIsLinkedToRiskAssessment(): void {
    this.isLinkedToRiskAssessment = this.item['filters'] && this.item['filters'].find(f => f.filterType === FilterTypes.Risk_Assesment && f.filterValue) !== undefined;
  }

  get localisedJob(): string {
    return this.translateService.instant(T.defaultLocalizations.job.one);
  }

  get localisedTask(): string {
    return this.translateService.instant(T.defaultLocalizations.task.one);
  }

  get closeIncidentBtnText(): string {
    return `${this.translateService.instant(T.common.close)} ${this.translateService.instant(T.defaultLocalizations.incident_item.one)}`;
  }

  onTitleUpdate(newTitle: string): void {
    this.titleUpdated.emit(newTitle);
  }

  public goBack(): void {
    if (this.useBrowserBackNavigation) {
      window.history.back();
    } else {
      void this.router.navigate([this.listUrl]);
    }
  }

  expandCollapseDescBtnClicked() {
    this.descriptionExpanded = !this.descriptionExpanded;
  }
  expandCollapseAdditionalFieldBtnClicked() {
    this.additionalFieldExpanded = !this.additionalFieldExpanded;
  }

  updateDescription(description: string): void {
    this.descriptionUpdated.emit(description);
  }

  updateAdditionalField(additionalField: string): void {
    this.additionalFieldUpdated.emit(additionalField);
  }

  onHeadlineStatusUpdated(item: unknown): void {
    this.headlineStatusChange.emit(item);
  }

  onTaskComplete(): void {
    this.taskCompleteClicked.emit()
  }

  onAddDependency(): void {
    this.addDependencyClicked.emit();
  }

  onAddComment(): void {
    this.addCommentClicked.emit();
  }

  onCloseIncident(): void {
    if(!this.canCloseIncident) return;

    this.quickIncidentBtnClicked.emit();
  }

  onCreateJob(): void {
    this.createJobClicked.emit();
  }

  onAddIncidentComment(): void {
    this.addIncidentCommentClicked.emit();
  }

  onCompleteJob(): void {
    if(this.canCompleteJob)
      this.completeJobClicked.emit();
  }

  onAddJobComment(): void {
    this.addJobCommentClicked.emit();
  }

  onCompleteTask(): void {
    this.taskCompleteClicked.emit();
  }

  onAddTaskDependency(): void {
    this.addDependencyClicked.emit();
  }

  onAddTaskComment(): void {
    this.addCommentClicked.emit();
  }

  onExportToPdf(): void {
    this.exportToPdfClicked.emit();
  }

  onAddQuestions(): void {
    this.addQuestionsClicked.emit();
  }

  onDuplicate(): void {
    this.duplicateClicked.emit();
  }

  onCreateRiskAssessment(): void {
    if(!this.isLinkedToRiskAssessment) {
      this.createRiskAssessmentClicked.emit();
    }
  }
}
