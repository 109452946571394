<div class="subscribers-container">
  <div class="employees-subscribers">
    <div class="subs-label mb-2">{{ T.defaultLocalizations.subscriber.many | translate }}:</div>
    <div class="subscribers-avatars" ngDropdown [templateRef]="canEdit ? subscribersInlineEditContainer : emptyTemplate">
      <app-employee-avatar
        *ngFor="let employee of employeeViewModelOfSubscribers"
        [employee]="employee"
        [app-tooltip]="{ message: employee.firstName + ' ' + employee.surname }"
      ></app-employee-avatar>
      <div class="add-subscriber">
        <i class="material-icons">add</i>
      </div>
    </div>
  </div>
  <div class="groups-subscribers mt-3">
    <div class="subs-label mb-2">{{ T.common.group.many | translate }}:</div>
    <div class="groups-avatars" ngDropdown [templateRef]="canEdit ? groupsInlineEditContainer : emptyTemplate">
      <div class="group-container" *ngFor="let group of groupSubscribers" [app-tooltip]="{ message: group.title }">
        <div class="group-initials">{{ getGroupInitials(group) }}</div>
      </div>
      <div class="add-subscriber">
        <i class="material-icons">add</i>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyTemplate>
</ng-template>

<ng-template #subscribersInlineEditContainer>
  <app-filters-inline-edit-container
    [mobile]="isMobile"
    [lozengeInlineEdit]="true"
    [filterTypeSelectorViewModel]="subscriberFilterTypeSelectorViewModel"
    [filters]="allowedSubscriberFilters"
    [appliedFilters]="subscriberFilters"
    [isUsedAlone]="true"
    (filtersUpdated)="handleSubscriberChange($event, true)"
  ></app-filters-inline-edit-container>
</ng-template>
<ng-template #groupsInlineEditContainer>
  <app-filters-inline-edit-container
    [mobile]="isMobile"
    [lozengeInlineEdit]="true"
    [filterTypeSelectorViewModel]="subscriberGroupFilterTypeSelectorViewModel"
    [filters]="allowedGroupsFilters"
    [appliedFilters]="subscriberFilters"
    [isUsedAlone]="true"
    (filtersUpdated)="handleSubscriberChange($event, false)"
  ></app-filters-inline-edit-container>
</ng-template>
