<button class="dropdown-icon-btn" (click)="onClick()" [disabled]="disabled">
  <div class="dropdown-icon-btn-container" [ngClass]="getButtonClasses()">
    <i
      class="material-icons"
      [@rotatedState]="{ value: getCurrentState, params: { initialDeg: initialDegrees, finalDeg: finalDegrees } }"
      >{{ icon }}</i
    >
    <div class="button-text">{{ renderedText }}</div>
  </div>
</button>
