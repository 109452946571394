<div class="card-wrapper" [style.background-color]="cardBackgroundColor">
  <div class="card-left">
    <ng-container *ngIf="cardIcon || cardIconSVG || cardLeftText">
      <div class="icon" *ngIf="cardIcon">
        <i class="material-icons">{{ cardIcon }}</i>
      </div>
      <div class="icon" *ngIf="cardLeftText" [innerHTML]="cardLeftText | safeHtml"></div>
      <div class="icon svg-icon" *ngIf="cardIconSVG" [innerHTML]="cardIconSVG | safeHtml"></div>
    </ng-container>
    <ng-container *ngIf="cardTypeLeaderboard">
      <div class="position">{{ getOrdinalNumber(cardTypeLeaderboard.index) }}</div>
    </ng-container>
    <ng-container *ngIf="cardSubTitle">
      <div class="title-sub-title-wrapper">
        <span class="title text-truncate" [innerHTML]="cardTitle | safeHtml"></span>
        <span class="sub-title text-truncate" [innerHTML]="cardSubTitle | safeHtml"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="!cardSubTitle">
      <div class="title text-truncate" [innerHTML]="cardTitle | safeHtml"></div>
    </ng-container>
  </div>
  <div class="card-right" [class.ragBreakdown]="ragBreakdown">
    <ng-container *ngIf="ragBreakdown">
      <app-rag-breakdown-bar [ragBreakdowns]="ragBreakdown"></app-rag-breakdown-bar>
    </ng-container>
    <ng-container *ngIf="cardRightSubText">
      <div class="title-sub-title-wrapper">
        <span [style.color]="cardRightColor" class="card-right-title" [innerHTML]="cardRightText | safeHtml"></span>
        <span class="sub-title text-truncate" [innerHTML]="cardRightSubText | safeHtml"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="cardRightText && !cardRightSubText">
      <div [style.color]="cardRightColor" class="card-right-title" [innerHTML]="cardRightText | safeHtml"></div>
    </ng-container>
    <ng-container *ngIf="targetBadgeBreakdown">
      <div class="target-badges-wrapper">
        <div
          *ngFor="let item of targetBadgeBreakdown"
          [innerHTML]="getBadgeIcon(item) + ' ' + item.total | safeHtml"
          class="icon svg-icon badge-icon"
        ></div>
      </div>
    </ng-container>
  </div>
</div>
