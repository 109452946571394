import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EmployeeCustomFilterViewModel } from '../../../models/filter/employeeCustomFilterViewModel';
import { SideFiltersService } from '../../../services/side-filters.service';
import { ModuleService } from '../../../services/module.service';
import { HeaderFiltersService } from '../../../services/header-filters.service';
import { Subscription } from 'rxjs';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { TitleOnlyModalComponent } from 'src/app/modules/settings/components/common/modals/title-only/title-only-modal.component';
import { BsModalConfig } from '../../../config/modal.config';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { ObjectEventEmitters } from '../../../events/object.events';
import { AlertService } from '../../../services/alert.service';
import { Account } from '../../../models/account';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfirmationService } from '../../../services/confirmation.service';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FilterDateRangeOptions } from '../../../enums/filter/filterDateRangeOptions';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterValueDateViewModel } from '../../../models/filter/filterValueDateViewModel';
import * as moment from 'moment';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { NgRangeDatesOutput } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickFiltersComponent implements OnInit, OnChanges {
  @Input() searchPlaceholder: string;
  @Input() searchText: string;
  @Input() showQuickFiltersForFilterSelectors: FilterTypeSelectorViewModel[] = [];
  @Input() showAddButton = false;
  @Input() showSettingsButton = true;
  @Input() showEmptyFiltersMessage = false;
  @Input() showSuggestedFilters: boolean = true;
  @Input() applyLocalFitlersGlobally = true;
  @Input() showFiltersButton: boolean = true;

  @Output() onSearch = new EventEmitter<string>();
  @Output() settingsClick = new EventEmitter<void>();
  @Output() addButtonClick = new EventEmitter<void>();
  @Output() filtersApplied = new EventEmitter<FilterViewModel[]>();

  public favoriteFiltersForObjectTypes: EmployeeCustomFilterViewModel[] = [];
  public favouriteMatchingFilter: EmployeeCustomFilterViewModel;
  public selectedFavoriteFilter: EmployeeCustomFilterViewModel;
  public appliedFavoriteFilter: EmployeeCustomFilterViewModel;
  public quickFiltersForFilterTypes: FilterTypes[] = [];
  public allFavoriteFilters: EmployeeCustomFilterViewModel[] = [];
  public appliedFilters: FilterViewModel[] = [];
  public localSelectedFilters: FilterViewModel[] = [];
  public readonly T = T;
  public readonly filterTypes = FilterTypes;
  public isMobile = false;

  private readonly subscriptions = new Subscription();
  private account: Account;
  private employee: Employee;

  constructor(
    private readonly sideFiltersService: SideFiltersService,
    private readonly moduleService: ModuleService,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly translateService: TranslateService,
    private readonly modalConfig: BsModalConfig,
    private readonly bsModalService: BsModalService,
    private readonly customFilterService: CustomFiltersService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly confirmationService: ConfirmationService
    ) { }

  ngOnInit(): void {
    this.initFilterTypesBasedOnSelectors();
    this.allFavoriteFilters = this.sideFiltersService.favouriteFilters;
    this.filterFavoriteFiltersForObjectTypes(this.moduleService.currentObjectTypes);
    this.appliedFilters = this.headerFiltersService.currentFilters;
    this.localSelectedFilters = this.appliedFilters.slice();
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.isMobile = window.innerWidth < Constants.sm;
    this.subscriptions.add(
      this.headerFiltersService.filtersChanged$.subscribe((filters) => {
        this.appliedFilters = filters;
        this.localSelectedFilters = filters.slice();
        this.cdr.detectChanges();
      })
    )

    this.subscriptions.add(
      this.sideFiltersService.favouriteFilters$.subscribe((filters) => {
        this.allFavoriteFilters = filters;
        this.filterFavoriteFiltersForObjectTypes(this.moduleService.currentObjectTypes);
      })
    )

    this.subscriptions.add(
      this.sideFiltersService.favoriteMatchingFilter$.subscribe((filter) => {
        this.favouriteMatchingFilter = filter;
        this.selectedFavoriteFilter = filter;
        this.cdr.detectChanges();
      })
    )

    this.subscriptions.add(
      this.moduleService.objectTypesChanged$.subscribe((objectTypes) => {
        this.filterFavoriteFiltersForObjectTypes(objectTypes);
      })
    )
  }

  ngOnChanges(): void {
    this.initFilterTypesBasedOnSelectors();
  }

  public initFilterTypesBasedOnSelectors(): void {
    if(this.showQuickFiltersForFilterSelectors?.length) {
      this.quickFiltersForFilterTypes = this.showQuickFiltersForFilterSelectors.map((filterSelector) => {
        return filterSelector.filterType;
      })
    }
  }

  public selectFavoriteFilter(favFilter: EmployeeCustomFilterViewModel): void {
    this.selectedFavoriteFilter = favFilter;
  }

  public onFavoriteFiltersApply(): void {
    this.sideFiltersService.applyFavoriteFilter(this.selectedFavoriteFilter);
  }

  public isFavoriteFilterSelected(favFilter: EmployeeCustomFilterViewModel): boolean {
    if(this.selectedFavoriteFilter?.id === favFilter.id) {
      return true;
    }
    return false;
  }

  private filterFavoriteFiltersForObjectTypes(objectTypes: ObjectTypes[]): void {
    this.favoriteFiltersForObjectTypes = this.allFavoriteFilters.filter((favourite) =>
    favourite.filters.length > 0 &&
    favourite.filters.every((f) => objectTypes.includes(+f.displayForGlobalObjectType))
    );
    this.cdr.detectChanges();
  }

  public openSideFilters(): void {
    this.sideFiltersService.toggleSideFilters('open');
  }

  public onResetFilters(): void {
    this.headerFiltersService.setCurrentFilters([]);
  }

  public getAllowedFiltersForFilterType(filterType: FilterTypes): FilterViewModel[] {
    return this.allowedFiltersService.getCachedAllowedFiltersByType(filterType);
  }

  public getAppliedFiltersByType(filterSelector: FilterTypeSelectorViewModel): FilterViewModel[] {
    return this.localSelectedFilters.filter((filter) => filter.filterType === filterSelector.filterType && (filter.displayForGlobalObjectType === filterSelector.displayForObjectType ));
  }

  public onFilterSelected(filter: FilterViewModel, filterSelector: FilterTypeSelectorViewModel): void {
    const copiedFilter = JSON.parse(JSON.stringify(filter)) as FilterViewModel;
    copiedFilter.displayForGlobalObjectType = filterSelector.displayForObjectType;
    //TODO must set display for globalObjectType
    if(this.localSelectedFilters.some((f) => f.id === filter.id && f.displayForGlobalObjectType === filterSelector.displayForObjectType)) {
      this.localSelectedFilters = this.localSelectedFilters.filter((a) => a.id !== filter.id);
    } else {
      this.localSelectedFilters.push(copiedFilter);
    }
    this.cdr.detectChanges();
  }

  public onApplyLocalFilters(): void {
    if(this.applyLocalFitlersGlobally)
      this.headerFiltersService.setCurrentFilters(this.localSelectedFilters);
    else
      this.filtersApplied.next(this.localSelectedFilters);
  }

  public onQuickFilterDropdownToggled(toggled: boolean): void {
    if(!toggled) {
      this.localSelectedFilters = this.appliedFilters.slice();
    }
  }

  public hasSelectedFiltersForFilterType(filterSelector: FilterTypeSelectorViewModel): boolean {
    return this.localSelectedFilters.some((f) => f.filterType === filterSelector.filterType && f.displayForGlobalObjectType === filterSelector.displayForObjectType);
  }

  public selectedFiltersCountForFilterType(filterSelector: FilterTypeSelectorViewModel): number {
    return this.localSelectedFilters.filter((f) => f.filterType === filterSelector.filterType && f.displayForGlobalObjectType === filterSelector.displayForObjectType).length;
  }

  public clearAllFiltersForFilterType(filterSelector: FilterTypeSelectorViewModel): void {
    this.localSelectedFilters = this.localSelectedFilters.filter((f) => !(f.filterType === filterSelector.filterType && f.displayForGlobalObjectType === filterSelector.displayForObjectType));

    this.onApplyLocalFilters();
    this.cdr.detectChanges();
  }

  public onEditFavoriteFilter(favoriteFilter: EmployeeCustomFilterViewModel): void {
    const initialState = {
      title: favoriteFilter.title,
      headerText: this.translateService.instant(T.common.save_filters),
      maxLength: 40,
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    const modalRef = this.bsModalService.show(TitleOnlyModalComponent, modalParams);
    this.subscriptions.add(
      modalRef.content.onSave.subscribe((res: string) => {
        this.subscriptions.add(
          this.customFilterService
          .updateEmployeeCustomFilter({
            accountId: this.account.id,
            employeeId: this.employee.id,
            filters: this.appliedFilters,
            globalObjectType: ObjectTypes.Favorite_Filters,
            title: res,
            id: favoriteFilter.id,
          })
          .subscribe((favoriteFilter) => {
            this.objectEventEmitters.broadcastObjectUpdated(favoriteFilter.id, ObjectTypes.Favorite_Filters, favoriteFilter);
            void this.alertService.success(this.translateService.instant(T.common.saved_filters_updated));
          })
        )
      })
    )
  }

  public onDeleteFavoriteFilter(favoriteFilter: EmployeeCustomFilterViewModel): void {


    this.confirmationService.confirm({
      headMessage: this.translateService.instant(T.defaultLocalizations.favorite_filter.one),
      message: this.translateService.instant(T.common.confirm_delete_element),
      confirm: () => {
        this.customFilterService.deleteEmployeeCustomFilter(favoriteFilter.id).subscribe(() => {
          this.objectEventEmitters.broadcastObjectDeleted(favoriteFilter.id, ObjectTypes.Favorite_Filters, favoriteFilter);
        });
      }
    })
  }

  onListSearch(searchText: string) {
    this.onSearch.next(searchText);
  }

  public onSettingsBtnClick(): void {
    this.settingsClick.next();
  }

  public getSelectedValues(filterSelector: FilterTypeSelectorViewModel): string {
    const foundFilters = this.localSelectedFilters.filter((f) => f.filterType === filterSelector.filterType && f.displayForGlobalObjectType === filterSelector.displayForObjectType);
    if(foundFilters.length) {
      return foundFilters.map((f) => f.filterText).join(', ');
    }
  }

  public onAddBtnClick(): void {
    this.addButtonClick.next();
  }

  // Dates related
  public sanitizeFilterDateOption(filterMember: FilterDateRangeOptions): string {
    return FilterDateRangeOptions[filterMember].toString().replace(/_/g, ' ');
  }

  private getAppliedDateFilter(filterSelector: FilterTypeSelectorViewModel): FilterViewModel {
    return this.localSelectedFilters.find((filter) =>
      filter.filterType === filterSelector.filterType &&
      filter.displayForGlobalObjectType === filterSelector.displayForObjectType
    );
  }

  public isDateFilterSelected(filterSelector: FilterTypeSelectorViewModel, relativeOption: FilterDateRangeOptions): boolean {
   const foundFilter = this.getAppliedDateFilter(filterSelector);
   if(foundFilter) {
      return foundFilter.dateRangeOption === relativeOption;
   }
  }

  public isNoneDateFilterSelected(filterSelector: FilterTypeSelectorViewModel): boolean {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    return !foundFilter || foundFilter?.dateRangeOption == null;
  }

  public isFixedDateRangeSelected(filterSelector: FilterTypeSelectorViewModel): boolean {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    if(foundFilter) {
      return foundFilter.dateRangeOption === FilterDateRangeOptions.Fixed_Date_Range || foundFilter.dateRangeOption === FilterDateRangeOptions.Fixed_Time_Range;
    }
  }

  public onRelativeDateSelected(filterSelector: FilterTypeSelectorViewModel, relativeOption: FilterDateRangeOptions): void {
    // Remove existing date filter
    this.localSelectedFilters = this.localSelectedFilters.filter((f) => !(f.filterType === filterSelector.filterType && f.dateProperty === filterSelector.dateProperty  && f.displayForGlobalObjectType === filterSelector.displayForObjectType));
    // Add new date filter
    const dateFilterViewModel: FilterViewModel = FilterUtilities.GenerateDateFilter(filterSelector.dateProperty, filterSelector.filterSelectorType, relativeOption);
    dateFilterViewModel.displayForGlobalObjectType = filterSelector.displayForObjectType;
    this.localSelectedFilters.push(dateFilterViewModel);
    this.onApplyLocalFilters();
  }

  public getDateSelectValue(filterSelector: FilterTypeSelectorViewModel): string {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    const isFixedDateRangeSelected = this.isFixedDateRangeSelected(filterSelector);
    if(foundFilter && !isFixedDateRangeSelected) {
      return this.sanitizeFilterDateOption(foundFilter.dateRangeOption);
    } else if(foundFilter && isFixedDateRangeSelected) {
      return `${this.getRangeDateStartValue(filterSelector)} - ${this.getRangeDateEndValue(filterSelector)}`;
    }
  }

  public getRangeDateStartValue(filterSelector: FilterTypeSelectorViewModel): string {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    if(foundFilter) {
      const filterValue = foundFilter.filterValue as FilterValueDateViewModel;
      return moment(filterValue.fromDate).format('DD/MM/YYYY');
    }
  }

  public getRangeDateEndValue(filterSelector: FilterTypeSelectorViewModel): string {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    if(foundFilter) {
      const filterValue = foundFilter.filterValue as FilterValueDateViewModel;
      return moment(filterValue.toDate).format('DD/MM/YYYY');
    }
  }

  public showTimePicker(filterSelector: FilterTypeSelectorViewModel): boolean {
    return filterSelector.dateProperty == FilterDateOptions.Start_Time;
  }

  public rangeFromDate(filterSelector: FilterTypeSelectorViewModel): Date {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    if(foundFilter && foundFilter.dateRangeOption === FilterDateRangeOptions.Fixed_Date_Range) {
      const filterValue = foundFilter.filterValue as FilterValueDateViewModel;
      return new Date(filterValue.fromDate)
    }
  }
  public rangeToDate(filterSelector: FilterTypeSelectorViewModel): Date {
    const foundFilter = this.getAppliedDateFilter(filterSelector);
    if(foundFilter && foundFilter.dateRangeOption === FilterDateRangeOptions.Fixed_Date_Range) {
      const filterValue = foundFilter.filterValue as FilterValueDateViewModel;
      return new Date(filterValue.toDate)
    }
  }

  public onDateRangeChanged(change: NgRangeDatesOutput, filterSelector: FilterTypeSelectorViewModel): void {
    let fromDate = change.startDate;
    let endDate = change.endDate;

    if(moment(fromDate).isSame(endDate, 'day')){
      fromDate = moment(fromDate).startOf('day').toISOString();
      endDate = moment(endDate).endOf('day').toISOString();
    }
    this.localSelectedFilters = this.localSelectedFilters.filter((f) => !(f.filterType === filterSelector.filterType && f.dateProperty === filterSelector.dateProperty  && f.displayForGlobalObjectType === filterSelector.displayForObjectType));

    const filter = FilterUtilities.GenerateDateFilter(filterSelector.dateProperty, filterSelector.filterSelectorType, FilterDateRangeOptions.Fixed_Date_Range);
    filter.displayForGlobalObjectType = filterSelector.displayForObjectType;

    const filterValueDateVm = filter.filterValue as FilterValueDateViewModel;
    filterValueDateVm.fromDate = fromDate as string;
    filterValueDateVm.toDate = endDate as string;
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Date_Range;
    filter.filterValue = filterValueDateVm;

    this.localSelectedFilters.push(filter);
    this.onApplyLocalFilters();
  }

  public removeDateFilter(filterSelector: FilterTypeSelectorViewModel): void {
    this.localSelectedFilters = this.localSelectedFilters.filter((f) => !(f.filterType === filterSelector.filterType && f.dateProperty === filterSelector.dateProperty  && f.displayForGlobalObjectType === filterSelector.displayForObjectType));
    this.onApplyLocalFilters();
  }

  public get excludeState(): boolean {
    return this.localSelectedFilters?.[0]?.exclude;
  }

  public onExclude(excluded: boolean): void {
    this.localSelectedFilters.forEach((a) => {
      a.exclude = excluded;
    });
  }
}
