<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.cancel | translate"
      (clicked)="confirmClose()"
    >
    </app-button-transparent>
    <span class="modal-title">{{ localisedProject }}</span>
    <span class="material-icons close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-icons">error_outline</i>
      {{ error }}
    </div>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
    <div class="container-with-bottom-element col-md-6 col-12">
      <app-text-field
        class="modal-project"
        [displayError]="triggerErrors"
        [autofocus]="true"
        [required]="true"
        [maxLength]="250"
        formControlName="title"
        [disableMargin]="true"
        [svg]="projectSvg"
        [placeholder]="(T.common.item_title | translate: { item: localisedProject }) + '*'"
        [formGroup]="form"
        [useReactiveMessages]="true"
      >
      </app-text-field>
    </div>
    <div class="ref-code col-md-3 col-12">
      <app-text-field
        formControlName="refCode"
        [maxLength]="12"
        [placeholder]="T.defaultLocalizations.ref_code.one | translate"
        [useReactiveMessages]="true"
        [formGroup]="form"
      >
      </app-text-field>
    </div>
    <div class="privacy col-md-6 col-12">
      <app-filter-dropdown-new
        [useSearch]="false"
        [singleSelect]="true"
        [filterByModuleObjectType]="false"
        [filterType]="filterTypes.Project_Privacy_Status"
        [appliedFilters]="filters"
        [typeahead]="true"
        [label]="T.common.privacy.one | translate"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <div class="container-with-bottom-element col-md-6 col-12">
      <div class="selection">
        <app-datepicker
          *ngIf="!dateReseter"
          [date]="getDate"
          (dateChanged)="dateChangeHandler($event)"
          [placeholder]="T.common.expected_due_date | translate"
          [showPlaceholderFirst]="true"
          [disableMargin]="true"
        >
        </app-datepicker>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          [app-tooltip]="{
            title: T.common.expected_due_date | translate,
            message:
              T.common.the_date_by_which_your_item_should_be_completed
              | translate: { localisedProject: localisedProject, localisedTask: localisedTask }
          }"
          size="small"
          icon="error_outline"
          [text]="T.common.what_s_this | translate"
        ></app-button-transparent>
      </div>
    </div>
    <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          [filterType]="filterTypes.Owner"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.common.assign_item | translate: { item: T.defaultLocalizations.owner.many | translate }"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_me | translate"
          (clicked)="setAccountForCurrentOwner()"
        ></app-button-transparent>
      </div>
    </div>
    <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
      <div class="selection">
        <app-filter-dropdown-new
          #dropDown
          [singleSelect]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Department"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.assign_lead_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex" *ngIf="employee?.departmentId > 0">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="setMyDepartment()"
        ></app-button-transparent>
      </div>
    </div>
    <!-- Hidden Options /// It is important to use it with [hidden] instead of *ngIf because some of the filters have inital data that needs to be added-->
    <div [hidden]="!isHiddenOptionVisible" class="event col-md-6 col-12">
      <app-filter-dropdown-new
        [filterType]="filterTypes.Event"
        [filterByModuleObjectType]="false"
        [appliedFilters]="filters"
        [typeahead]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <div [hidden]="!isHiddenOptionVisible" class="status col-md-6 col-12">
      <app-filter-dropdown-new
        [useSearch]="false"
        [currentlySelectedSettingValue]="'1'"
        [disableMargin]="true"
        [displayError]="triggerErrors"
        [required]="true"
        [singleSelect]="true"
        [filterByModuleObjectType]="false"
        [filterType]="filterTypes.Project_Status"
        [appliedFilters]="filters"
        [typeahead]="true"
        [label]="(T.common.status.one | translate) + '*'"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <div [hidden]="!isHiddenOptionVisible" class="description col-12">
      <app-details-editable-field
        [input]="form.controls.description.value ? form.controls.description.value : ''"
        [header]="T.defaultLocalizations.description.one | translate"
        [type]="editableFieldTypes.RichText"
        [canEdit]="true"
        (onUpdate)="updateDescription($event)"
        (onInputChange)="updateDescription($event)"
        [placeholder]="T.common.add_item | translate: { item: T.defaultLocalizations.description.one | translate }"
      ></app-details-editable-field>
    </div>

    <app-add-modal-tag-groups
      [hidden]="!isHiddenOptionVisible"
      class="col-12"
      [layoutClasses]="'col-md-6 col-12'"
      [filters]="filters"
      [tagGroupObjectType]="objectTypes.Project"
      (onFiltersUpdated)="handleFilterChange($event)"
    ></app-add-modal-tag-groups>
    <!-- End of Hidden Options -->
    <div class="show-more-btn d-none d-md-block col-12">
      <app-button-bar-rounded
        (clicked)="showHiddenOptions()"
        [isOpen]="isHiddenOptionVisible"
        [fitToContainer]="true"
        [text]="T.common.show_more | translate"
        [secondText]="T.common.show_less | translate"
      ></app-button-bar-rounded>
    </div>
    <div class="buttons-row col-12 d-flex justify-content-between align-items-center">
      <app-button-transparent
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.cancel | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [disabled]="form.invalid"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[selectedButtonOption]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
    </div>
  </div>
  <ng-template #tooltipTemplate>
    <table class="styled-table">
      <tr *ngFor="let keybind of keyboardShortcuts">
        <td>
          <span class="key-shortcut">{{ keybind.key }}</span> -
          <span class="key-description">{{ keybind.description }}</span>
        </td>
      </tr>
    </table>
  </ng-template>
</div>
