<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!accordionLozenge && !customTemplate">
  <div
    class="details-lozenge-wrapper"
    #detailsLozengeWrapper
    (mouseenter)="toggleShowEditButton(true)"
    (mouseleave)="toggleShowEditButton(false)"
  >
    <ng-template #filtersInlineEditContainer>
      <app-filters-inline-edit-container
        *ngIf="!useLightUpdate"
        [isFilterRequired]="isFilterRequired"
        [mobile]="isMobile"
        [lozengeInlineEdit]="true"
        [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
        [filters]="allFilters"
        [customTitle]="heading"
        [singleSelect]="singleSelect"
        [appliedFilters]="filters"
        [isUsedAlone]="true"
        (filtersUpdated)="updateFilters($event)"
      ></app-filters-inline-edit-container>
      <app-simple-filters-container
        *ngIf="useLightUpdate"
        [isFilterRequired]="isFilterRequired"
        [mobile]="isMobile"
        [lozengeInlineEdit]="true"
        [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
        [allowedFilters]="allFilters"
        [singleSelect]="singleSelect"
        [appliedFilters]="filters"
        (filtersUpdated)="updateFilters($event)"
      ></app-simple-filters-container>
    </ng-template>

    <div class="heading" [class.not-editable]="!canEdit && !useFilterValueAsLink">
      <i class="material-icons" *ngIf="iconByType">{{ iconByType }}</i>
      {{ lozengeTitle }}:
      <ng-container *ngIf="canEdit">
        <app-button-transparent
          *ngIf="!handleEditExternally"
          [text]="T.common.edit | translate"
          icon="edit"
          textColor="primary-text"
          class="ml-2"
          iconFontSize="16"
          ngDropdown
          (dropdownToggled)="toggleEditMode($event)"
          [templateRef]="filtersInlineEditContainer"
          [style.visibility]="IsEditButtonVisible() ? 'visible' : 'hidden'"
        >
        </app-button-transparent>

        <app-button-transparent
          *ngIf="handleEditExternally"
          [text]="T.common.edit | translate"
          icon="edit"
          textColor="primary-text"
          class="ml-2"
          iconFontSize="16"
          [style.visibility]="IsEditButtonVisible() ? 'visible' : 'hidden'"
          (clicked)="onEditClicked()"
        >
        </app-button-transparent>
      </ng-container>
    </div>
    <div
      class="card-lozenge"
      [class.not-editable]="!canEdit && !useFilterValueAsLink"
      [class.card-lozenge-disabled]="editMode"
    >
      <div class="card-lozenge-value" *ngIf="!showOverflowingLozenges">
        <ng-container *ngFor="let filter of filtersByFilterType; let i = index">
          <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount">
            <span
              class="filter-lozenge-text primary"
              *ngIf="filter.isPrimary"
              (click)="goToDetailsPage($event, filter)"
              [app-tooltip]="{
                title:
                  (filter.isPrimary ? (T.common.primary | translate) : (T.common.non_primary | translate)) +
                  ' ' +
                  lozengeTitle,
                message: primaryFilterTooltipMessage
              }"
              >{{ filter.filterText }} <i class="material-icons material-icons-grade star"> grade</i>
            </span>
            <span
              class="filter-lozenge-text"
              [class.not-editable]="!canEdit && !useFilterValueAsLink"
              *ngIf="!filter.isPrimary"
              (click)="goToDetailsPage($event, filter)"
              [app-tooltip]="{ message: filter.filterText }"
              >{{ filter.filterText }}</span
            >
            <span class="no-value" *ngIf="filter.filterText == ''" (click)="goToDetailsPage($event, filter)">{{
              T.common.no_item | translate: { item: lozengeTitle }
            }}</span>
            <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount - 1" style="text-decoration: none"
              >,
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!filtersByFilterType.length">
          <span class="no-value">{{ T.common.no_item | translate: { item: lozengeTitle } }}</span>
        </ng-container>
      </div>
      <div class="card-lozenge-expanded-value" *ngIf="showOverflowingLozenges">
        <ng-container *ngFor="let filter of filtersByFilterType; let i = index">
          <ng-container *ngIf="i < filtersByFilterType.length">
            <span
              class="filter-lozenge-text primary"
              *ngIf="filter.isPrimary"
              (click)="goToDetailsPage($event, filter)"
              [app-tooltip]="{
                title:
                  (filter.isPrimary ? (T.common.primary | translate) : (T.common.non_primary | translate)) +
                  ' ' +
                  lozengeTitle,
                message: primaryFilterTooltipMessage
              }"
              >{{ filter.filterText }} <i class="material-icons material-icons-grade star"> grade</i>
            </span>
            <span
              class="filter-lozenge-text"
              [app-tooltip]="{ message: filter.filterText }"
              *ngIf="!filter.isPrimary"
              (click)="goToDetailsPage($event, filter)"
              >{{ filter.filterText }}</span
            >
            <span class="no-value" *ngIf="filter.filterText == ''" (click)="goToDetailsPage($event, filter)">{{
              T.common.no_item | translate: { item: lozengeTitle }
            }}</span>
            <!-- <span class="filter-lozenge-text" (click)="goToDetailsPage($event, filter)">{{filter.filterText}}</span> -->
            <ng-container *ngIf="i < filtersByFilterType.length - 1" style="text-decoration: none"
              >,&nbsp;</ng-container
            >
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!filtersByFilterType.length">
          <span class="no-value">{{ T.common.no_item | translate: { item: lozengeTitle } }}</span>
        </ng-container>
      </div>
      <div
        class="card-lozenge-overflowing-count"
        (click)="showOverflowingLozenges = !showOverflowingLozenges"
        *ngIf="overflowingCount > 0"
      >
        {{ !showOverflowingLozenges ? 'Show All' : 'Show Less' }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="accordionLozenge && !customTemplate && !handleEditExternally">
  <div
    class="card-lozenge-value accordion-lozenge text-truncate"
    [ngClass]="{'p-0': clearPaddingInLozenge, 'pe-none': !canEdit && !useFilterValueAsLink}"
    *ngIf="!showOverflowingLozenges"
    ngDropdown
    [templateRef]="filtersInlineEditContainerAccordion"
    (dropdownToggled)="toggleEditMode($event)"
  >
    <ng-template #filtersInlineEditContainerAccordion>
      <ng-container *ngIf="canEdit">
        <app-filters-inline-edit-container
         *ngIf="!useLightUpdate"
          [isSearchActive]="isSearchActive"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [filters]="allFilters"
          [customTitle]="heading"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          [isUsedAlone]="true"
          (filtersUpdated)="updateFilters($event)"
        ></app-filters-inline-edit-container>
        <app-simple-filters-container
          *ngIf="useLightUpdate"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [allowedFilters]="allFilters"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          (filtersUpdated)="updateFilters($event)"
        ></app-simple-filters-container>
      </ng-container>
    </ng-template>
    <ng-container *ngFor="let filter of filtersByFilterType; let i = index">
      <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount">
        <span
          class="filter-lozenge-text primary"
          *ngIf="filter.isPrimary"
          (click)="goToDetailsPage($event, filter)"
          [app-tooltip]="{
            title:
              (filter.isPrimary ? (T.common.primary | translate) : (T.common.non_primary | translate)) +
              ' ' +
              lozengeTitle,
            message: primaryFilterTooltipMessage
          }"
          >{{ filter.filterText }} <i class="material-icons material-icons-grade star"> grade</i>
        </span>
        <span
          class="filter-lozenge-text"
          [app-tooltip]="{ message: filter.filterText }"
          [class.not-editable]="!canEdit && !useFilterValueAsLink"
          [class.d-flex]="showAccordionLozengeIcon"
          *ngIf="!filter.isPrimary"
          (click)="goToDetailsPage($event, filter)"
          ><i class="material-icons accordion-icon me-2" *ngIf="iconByType && showAccordionLozengeIcon">{{
            iconByType
          }}</i>
          <span class="text-nowrap">{{ filter.filterText }}</span></span
        >
        <span class="no-value"
        *ngIf="filter.filterText == ''"
        (click)="goToDetailsPage($event, filter)"
        [class.not-editable]="!canEdit && !useFilterValueAsLink"
        >{{
          T.common.no_item | translate: { item: lozengeTitle }
        }}</span>
        <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount - 1" style="text-decoration: none"
          ><span [innerHTML]="accordionOverflowDivider"></span>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!filtersByFilterType.length">
      <span class="no-value" [class.not-editable]="!canEdit && !useFilterValueAsLink">{{ T.common.no_item | translate: { item: lozengeTitle } }}</span>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="accordionLozenge && !customTemplate && handleEditExternally">
  <div
    class="card-lozenge-value accordion-lozenge text-truncate"
    [ngClass]="{'p-0': clearPaddingInLozenge}"
    *ngIf="!showOverflowingLozenges"
    (click)="onEditClicked()"
  >
    <ng-template #filtersInlineEditContainerAccordion>
      <ng-container *ngIf="canEdit">
        <app-filters-inline-edit-container
         *ngIf="!useLightUpdate"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [customTitle]="heading"
          [filters]="allFilters"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          [isUsedAlone]="true"
          (filtersUpdated)="updateFilters($event)"
        ></app-filters-inline-edit-container>
        <app-simple-filters-container
          *ngIf="useLightUpdate"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [allowedFilters]="allFilters"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          (filtersUpdated)="updateFilters($event)"
        ></app-simple-filters-container>
      </ng-container>
    </ng-template>
    <ng-container *ngFor="let filter of filtersByFilterType; let i = index">
      <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount">
        <span
          class="filter-lozenge-text primary"
          *ngIf="filter.isPrimary"
          (click)="goToDetailsPage($event, filter)"
          [app-tooltip]="{
            title:
              (filter.isPrimary ? (T.common.primary | translate) : (T.common.non_primary | translate)) +
              ' ' +
              lozengeTitle,
            message: primaryFilterTooltipMessage
          }"
          >{{ filter.filterText }} <i class="material-icons material-icons-grade star"> grade</i>
        </span>
        <span
          class="filter-lozenge-text"
          [app-tooltip]="{ message: filter.filterText }"
          [class.not-editable]="!canEdit && !useFilterValueAsLink"
          [class.d-flex]="showAccordionLozengeIcon"
          *ngIf="!filter.isPrimary"
          (click)="goToDetailsPage($event, filter)"
          ><i class="material-icons accordion-icon me-2" *ngIf="iconByType && showAccordionLozengeIcon">{{
            iconByType
          }}</i>
          <span class="text-nowrap">{{ filter.filterText }}</span></span
        >
        <span class="no-value" *ngIf="filter.filterText == ''" (click)="goToDetailsPage($event, filter)">{{
          T.common.no_item | translate: { item: lozengeTitle }
        }}</span>
        <ng-container *ngIf="i < filtersByFilterType.length - overflowingCount - 1" style="text-decoration: none"
          ><span [innerHTML]="accordionOverflowDivider"></span>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!filtersByFilterType.length">
      <span class="no-value">{{ T.common.no_item | translate: { item: lozengeTitle } }}</span>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="customTemplate">
  <div
    class="custom-lozenge-template"
    ngDropdown
    [templateRef]="filtersInlineEditContainerAccordion"
    (dropdownToggled)="toggleEditMode($event)"
  >
    <ng-template #filtersInlineEditContainerAccordion>
      <ng-container *ngIf="canEdit">
        <app-filters-inline-edit-container
          *ngIf="!useLightUpdate"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [filters]="allFilters"
          [customTitle]="heading"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          [useClearAllButton]="useClearAllButton"
          [isUsedAlone]="true"
          (filtersUpdated)="updateFilters($event)"
        ></app-filters-inline-edit-container>
        <app-simple-filters-container
          *ngIf="useLightUpdate"
          [isFilterRequired]="isFilterRequired"
          [mobile]="isMobile"
          [lozengeInlineEdit]="true"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          [allowedFilters]="allFilters"
          [singleSelect]="singleSelect"
          [appliedFilters]="filters"
          (filtersUpdated)="updateFilters($event)"
        ></app-simple-filters-container>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
  </div>
</ng-container>
