<app-modal>
  <app-modal-header
    style="z-index: 50"
    [title]="title"
    [showDelete]="checklistItem.id"
    (closed)="bsModalRef.hide()"
    (deleted)="onDeleteItem()"
  >
  </app-modal-header>

  <app-modal-body class="custom-scrollbar">
    <div class="row col-row-space custom-gutters">
      <div class="col-12" *ngIf="checklistItem.checklistTemplateTitle">
        <div class="star-row-wrapper">
          <span class="sub-heading"
            >{{ T.control.added_from_checklist_template | translate }} "{{
              checklistItem.checklistTemplateTitle
            }}"</span
          >
        </div>
      </div>
      <div class="col-12" *ngIf="showTitle">
        <app-text-field
          [(ngModel)]="checklistItem.title"
          [disabled]="isFromTemplate"
          name="title"
          [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
          [maxLength]="80"
          [required]="true"
        >
        </app-text-field>
      </div>
      <div class="col-12" *ngIf="showDescription">
        <app-text-field
          [(ngModel)]="checklistItem.description"
          [disabled]="isFromTemplate"
          name="description"
          [placeholder]="T.defaultLocalizations.description.one | translate"
          [maxLength]="255"
        >
        </app-text-field>
      </div>
      <div class="col-12" *ngIf="showOwnerFilter">
        <app-filter-dropdown-new
          (onFilterUpdate)="onEmployeesUpdated($event)"
          [singleSelect]="true"
          [currentlySelectedSettingValue]="checklistItem.employeeId"
          [appliedFilters]="filterSettings"
          [filterType]="filterTypes.Owner"
          [typeahead]="true"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-12">
        <app-search-dropdown-r
          *ngIf="showResponseTypeDD"
          [disabled]="isEditMode ? !isAdmin : false"
          [multiSelect]="false"
          [currentlySelectedObject]="selectedResponseType"
          (objectCleared)="onResponseTypeCleared($event)"
          (objectSelected)="onResponseTypeSelected($event)"
          [objects]="reponseTypeOptions"
          bindProperty="value"
          [label]="T.control.response_type | translate"
        >
        </app-search-dropdown-r>
      </div>
      <div class="col-12" *ngIf="showStar">
        <div class="star-row-wrapper">
          <span class="star-description">{{ T.common.star_this_item | translate }} </span>
          <i
            class="material-icons"
            [class.disabled]="isFromTemplate"
            *ngIf="checklistItem.starred"
            (click)="changeStarred(false)"
            >star</i
          >
          <i
            class="material-icons"
            [class.disabled]="isFromTemplate"
            *ngIf="!checklistItem.starred"
            (click)="changeStarred(true)"
            >star_border</i
          >
          <div class="sub-heading">{{ subHeading }}</div>
        </div>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle
      *ngIf="checklistItem.id"
      size="large"
      [text]="T.common.delete | translate"
      buttonTheme="danger"
      (clicked)="onDeleteItem()"
    ></app-button-rectangle>
    <app-button-rectangle
      [buttonTheme]="'white'"
      [size]="'large'"
      [text]="T.common.cancel | translate"
      (clicked)="onCancel()"
    ></app-button-rectangle>
    <app-button-rectangle
      [text]="T.common.save | translate"
      [size]="'large'"
      [disabled]="!isValid"
      (clicked)="onSave()"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>
