<div class="status-stars" ngDropdown [templateRef]="filterTemplate" [canOpen]="canChange">
  <div class="priority-starts-container d-flex align-items-center justify-content-between">
    <label *ngIf="showSelectPriorityLabel">{{ T.common.select_priority | translate }}</label>
    <div>
      <i
        data-priority="1"
        class="material-icons-outlined mt-2 priority_star"
        [class.wetrack-black]="selectedPriority && selectedPriority.filterValue > 0"
        style="color: #cccccc; cursor: pointer"
        >star</i
      >
      <i
        data-priority="2"
        class="material-icons-outlined mt-2 priority_star"
        [class.wetrack-black]="selectedPriority && selectedPriority.filterValue > 1"
        style="color: #cccccc; cursor: pointer"
        >star</i
      >
      <i
        data-priority="3"
        class="material-icons-outlined mt-2 priority_star"
        [class.wetrack-black]="selectedPriority && selectedPriority.filterValue > 2"
        style="color: #cccccc; cursor: pointer"
        >star</i
      >
    </div>
  </div>
</div>

<ng-template #filterTemplate style="position: absolute; z-index: 2147483646; visibility: hidden">
  <div class="filters-container" [ngStyle]="{'width.px': widthPx, 'margin-top.px': topOffsetPx}">
    <div class="priority-container">
      <div class="priority-container-entries-container">
        <app-dropdown-option
          *ngFor="let entry of displayedPriorityFilters"
          [value]="entry"
          [mobile]="false"
          [useBorderBottom]="false"
          [useCheckbox]="false"
          [allowDeselect]="false"
          [selected]="selectedPriority && selectedPriority.filterValue.toString() == entry.filterValue.toString()"
          (selectionChange)="onEntrySelected(entry)"
        >
          <div *ngIf="entry.colour" class="filled-round-box mr-8" [style.background]="entry.colour"></div>
          {{ entry.filterText }}
        </app-dropdown-option>
      </div>
    </div>
    <app-filters-desktop-footer
      [useClearAllButton]="true"
      [useCancelApplyButtons]="true"
      [clearDisabled]="false"
      [applyDisabled]="false"
      (cancel)="onCancel()"
      (apply)="onApply()"
      (clear)="onClearAll()"
    >
    </app-filters-desktop-footer>
  </div>
</ng-template>
