<div class="settings-accordion-group">
  <div
    class="settings-accordion-group-header"
    [class.settings-accordion-group-header-border-bottom]="useHeaderBorderBottom"
    (click)="onToggle()"
  >
    <div
      class="settings-accordion-group-header-icon"
      [class.settings-accordion-group-header-icon-active]="opened"
      *ngIf="icon"
    >
      <div class="responsive-flex-wrapper">
        <i class="material-icons">{{ icon }}</i>
      </div>
    </div>
    <div class="settings-accordion-group-header-title" [class.settings-accordion-group-title-bold]="opened">
      {{ title }}
    </div>
    <div class="settings-accordion-group-header-icon" [class.settings-accordion-group-header-icon-active]="opened">
      <div class="responsive-flex-wrapper">
        <i class="material-icons">{{ opened ? 'expand_less' : 'expand_more' }}</i>
      </div>
    </div>
  </div>
  <div [class.settings-accordion-group-content]="opened && !useHeaderBorderBottom" [@slideInOut]="!opened">
    <ng-content></ng-content>
  </div>
</div>
