<ng-container *ngIf="visible">
  <div class="ng-image-preview" [class.ng-image-preview-visible]="visible">
    <div class="image-preview" *ngIf="object">
      <div class="image-preview-header d-flex justify-content-between mb-4">
        <div class="image-preview-header-left d-flex column">
          <div class="profile-icon">
            <i class="material-icons profile-icon image-text-info-white">account_circle</i>
          </div>
          <div>
            <div class="image-created-by image-text-info-white">{{ user.filterText }}</div>
            <div class="image-created-date image-text-info-white date-text">{{ createdDate }}</div>
          </div>
        </div>
        <div class="image-preview-header-center image-text-title-white column">
          <ng-container *ngIf="object.title">{{ object.title }}</ng-container>
          <ng-container *ngIf="!object.title">{{ object.filename }}</ng-container>
        </div>
        <div class="image-preview-header-right column">
          <div class="actions-wrapper d-flex">
            <div class="action-download me-2" (click)="downloadAttachment()">
              <i class="material-icons action-icon image-text-info-white">download</i>
            </div>
            <div class="action-delete" (click)="deleteAttachment()">
              <i class="material-icons action-icon image-text-info-white">delete</i>
            </div>
          </div>
        </div>
      </div>
      <div class="image-prevew-wrapper">
        <img class="image" [src]="imageUrl" />
      </div>
    </div>

    <div class="ng-image-preview-close" (click)="hide()">
      <i class="material-icons">close</i>
    </div>
  </div>
</ng-container>
