<div class="d-flex align-items-center sorting-container"
  ngDropdown
  [templateRef]="sortingTemplate"
  (dropdownToggled)="onDropdownToggle($event)"
  [canOpen]="true">
  <i class="material-icons">sort</i>

  <span class="sorting-text ms-1">{{ currentlySelectedSortingOption?.value }}</span>

  <ng-template #sortingTemplate style="position: absolute; z-index: 2147483646; visibility: hidden">
    <div class="sorting-dropdown" [ngStyle]="customSortingDropdownStyles">
      <div class="sorting-options custom-scrollbar">
        <app-dropdown-option
        *ngFor="let item of sortDropdownItems"
        [value]="item.value"
        [mobile]="false"
        [useBorderBottom]="false"
        [useCheckbox]="false"
        [allowDeselect]="false"
        [selected]="currentlySelectedSortingOption.value === item.value"
        (selectionChange)="onSelectEntry(item)"
        >
          {{ item.value }}
        </app-dropdown-option>
    </div>
      <app-filters-desktop-footer
        [useClearAllButton]="false"
        [useCancelApplyButtons]="true"
        [clearDisabled]="false"
        [applyDisabled]="false"
        (cancel)="onCancel()"
        (apply)="onApply()"
        >
      </app-filters-desktop-footer>
    </div>
  </ng-template>
</div>
