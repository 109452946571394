<ng-container *ngIf="displayData; else loadingStateContainer">
  <div class="employee-container" ngDropdown [templateRef]="filtersInlineEditContainer" [canOpen]="canEdit">
    <ng-container *ngIf="isEmployeeFilterType">
      <ng-container *ngIf="currentEmployees.length">
        <div
          *ngFor="let employee of limitedEmployeesByMaxVisible; index as i"
          class="employee-logo"
          [ngStyle]="{ 'z-index': i }"
        >
          <app-employee-avatar [employee]="employee" [size]="getEnumSize()" [showTooltipWithFullName]="true"></app-employee-avatar>
        </div>
        <div *ngIf="overflowEmployeesCount" class="overflow-count-container" [ngClass]="size">
          <span class="count">+{{ overflowEmployeesCount }}</span>
        </div>
        <ng-container *ngIf="currentEmployees.length && showAddButton && canEdit">
          <div class="assing-owner-container">
            <i class="material-icons">add</i>
            Assing Owner
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!currentEmployees.length && showEmptyAddBtn && canEdit">
        <div class="add-subscriber" [app-tooltip]="{ message: T.common.add_item | translate: {item: filterType | localiseFilterType} }">
          <i class="material-icons">add</i>
        </div>
      </ng-container>
      <ng-container *ngIf="!currentEmployees.length && showEmptyAddBtn && !canEdit">
        <div class="non-editable-subscriber" [app-tooltip]="{ message: T.common.no_item | translate: {item: filterType | localiseFilterType} }">
          <i class="material-icons">person_off</i>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!isEmployeeFilterType">
      <ng-container *ngIf="customFilters.length">
        <div
          *ngFor="let filter of limitedCustomFiltersByMaxVisible; index as i"
          class="employee-logo"
          [ngStyle]="{ 'z-index': i }"
        >
          <app-initials-avatar [title]="filter.filterText" [size]="getEnumSize()" [showTooltipWithFullName]="true"></app-initials-avatar>
        </div>
        <div *ngIf="overflowEmployeesCount" class="overflow-count-container" [ngClass]="size">
          <span class="count">+{{ overflowEmployeesCount }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!customFilters.length && showEmptyAddBtn && canEdit">
        <div class="add-subscriber" [app-tooltip]="{ message: T.common.add_item | translate: {item: filterType | localiseFilterType} }">
          <i class="material-icons">add</i>
        </div>
      </ng-container>
      <ng-container *ngIf="!customFilters.length && showEmptyAddBtn && !canEdit">
        <div class="non-editable-subscriber" [app-tooltip]="{ message: T.common.no_item | translate: {item: filterType | localiseFilterType} }">
          <i class="material-icons">person_off</i>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #loadingStateContainer>
  <div class="employee-container">
    <ng-container *ngFor="let item of [].constructor((maxVisible < ownerFiltersSize) ? maxVisible + 1 : ownerFiltersSize)">
      <div class="employee-logo-skeletion" [ngClass]="size"></div>
    </ng-container>
  </div>
</ng-template>

<ng-template #filtersInlineEditContainer>
  <app-filters-inline-edit-container
    [isFilterRequired]="required"
    [mobile]="isMobile"
    [lozengeInlineEdit]="true"
    [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
    [filters]="allowedFilters"
    [singleSelect]="singleSelect"
    [appliedFilters]="filters"
    [isUsedAlone]="true"
    (filtersUpdated)="updateFilters($event)"
  ></app-filters-inline-edit-container>
</ng-template>
