<div class="wrapper">
  <div class="widget-wrapper">
    <div class="widget-wrapper-top">
      <div class="widget-wrapper-top-left">
        <div class="widget-wrapper-title">
          <span>{{ T.dashboard.countdown_timer | translate }}</span>
        </div>
      </div>
      <div class="widget-wrapper-top-right">
        <app-button-icon-square
          icon="more_horiz"
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="moreButtonTemplate"
        ></app-button-icon-square>
      </div>
    </div>
    <div class="widget-wrapper-main custom-scrollbar">
      <ng-container *ngIf="!loading">
        <div #countdownContainer class="chart-container-wrapper">
          <ng-container *ngIf="!loading">
            <ng-container *ngIf="hasCountdownSetting">
              <div class="title">
                {{ countdownSetting.countdownTitle }}
              </div>
              <div class="countdown-wrapper" *ngIf="dateIsNotInPast">
                <div class="time-wrapper">
                  <span class="time-info">{{ weeks }}</span>
                  <span class="time-title">{{ T.calendar.week.many | translate }}</span>
                </div>
                <div class="time-wrapper">
                  <span class="time-info">{{ days }}</span>
                  <span class="time-title">{{ T.calendar.day.many | translate }}</span>
                </div>
                <div class="time-wrapper">
                  <span class="time-info">{{ hours }}</span>
                  <span class="time-title">{{ T.calendar.hour.many | translate }}</span>
                </div>
                <div class="time-wrapper">
                  <span class="time-info">{{ minutes }}</span>
                  <span class="time-title">{{ T.calendar.minute.many | translate }}</span>
                </div>
                <div class="time-wrapper">
                  <span class="time-info">{{ seconds }}</span>
                  <span class="time-title">{{ T.calendar.second.many | translate }}</span>
                </div>
              </div>
              <div class="empty-state-wrapper" *ngIf="!dateIsNotInPast">
                <div class="empty-state-heading">
                  {{ T.common.countdown_complete | translate }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="!hasCountdownSetting">
              <div class="empty-state-wrapper">
                <i class="material-icons"> schedule </i>
                <div class="empty-state-heading">{{ T.common.no_countdown_set | translate }}!</div>
                <div class="empty-state-subtext">
                  {{ T.common.admin_users_can_set_countdown_in_dashboard | translate }}
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="loading">
            <app-loading [displayLogo]="true">
              <p>{{ T.common.getting_ready | translate }}...</p>
            </app-loading>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="loading">
        <app-loading [displayLogo]="true">
          <p>{{ T.common.getting_ready | translate }}...</p>
        </app-loading>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #moreButtonTemplate>
  <div class="dropdown-options-container">
    <div (click)="configureWidget()" class="dropdown-option separator">Configure Widget</div>
  </div>
</ng-template>
