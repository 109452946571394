<div #wrapper class="list-wrapper" *ngIf="!loading">
  <div *ngIf="!smallCards" class="large-cards-wrapper" [ngStyle]="{ height: !items.length ? 'initial' : '100%' }">
    <ng-container *ngIf="items.length">
      <app-paginated-cards [items]="items" [cardTemplate]="cardTemplate"></app-paginated-cards>
    </ng-container>
    <ng-container *ngIf="!items.length">
      <div class="no-results-wrapper">
        <div class="responsive-flex-wrapper">
          <i class="material-icons">error</i>
        </div>
        <span class="no-results-heading">No items match these filters</span>
      </div>
    </ng-container>
  </div>

  <div *ngIf="smallCards" class="small-cards-wrapper">
    <ng-template #leftSideTemplate>
      <div class="header-wrapper">
        <div class="top">
          <div>{{ title }}</div>

          <div class="sort-btn-wrapper">
            <ng-container *ngTemplateOutlet="sortButtonTemplate"></ng-container>
          </div>
        </div>
        <div class="bottom" (click)="goBack()" *ngIf="useBackToListButton">
          <div class="responsive-flex-wrapper">
            <i class="material-icons">arrow_back</i>
          </div>
          <span>Back to List</span>
        </div>
      </div>
    </ng-template>

    <ng-template #rightSideTemplate>
      <router-outlet></router-outlet>
    </ng-template>

    <app-split-pane
      [loading]="loading"
      [items]="items"
      [cardTemplate]="cardTemplate"
      [leftSideTemplate]="leftSideTemplate"
      [rightSideTemplate]="rightSideTemplate"
      [showHelperAndSort]="showHelperAndSort"
      (expandCollapseHappened)="onExpandCollapse($event)"
    >
    </app-split-pane>
  </div>
</div>
<app-loading *ngIf="loading" [displayLogo]="displayLogo">
  <p>Loading...</p>
</app-loading>
