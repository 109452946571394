<ng-template #templateRef>
  <app-dropdown-text-item
    *ngFor="let dropdownItem of sortDropdownItems; let i = index"
    [dropdownItem]="dropdownItem"
    [bindProperty]="'value'"
    [selected]="i === currentlySelectedIndex"
    (dropdownItemSelected)="onDropdownItemSelected(i, dropdownItem)"
  >
  </app-dropdown-text-item>
</ng-template>

<button
  [app-popup]="{
    templateRef: templateRef,
    position: position,
    arrowPosition: arrowPosition,
    closeUponSelection: true,
    paddingless: true
  }"
>
  <div class="button-content">
    <i class="material-icons">arrow_drop_down</i>
    <div class="button-text text-truncate">
      {{ title }}
    </div>
  </div>
</button>
