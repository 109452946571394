<div *ngIf="isAdmin && showWeatherOptions">
  <div class="section-header">
    <span>{{ T.dashboard.weather_widget | translate }}</span>
  </div>
  <div class="row content-wrapper mb-2">
    <div class="col-12 mb-2 pl-0">
      <span class="heading-text">{{ T.dashboard.chose_temperature_unit | translate }}</span>
    </div>
    <div class="col-12 mb-3 pl-0">
      <app-search-dropdown-r
        [objects]="temperatureOptions"
        bindProperty="value"
        [currentlySelectedObject]="selectedTemperatureOption"
        (objectSelected)="onTemperatureOptionChange($event)"
      >
      </app-search-dropdown-r>
    </div>
  </div>
  <ng-container *ngIf="dashboardWeatherLocations !== null && showWeatherOptions">
    <div class="row content-wrapper mb-2">
      <div class="col-12 mb-2 pl-0">
        <span class="heading-text">{{ T.dashboard.set_weather_locations | translate }}</span>
      </div>
      <div class="col-12 mb-3 pl-0">
        <div class="weather-location-table" *ngFor="let w of dashboardWeatherLocations; let i = index">
          <div class="title-width">
            <span [title]="w.location.locationDetails">{{ w.location.locationDetails }}</span>
          </div>
          <div class="right">
            <a (click)="onMakeDefault(w)" class="location-link mr-2">
              <i class="material-icons location-icons active" *ngIf="w.isDefault">star</i>
              <i class="material-icons location-icons" *ngIf="!w.isDefault">star_border</i>
            </a>
            <a (click)="onRemoveWeatherLocation(i)" class="location-link">
              <i class="material-icons location-icons">delete_forever</i>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 pl-0">
        <button class="btn small-btn btn-white" (click)="addWeatherLocation()">{{ T.common.add | translate }}</button>
        <button
          class="btn small-btn action-btn float-right"
          *ngIf="showWeatherLocationUpdateButton"
          (click)="updateWeatherLocations()"
        >
          {{ T.common.update | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
