<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.cancel | translate"
      (clicked)="confirmClose()"
    ></app-button-transparent>
    <span class="modal-title">{{ T.common.add_item | translate: { item: localisedTaskGroup } }}</span>
    <span class="material-icons close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
    <div class="add-task-group-field container-with-bottom-element col-12">
      <app-filter-dropdown-new
        class="modal-project"
        [displayError]="triggerErrors"
        [filterByModuleObjectType]="false"
        [filterType]="filterTypes.Project"
        [disableMargin]="true"
        [required]="true"
        [singleSelect]="true"
        [appliedFilters]="filters"
        [typeahead]="true"
        [currentlySelectedSettingValue]="selectedProject?.filterValue"
        (onFilterUpdate)="handleProjectChange($event)"
        [disabled]="disableProjectDropdown"
        [label]="localisedProject + '*'"
      >
      </app-filter-dropdown-new>
    </div>
    <div class="add-task-group-field d-flex container-with-bottom-element col-12">
      <div class="drop-arrow">
        <div class="vertical-line"></div>
        <div class="horizontal-line"></div>
      </div>
      <app-text-field
        [autofocus]="true"
        [minLength]="3"
        [maxLength]="60"
        [displayError]="triggerErrors"
        class="flex-fill modal-taskgroup"
        formControlName="taskGroupTitle"
        [disableMargin]="true"
        [svg]="taskSvg"
        [placeholder]="(T.common.item_title | translate: { item: localisedTaskGroup }) + '*'"
        [formGroup]="form"
        [useReactiveMessages]="true"
      >
      </app-text-field>
    </div>
    <div class="buttons-row col-12 d-flex justify-content-between align-items-center">
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.close | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[selectedButtonOption]"
          [options]="buttonOptions"
          [disabled]="!isValid"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
    </div>
  </div>
</div>
