<div
  #searchIconWrapper
  class="search-icon responsive-flex-wrapper"
  *ngIf="!inputVisible"
  (click)="onShowHideInput($event)"
>
  <i class="material-icons">search</i>
</div>

<div class="list-search-input-wrapper" *ngIf="inputVisible">
  <div #listSearchInputWrapper class="list-search-input-inner-wrapper">
    <input #input type="text" (input)="onInput()" [placeholder]="placeholder" />
    <div class="responsive-flex-wrapper" *ngIf="input.value" (click)="onClearInput()">
      <i class="material-icons">close</i>
    </div>
    <div class="responsive-flex-wrapper">
      <i class="material-icons">search</i>
    </div>
  </div>
</div>
