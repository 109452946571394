<!-- We need to use an inline style so we can access it through the javascript -->
<!-- That way if one wants to change the default width one would only need to touch here -->
<div #wrapper class="tooltip-wrapper" [class.version-2]="stylingVersion === 2" style="max-width: 404px" [style.min-width.px]="width">
  <div class="content-wrapper">
    <ng-container *ngIf="title || message || templateRef" [ngSwitch]="position">
      <div *ngSwitchCase="tooltipPosition.Bottom" class="top-arrow-pointer-wrapper">
        <div class="top-arrow-pointer"></div>
        <div class="top-arrow-pointer-border"></div>
      </div>
      <div *ngSwitchCase="tooltipPosition.Top" class="bottom-arrow-pointer-wrapper">
        <div class="bottom-arrow-pointer"></div>
        <div class="bottom-arrow-pointer-border"></div>
      </div>
      <div *ngSwitchCase="tooltipPosition.Right" class="left-arrow-pointer-wrapper">
        <div class="left-arrow-pointer"></div>
        <div class="left-arrow-pointer-border"></div>
      </div>
      <div *ngSwitchCase="tooltipPosition.Left" class="right-arrow-pointer-wrapper">
        <div class="right-arrow-pointer"></div>
        <div class="right-arrow-pointer-border"></div>
      </div>
    </ng-container>

    <div *ngIf="title" class="header" [class.only-header]="!message && !templateRef">
      <div class="header-content">
        <div class="icon">
          <i class="material-icons">{{ icon || 'info_outline' }}</i>
        </div>
        <div class="text">
          <span #titleEl>{{ title }}</span>
        </div>
      </div>
    </div>

    <div class="message-wrapper" *ngIf="message">
      <span [innerHTML]="message" #messageEl></span>
    </div>

    <div class="message-wrapper" *ngIf="templateRef">
      <ng-template [ngTemplateOutlet]="templateRef"></ng-template>
    </div>
  </div>
</div>
