<div
  class="filters-list-entry"
  [class.filters-list-entry-selected]="selected"
  [class.filters-list-entry-mobile]="mobile"
  [class.filters-list-entry-focused]="focused"
>
<div class="filters-list-entry-text">
  <ng-content></ng-content>
</div>
  <div class="filters-list-entry-icon">
    <ng-container *ngIf="useCheckbox">
      <app-checkbox
      [isChecked]="selected" [needConfirmation]="true" (checked)="onClick($event)"></app-checkbox>
    </ng-container>
    <ng-container *ngIf="!useCheckbox">
      <i class="material-icons material-icons-add">add_circle_outline</i>
      <i [class.material-icons-applied-required]="!allowDeselect" class="material-icons material-icons-applied">done</i>
      <i *ngIf="allowDeselect" class="material-icons material-icons-remove"
         (click)="singleSelect || isActionsGroup ? onDeselect($event) : null" >highlight_off</i>
    </ng-container>
  </div>
</div>
<div class="filters-list-entry-border" *ngIf="useBorderBottom"></div>
