<div
  class="bubble"
  [style.background-color]="color"
  [style.width]="large ? '38px' : '28px'"
  [style.height]="large ? '38px' : '28px'"
>
  <div *ngIf="value !== undefined" class="value">{{ value }}</div>
  <div *ngIf="icon !== undefined" class="responsive-flex-wrapper">
    <i class="material-icons">{{ icon }}</i>
  </div>
</div>
