<ng-container *ngIf="!mobile">
  <div class="top-wrapper">
    <div
      class="d-flex"
      *ngIf="selectedEntries && selectedEntries.length > 0 && selectedEntries[0].filterText"
      (click)="onEntrySelected(selectedEntries[0])"
    >
      <i class="material-icons selected-icon m-r-5">done</i>
      <i class="material-icons remove-icon m-r-5">highlight_off</i>
      {{ selectedEntries[0].filterText }}
    </div>
    <div class="disabled-txt" *ngIf="!selectedEntries || !selectedEntries.length || !selectedEntries[0].filterText">
      {{ T.common.no_items_selected | translate }}
    </div>
  </div>

  <app-filters-search-input
    [filterTitle]="filterType | localiseFilterType"
    (search)="onSearch($event)"
  ></app-filters-search-input>

  <app-filters-entries-information
    [entriesCount]="filters.length"
    [selectedEntriesCount]="
      selected[0]?.filterText === 'All Channels' || selected[0]?.filterText === (T.common.all_channels | translate)
        ? 0
        : selectedEntries.length
    "
    [matchedEntriesCount]="searchValue ? filteredFilters.length : undefined"
  ></app-filters-entries-information>

  <app-filters-list-entries
    [required]="required"
    [entries]="filteredFilters"
    [selectedEntries]="selectedEntries"
    [mobile]="mobile"
    [required]="isFilterRequired"
    (entrySelected)="onEntrySelected($event)"
  ></app-filters-list-entries>

  <app-filters-desktop-footer
    [useClearAllButton]="false"
    [useCancelApplyButtons]="true"
    [applyDisabled]="isFilterChanged"
    [showApplyButton]="false"
    (cancel)="onCancel()"
    (apply)="onApply()"
  ></app-filters-desktop-footer>
</ng-container>
<ng-container *ngIf="mobile">
  <app-filters-mobile-header
    *ngIf="mobile"
    [title]="filterType | localiseFilterType"
    [useClear]="true"
    [clearDisabled]="!selectedEntries.length"
    (cancel)="onCancel()"
    (clear)="onClearAll()"
  ></app-filters-mobile-header>

  <div class="filters-multiselect-mobile">
    <app-filters-search-input
      [filterTitle]="filterType | localiseFilterType"
      (search)="onSearch($event)"
    ></app-filters-search-input>

    <div class="top-wrapper">
      <div
        class="d-flex"
        *ngIf="selectedEntries && selectedEntries.length"
        (click)="onEntrySelected(selectedEntries[0])"
      >
        <i class="material-icons selected-icon m-r-5">done</i>
        <i class="material-icons remove-icon m-r-5">highlight_off</i>
        {{ selectedEntries[0].filterText }}
      </div>
      <div class="disabled-txt" *ngIf="!selectedEntries || !selectedEntries.length">
        {{ T.common.no_items_selected | translate }}
      </div>
    </div>

    <app-filters-entries-information
      [entriesCount]="filters.length"
      [selectedEntriesCount]="selectedEntries.length"
      [matchedEntriesCount]="searchValue ? filteredFilters.length : undefined"
    ></app-filters-entries-information>

    <app-filters-list-entries
      [entries]="filteredFilters"
      [selectedEntries]="selectedEntries"
      [mobile]="mobile"
      [required]="isFilterRequired"
      (entrySelected)="onEntrySelected($event)"
    ></app-filters-list-entries>
  </div>

  <app-filters-mobile-footer
    *ngIf="mobile"
    [applyDisabled]="isFilterChanged"
    (apply)="onApply()"
  ></app-filters-mobile-footer>
</ng-container>
