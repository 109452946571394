<app-modal *ngIf="!isLoading; else loadingState">
  <app-modal-header
    [title]="
      isScheduled
        ? (T.reports.scheduled_report_sharing_settings | translate)
        : (T.reports.share_report_immediately | translate)
    "
    [showDelete]="false"
    (closed)="onClose()"
  ></app-modal-header>
  <app-modal-body>
    <div class="share-report-settings-modal__container" [formGroup]="form">
      <ng-container *ngIf="!isScheduled; else scheduledView">
        <div class="modal-body-container row col-row-space custom-gutters pt-0">
          <div class="col-12">
            <ng-container *ngTemplateOutlet="titleField"></ng-container>
          </div>

          <div class="col-12">
            <ng-container *ngTemplateOutlet="descriptionField"></ng-container>
          </div>

          <div class="col-12">
            <ng-container *ngTemplateOutlet="shareWithEmployees"></ng-container>
          </div>

          <div class="col-12">
            <ng-container *ngTemplateOutlet="shareWithExternalUsers"></ng-container>
          </div>

          <div class="col-12">
            <ng-container *ngTemplateOutlet="shareWithGroups"></ng-container>
          </div>

          <div class="col-12 mb-4">
            <ng-container *ngTemplateOutlet="attachmentType"></ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #scheduledView>
        <div class="modal-body-container row col-row-space custom-gutters pt-0">
          <div class="col-6">
            <div class="mb-4">
              <ng-container *ngTemplateOutlet="titleField"></ng-container>
            </div>

            <div class="mb-4">
              <ng-container *ngTemplateOutlet="descriptionField"></ng-container>
            </div>

            <div class="mb-3">
              <ng-container *ngTemplateOutlet="attachmentType"></ng-container>
            </div>
          </div>

          <div class="col-6">
            <div class="mb-4">
              <ng-container *ngTemplateOutlet="shareWithEmployees"></ng-container>
            </div>

            <div class="mb-4">
              <ng-container *ngTemplateOutlet="shareWithExternalUsers"></ng-container>
            </div>

            <div class="mb-4">
              <ng-container *ngTemplateOutlet="shareWithGroups"></ng-container>
            </div>
          </div>
        </div>

        <div class="modal-body-container row col-row-space custom-gutters pt-0" *ngIf="isScheduled">
          <div class="col-12">
            <app-hr></app-hr>
          </div>

          <div class="col-12" [innerHTML]="T.reports.scheduling_html_info | translate"></div>

          <div class="col-md-6 col-12">
            <div class="mb-4">
              <div class="d-flex">
                <app-radio-button
                  [name]="'regular-scheduling'"
                  [text]="T.reports.regular_scheduling | translate"
                  [checked]="scheduleTypeFC.value === reportScheduleTypes.Regular"
                  (checkedChanged)="onRegularScheduleChecked()"
                  class="me-3"
                ></app-radio-button>
                <app-radio-button
                  [name]="'custom-scheduling'"
                  [text]="T.reports.custom_scheduling | translate"
                  [checked]="scheduleTypeFC.value === reportScheduleTypes.Custom"
                  (checkedChanged)="onCustomScheduleChecked()"
                ></app-radio-button>
              </div>
            </div>

            <ng-container *ngIf="scheduleTypeFC.value === reportScheduleTypes.Regular">
              <div class="my-4">
                <app-search-dropdown-r
                  [objects]="reportSendIntervalTypes"
                  [bindProperty]="'label'"
                  [label]="T.reports.send_reports_how_often | translate"
                  [currentlySelectedObject]="currentSendIntervalTypeObject"
                  (objectSelected)="onReportSendIntervalTypeSelect($event)"
                  (objectCleared)="onReportSendIntervalTypeClear()"
                ></app-search-dropdown-r>
              </div>

              <div class="info-message-container" *ngIf="sendStartDateFC.invalid">
                <div class="info-message-title">
                  <i class="material-icons">error_outline</i>
                  <span>{{ T.reports.start_can_not_be_after_end_date | translate }}</span>
                </div>
              </div>

              <div class="d-flex my-4">
                <app-datepicker
                  [placeholder]="T.reports.send_from | translate"
                  [required]="true"
                  [date]="sendStartDateFC.value"
                  [minDate]="minStartDate"
                  (dateChanged)="onSendStartDateChanged($event)"
                  [errorMsg]="T.reports.date_is_required | translate"
                  class="me-4"
                >
                </app-datepicker>

                <app-datepicker
                  [placeholder]="T.reports.send_until | translate"
                  [required]="true"
                  [date]="sendEndDateFC.value"
                  [minDate]="minEndDate"
                  (dateChanged)="onSendEndDateChanged($event)"
                  [errorMsg]="T.reports.date_is_required | translate"
                >
                </app-datepicker>
              </div>

              <app-timepicker
                [placeholder]="T.reports.starting_at | translate"
                [date]="sendStartDateFC.value"
                (timeChanged)="onTimeChanged($event)"
              ></app-timepicker>
            </ng-container>

            <ng-container *ngIf="scheduleTypeFC.value === reportScheduleTypes.Custom">
              <div class="my-4">
                <app-datepicker
                  [placeholder]="T.reports.choose_date | translate"
                  [date]="customSendDateToAdd"
                  [minDate]="minStartDate"
                  (dateChanged)="onCustomSendDateChanged($event)"
                  [errorMsg]="T.reports.date_is_required | translate"
                >
                </app-datepicker>
              </div>

              <div class="my-4">
                <app-timepicker
                  [placeholder]="T.reports.send_at | translate"
                  [date]="customSendDateToAdd"
                  (timeChanged)="onCustomSendDateChanged($event)"
                ></app-timepicker>
              </div>

              <div class="mt-4 d-flex justify-content-end">
                <app-button-rectangle
                  [text]="T.reports.clear_selection | translate"
                  [buttonTheme]="'white'"
                  [disabled]="!customSendDateToAdd"
                  (clicked)="clearCustomDateInputs()"
                  class="mr-8"
                ></app-button-rectangle>
                <app-button-rectangle
                  [text]="T.common.add_dates | translate"
                  [disabled]="!customSendDateToAdd"
                  (clicked)="addCustomDateToSchedule()"
                ></app-button-rectangle>
              </div>
            </ng-container>
          </div>

          <div class="col-md-6 col-12">
            <app-well [height]="'240px'">
              <div well-header>
                <div class="share-report-settings-modal__info-label">
                  {{ T.reports.report_sharing_settings | translate }}
                </div>
                <div class="share-report-settings-modal__info-summary mb-2">{{ getSharingSettingsSummary() }}</div>

                <div *ngIf="scheduleTypeFC.value !== reportScheduleTypes.Custom; else customDatesTemplate">
                  <div class="share-report-settings-modal__info-label">{{ T.reports.first_report | translate }}</div>
                  <div class="share-report-settings-modal__info-summary mb-2">
                    {{
                      T.reports.time_on_date
                        | translate
                          : {
                              time: sendStartDateFC.value | localisedTime: true,
                              date: sendStartDateFC.value | localisedDate: false
                            }
                    }}
                  </div>

                  <div class="share-report-settings-modal__info-label">{{ T.reports.last_report | translate }}</div>
                  <div class="share-report-settings-modal__info-summary">
                    {{
                      T.reports.time_on_date
                        | translate
                          : { time: lastShareDate | localisedTime: true, date: lastShareDate | localisedDate: false }
                    }}
                  </div>
                </div>
                <ng-template #customDatesTemplate>
                  <div class="share-report-settings-modal__info-label">{{ T.reports.report_dates | translate }}</div>
                </ng-template>
              </div>

              <div *ngIf="scheduleTypeFC.value === reportScheduleTypes.Custom">
                <p *ngIf="!reportItemShareDatesFC.value.length; else customDatesList">
                  {{ T.reports.select_the_dates_and_time | translate }}
                </p>

                <ng-template #customDatesList>
                  <div
                    *ngFor="let date of reportItemShareDatesFC.value; let i = index"
                    class="d-flex align-items-center"
                  >
                    <span
                      ><span class="grey-dark">{{ date | localisedTime: true }}</span>
                      {{ T.reports.on_date | translate: { date: date | localisedDate: false } }}</span
                    >
                    <button class="ms-auto" (click)="removeCustomDate(i)"><i class="material-icons">close</i></button>
                  </div>
                </ng-template>
              </div>

              <div
                well-footer
                *ngIf="scheduleTypeFC.value === reportScheduleTypes.Custom && reportItemShareDatesFC.value.length"
                class="share-report-settings-modal__remove-all-dates"
              >
                <button class="share-report-settings-modal__remove-all-dates-button" (click)="removeAllCustomDates()">
                  {{ T.common.clear_all | translate }}
                </button>
              </div>
            </app-well>
          </div>
        </div>
      </ng-template>
    </div>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      class="mr-8"
      [text]="T.common.cancel | translate"
      [buttonTheme]="'white'"
      [size]="'large'"
      (clicked)="onClose()"
    ></app-button-rectangle>
    <app-button-rectangle
      [text]="isScheduled ? (T.common.save | translate) : (T.reports.share_now | translate)"
      [size]="'large'"
      [disabled]="form.invalid"
      (clicked)="onApply()"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>

<ng-template #loadingState>
  <app-loading></app-loading>
</ng-template>

<ng-template #titleField [formGroup]="form">
  <app-text-field
    [autofocus]="true"
    formControlName="title"
    [maxLength]="80"
    [minlength]="3"
    [placeholder]="T.defaultLocalizations.title.one | translate"
    [formGroup]="form"
  >
  </app-text-field>
</ng-template>

<ng-template #descriptionField [formGroup]="form">
  <app-text-area
    [placeholder]="T.defaultLocalizations.description.one | translate"
    formControlName="description"
    [maxLength]="250"
    [minlength]="3"
    [formGroup]="form"
    [rows]="isScheduled ? 6 : 3"
  ></app-text-area>
</ng-template>

<ng-template #shareWithEmployees>
  <app-filter-dropdown-new
    [filterByModuleObjectType]="false"
    [filterType]="filterTypes.Employee"
    [singleSelect]="false"
    [appliedFilters]="filtersFC.value"
    [disableMargin]="true"
    [typeahead]="true"
    [label]="T.reports.share_with | translate"
    (onFilterUpdate)="handleShareWithFilterChange($event)"
  >
  </app-filter-dropdown-new>
  <div class="bottom-element d-flex">
    <app-button-transparent
      [iconFontSize]="16"
      size="small"
      icon="add"
      [text]="T.reports.include_me | translate"
      (clicked)="inludeCurrentEmployeeInShared()"
    ></app-button-transparent>
  </div>
</ng-template>

<ng-template #shareWithExternalUsers>
  <app-filter-dropdown-new
    [filterByModuleObjectType]="false"
    [filterType]="filterTypes.External_User"
    [singleSelect]="false"
    [appliedFilters]="filtersFC.value"
    [disableMargin]="true"
    [typeahead]="true"
    [label]="T.reports.share_with_external_users | translate"
    (onFilterUpdate)="handleShareWithFilterChange($event)"
  >
  </app-filter-dropdown-new>
  <div class="bottom-element d-flex">
    <app-button-transparent
      [iconFontSize]="16"
      size="small"
      icon="add"
      [text]="T.reports.add_new_external_user | translate"
      (clicked)="addNewExternalUser()"
    ></app-button-transparent>
  </div>
</ng-template>

<ng-template #shareWithGroups>
  <app-filter-dropdown-new
    [filterByModuleObjectType]="false"
    [filterType]="filterTypes.User_Group"
    [singleSelect]="false"
    [appliedFilters]="filtersFC.value"
    [disableMargin]="true"
    [typeahead]="true"
    [label]="T.reports.share_with_groups | translate"
    (onFilterUpdate)="handleShareWithFilterChange($event)"
  >
  </app-filter-dropdown-new>
</ng-template>

<ng-template #attachmentType>
  <div class="d-flex">
    <span class="me-3">{{ T.reports.attach_report_to_email | translate }}</span>
    <app-radio-button
      [name]="'PDF'"
      [text]="'PDF'"
      [checked]="attachmentTypeFC.value === reportAttachTypes.PDF"
      (checkedChanged)="onPDFChecked()"
      class="me-3"
    ></app-radio-button>
    <app-radio-button
      *ngIf="isSingleSectionReport"
      [name]="'XLSX'"
      [text]="'XLSX'"
      [checked]="attachmentTypeFC.value === reportAttachTypes.CSV"
      (checkedChanged)="onXLSXChecked()"
    ></app-radio-button>
  </div>
</ng-template>
