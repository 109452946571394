<div #wrapper class="wrapper">
  <div class="input-wrapper">
    <input #input type="text" (input)="onInput()" (click)="onClick()" />
    <div class="search-icon-wrapper">
      <div class="responsive-flex-wrapper">
        <i class="material-icons">search</i>
      </div>
    </div>
    <div class="label-wrapper" *ngIf="!input.value && displayLabel" (click)="onLabelClick($event)">
      <div class="responsive-flex-wrapper">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
  <div #dropdown *ngIf="foundItems.length" class="dropdown-content">
    <p *ngIf="!input.value && !foundItems.length">Type to search...</p>
    <p *ngFor="let item of foundItems" (click)="onItemSelected(item)" [innerHTML]="item[bindProperty]"></p>
  </div>
</div>
