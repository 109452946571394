<app-modal>
  <!-- Header -->
  <app-modal-header
    [icon]="'event'"
    [iconColor]="'var(--wt-primary)'"
    [title]="modalTitle"
    (closed)="onClose()"
  ></app-modal-header>

  <!-- Body -->
  <app-modal-body>
    <div class="alert-warning intro-text mb-3">
      <div class="m-r-5"><i class="material-icons">info</i></div>
      <div class="m-l-5">
        <p
          [innerHTML]="
            T.control.the_below_localisedEvents
              | translate: { localisedEvents: localisedEvents, localisedEvent: localisedEvent }
          "
        ></p>
        <p>
          {{ T.control.this_will_give_you_focused_view | translate: { localisedEvent: localisedEvent } }}
        </p>
        <p class="note">
          {{ T.control.note_if_you_not_working_at_live_localisedEvent | translate: { localisedEvent: localisedEvent } }}
        </p>
      </div>
    </div>

    <div
      *ngFor="let event of events"
      class="template-list-wrapper"
      [ngClass]="{ 'is-selected': isSelected(event) }"
      (click)="setSelected(event)"
    >
      <span class="title">{{ event.title }}</span
      ><span *ngIf="isSelected(event)" class="material-icons">done</span>
      <div class="data">
        <span class="live-lozenge-small">{{ T.common.live | translate }}</span
        >&nbsp;<span class="date">{{ event.startDate + ' - ' + event.endDate }}</span>
      </div>
    </div>
  </app-modal-body>

  <!-- Footer -->
  <div class="footer">
    <app-button-rectangle
      [buttonTheme]="'white'"
      [text]="T.control.no_live_localisedEvent | translate: { localisedEvent: localisedEvent }"
      (clicked)="onNoFixedEventConfirmed()"
    ></app-button-rectangle>
    <app-button-rectangle
      [buttonTheme]="'primary'"
      [text]="T.common.confirm | translate"
      [disabled]="!selectedEvent"
      (clicked)="onConfirm()"
    ></app-button-rectangle>
  </div>
</app-modal>
