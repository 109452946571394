import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IndicatorTemplateService } from 'src/app/modules/accountHub/services/sustainability/indicator-template.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { IndicatorService } from '../../../services/indicator.service';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Subject, Subscription, debounceTime, forkJoin } from 'rxjs';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { IndicatorViewModel } from '../../../viewModels/indicatorViewModel';
import { IndicatorUpdateViewModel } from '../../../viewModels/indicatorUpdateViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { IndicatorTrackingDateTypes } from '../../../enums/indicatorTrackingDateTypes';
import { IndicatorUpdateTypes } from '../../../enums/indicatorUpdateTypes';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { IndicatorPriorities } from '../../../enums/indicatorPriorities';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import * as moment from 'moment';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { FilterItemTypes } from 'src/app/modules/shared/enums/filter/filterItemTypes.enum';
import { ClimatiqService } from '../../../services/climatiq.service';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AccountSettingTypes } from 'src/app/modules/shared/enums/accountSettingTypes';
import { ClimatiqRegionViewModel } from '../../../viewModels/climatiq/climatiqRegionViewModel';
import { ClimatiqSourceViewModel } from '../../../viewModels/climatiq/climatiqSourceViewModel';
import { ClimatiqEmissionFactorSearchViewModel } from '../../../viewModels/climatiq/climatiqEmissionFactorSearchViewModel';
import { ClimatiqIndicatorEmissionsDataSelectorViewModel } from '../../../viewModels/climatiq/climatiqIndicatorEmissionsDataSelectorViewModel';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { IndicatorUpdateExpectedDirections } from '../../../enums/indicatorUpdateExpectedDirections';
import { ClimatiqUnitTypeViewModel } from '../../../viewModels/climatiq/climatiqUnitTypeViewModel';
import { ClimatiqSectorViewModel } from '../../../viewModels/climatiq/climatiqSectorViewModel';

@Component({
  selector: 'app-indicator-add-modal-new',
  templateUrl: './indicator-add-modal-new.component.html',
  styleUrl: './indicator-add-modal-new.component.scss'
})
export class IndicatorAddModalNewComponent implements OnInit, OnDestroy {
  @Output() submit = new EventEmitter<boolean>();

  private subscriptions: Subscription = new Subscription();

  public readonly T = T;
  public currentAccount: Account;
  public currentEmployee: Employee;

  public localisedDepartment = 'Department';
  public localisedDepartments = 'Departments';
  public localisedDepartmentCategories = 'Department Categories';
  public unitTypesFilters: FilterViewModel[] = [];
  public allowedUnitFilters: FilterViewModel[] = [];
  public unitFilters: FilterViewModel[] = [];
  public isConversionDisabled = false;

  public currentSelectedPriorityFilter: FilterViewModel;

  public footerButtonText: string[] = [
    this.translateService.instant(T.common.continue),
    this.translateService.instant(T.common.continue),
    this.translateService.instant(T.common.continue),
    this.translateService.instant(T.common.continue),
    this.translateService.instant('Create Indicator')
  ];

  public beads: ThreadedBead[] = [
    { number: 0, active: false },
    { number: 1, active: false },
    { number: 2, active: false },
    { number: 3, active: false },
    { number: 4, active: false }
  ];

  public loading: boolean = false;
  public currentStep: number = 0;
  public indicatorTrackingPeriodFilter: FilterViewModel[] = [];
  public indicator: IndicatorViewModel = new IndicatorViewModel();
  public indicatorUpdates: IndicatorUpdateViewModel[] = [];
  public indicatorForm: UntypedFormGroup;
  public indicatorTemplateForm: UntypedFormGroup;
  public unitOfMeasurementFilters: FilterViewModel[];
  public unitOfMeasurementSelectedFilter: FilterViewModel;
  public expectedDirectionFilters: FilterViewModel[];
  public expectedDirectionSelectedFilter: FilterViewModel;
  public unitTypeSelectedFilter: FilterViewModel;
  public unitSelectedFilter: FilterViewModel;
  public convertionSelectedUnitFilter: FilterViewModel;

  public filterTypes = FilterTypes;
  public objectTypes = ObjectTypes;
  public indicatorTrackingDateTypes = IndicatorTrackingDateTypes;
  public indicatorUpdateTypes = IndicatorUpdateTypes;
  public editableFieldTypes = EditableFieldTypes;
  public triggerIndicatorErrors: boolean = false;
  public priorityTitles: string[] = [];
  public validationErrors: string[];

  //Emissions Factors CLIMATIQ
  private climatiqEmisionFactorSearchModel: ClimatiqEmissionFactorSearchViewModel = null;
  private previousSearchKeywordLength = 0;

  public indicatorCreated = false;
  public climatiqDataIsLoading = false;
  public emissionsFactorIsLoading = false;

  public trackCarbonEmissions = true;
  public climatiqSectors: ClimatiqSectorViewModel[] = [];
  public climatiqUnitTypes: ClimatiqUnitTypeViewModel[] = [];
  public climatiqRegions: ClimatiqRegionViewModel[] = [];
  public climatiqSources: ClimatiqSourceViewModel[] = [];

  public matchedClimatiqSector: ClimatiqSectorViewModel = null;
  public matchedClimatiqUnitType: ClimatiqUnitTypeViewModel = null;
  public selectedClimatiqRegion: ClimatiqRegionViewModel = null;
  public selectedClimatiqSource: ClimatiqSourceViewModel = null;

  public climatiqIndicatorEmissionsData: ClimatiqIndicatorEmissionsDataSelectorViewModel[] = [];
  public filteredClimaticIndicatorEmissionsData: ClimatiqIndicatorEmissionsDataSelectorViewModel[] = [];

  public emissionFactorSearchSubject = new Subject<string>();
  public indicatorCategory: string;

  constructor(
    private readonly indicatorService: IndicatorService,
    private readonly indicatorTemplateService: IndicatorTemplateService,
    private readonly authenticationService: AuthenticationService,
    private readonly cachingService: CachingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly localisationService: LocalisationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly bsModalService: BsModalService,
    public readonly bsModalRef: BsModalRef,
    public readonly checklistBsModalRef: BsModalRef,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators,
    private readonly climatiqService: ClimatiqService,
    private readonly accountSettingsService: AccountSettingsService
  ) {}

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    this.localisedDepartments = this.localisationService.localiseObjectType(ObjectTypes.Department, true);
    this.localisedDepartmentCategories = this.localisationService.localiseObjectType(ObjectTypes.Department_Category, true);

    this.initIndicatorForm();
    this.initPriorityFilterTitles();
    this.initIndicatorFilters();
    this.initIndicatorTemplateFilters();
    this.initSubscriptions();

    this.updateBeads()

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get stepDisabled(): boolean {
    let disabledFlag = false;
    if (this.currentStep === 0) {
      disabledFlag = !(
        this.indicatorForm.controls.title.valid &&
        this.indicator.indicatorCategoryId > 0 &&
        this.indicatorForm.controls.priority.valid);
    }

    if (this.currentStep === 2) {
      disabledFlag =
        !this.indicator.updateType ||
        (this.indicator.updateType === this.indicatorUpdateTypes.Numeric && !this.indicator.conversionUnitId);
    }

    if (this.currentStep === 4) {
      disabledFlag = this.trackCarbonEmissions && !this.indicator.emissionsData;
    }

    return disabledFlag;
  }

  get getStartDate() {
    if (this.indicator.startDate) {
      return this.indicator.startDate;
    }
    return '';
  }

  get getDueDate() {
    if (this.indicator.dueDate) {
      return this.indicator.dueDate;
    }
    return '';
  }

  public increaseStep(): void {
    if(this.currentStep === 4) {
      this.onSave();
      return;
    }

    this.currentStep++;
    this.updateBeads();
    switch (this.currentStep) {
      case 2:
        this.updateIndicatorUpdateType(IndicatorUpdateTypes.Numeric);
        break;
      case 4:
        this.getClimatiqData();
        break;
      default: break;
    }
  }

  public decreaseStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.updateBeads();
    }
  }

  public updateIndicatorCategory(filters: FilterViewModel[]): void {
    this.indicator.filters = filters;
    const indicatorCategoryFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Indicator_Category);
    this.indicator.indicatorCategoryId = (indicatorCategoryFilter !== undefined)
      ? +indicatorCategoryFilter.filterValue
      : 0;

    this.matchedClimatiqSector = null;
    this.trackCarbonEmissions = false;
    this.indicator.trackCo2Emissions = false;
    this.indicatorCategory = indicatorCategoryFilter.filterText;
    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorScope(filters: FilterViewModel[]): void {
    this.indicator.filters = filters;
    const indicatorScopeFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Sustainability_Scope);
    this.indicator.sustainabilityScope = (indicatorScopeFilter !== undefined)
      ? +indicatorScopeFilter.filterValue
      : 0;
    this.changeDetectorRef.detectChanges();
  }

  public updateGHGReportingCategory(filters: FilterViewModel[]) {
    this.indicator.filters = filters;
    const indicatorGHGFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.GHG_Reporting_Category);
    this.indicator.GHGReportingCategory = (indicatorGHGFilter !== undefined)
      ? +indicatorGHGFilter.filterValue
      : 0;
    this.changeDetectorRef.detectChanges();
  }

  public updatePillar(filters: FilterViewModel[]) {
    this.indicator.filters = filters;
    const indicatorPillarFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Pillar);
    this.indicator.pillar = (indicatorPillarFilter !== undefined)
      ? +indicatorPillarFilter.filterValue
      : 0;
    this.changeDetectorRef.detectChanges();
  }

  public updateReportingDirectives(filters: FilterViewModel[]) {
    this.indicator.filters = filters;
    const indicatorReportingDirectiveFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Reporting_Directive);
    this.indicator.reportingDirectives = (indicatorReportingDirectiveFilter !== undefined)
      ? +indicatorReportingDirectiveFilter.filterValue
      : 0;
    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUpdateType(updateType: IndicatorUpdateTypes) {
    if(this.indicator.updateType !== updateType) {
      this.indicator.updateType = updateType;

      const indicatorTypeFilter = FilterUtilities.GenerateFilter(FilterTypes.Indicator_Update_Type, updateType, updateType.toString());
      this.indicator.filters = this.indicator.filters.filter((f) => f.filterType !== FilterTypes.Indicator_Update_Type);
      this.indicator.filters.push(indicatorTypeFilter);

      if (this.indicator.updateType === IndicatorUpdateTypes.Pass_Or_Fail) {
        this.indicator.filters = this.indicator.filters.filter((f) => f.filterType !== FilterTypes.Unit_Type && f.filterType !== FilterTypes.Unit);
        this.indicator.defaultUnitId = undefined;
        this.indicator.conversionUnitId = undefined;
        this.indicator.unitTypeId = undefined;
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  public updatedExpectedDirection(filters: FilterViewModel[]) {
    const expectedDirectionFilter: FilterViewModel = filters.find((f) => f.filterType == FilterTypes.Expected_Direction);
    this.expectedDirectionSelectedFilter = expectedDirectionFilter;

    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUnitType(filters: FilterViewModel[]) {
    if (filters.length > 0) {
      const indicatorUnitTypeFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit_Type);
      this.unitTypeSelectedFilter = indicatorUnitTypeFilter;

      const isDisabled = this.unitTypeSelectedFilter.adittionalItems.find(
        (d) => +d.key === FilterItemTypes.sus_conversionDropdownVisibilityType
      );
      this.isConversionDisabled = isDisabled === undefined ? false : isDisabled.value === '1';

      this.unitFilters = this.allowedUnitFilters.filter((f) => f.relatedObjectId === indicatorUnitTypeFilter.filterValue);
      const defaultUnit = this.unitFilters.find(
        (f) => f.adittionalItems.findIndex((s) => +s.key === FilterItemTypes.sus_unit_default_value && s.value === 'True') > -1
      );

      if (defaultUnit) {
        this.unitSelectedFilter = defaultUnit;
        this.convertionSelectedUnitFilter = defaultUnit;
      } else {
        this.unitSelectedFilter = this.unitFilters.length > 0 ? this.unitFilters[0] : undefined;
        this.convertionSelectedUnitFilter = this.unitFilters.length > 0 ? this.unitFilters[0] : undefined;
      }

      this.indicator.filters = this.indicator.filters.filter(
        (f) => f.filterType !== FilterTypes.Unit_Type && f.filterType !== FilterTypes.Unit
      );
      this.indicator.filters.push(indicatorUnitTypeFilter);

      this.indicator.unitTypeId = +indicatorUnitTypeFilter.filterValue;
      this.indicator.defaultUnitId = +this.unitSelectedFilter.filterValue;
      this.indicator.conversionUnitId = +this.convertionSelectedUnitFilter.filterValue;
    }
    else {
      this.unitTypeSelectedFilter = undefined;
      this.unitSelectedFilter = undefined;
      this.convertionSelectedUnitFilter = undefined;

      this.indicator.unitTypeId = undefined;
      this.indicator.defaultUnitId = undefined;
      this.indicator.conversionUnitId = undefined;

      this.setUnitAndUnitTypes();
    }

    this.matchedClimatiqUnitType = null;
    this.trackCarbonEmissions = false;
    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUnit(filters: FilterViewModel[]) {
    const unitFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit);
    this.unitSelectedFilter = unitFilter;
    this.indicator.defaultUnitId = unitFilter ? +unitFilter.filterValue : null;
    this.changeDetectorRef.detectChanges();
  }

  public updateConversionIndicatorUnit(filters: FilterViewModel[]) {
    const unitFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit);
    this.convertionSelectedUnitFilter = unitFilter;
    this.indicator.conversionUnitId = unitFilter ? +unitFilter.filterValue : null;
    this.changeDetectorRef.detectChanges();
  }

  public updateViewModel(filters: FilterViewModel[]): void {
    this.indicator.filters = filters;

    this.changeDetectorRef.detectChanges();
  }

  public onDescriptionChange($event: string): void {
    this.indicatorForm.controls.description.setValue($event);
  }

  public onApply($event): void {
    this.currentSelectedPriorityFilter = $event;
    this.indicatorForm.patchValue({
      priority: this.currentSelectedPriorityFilter.filterValue
    });
  }

  public cancel(): void {
    this.bsModalRef.hide();
  }

  public addCurrentUserDepartment() {
    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Department);

    if (filters) {
      const departmentFilter = filters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.currentEmployee.departmentId.toString()
      );
      if (
        departmentFilter &&
        !this.indicator.filters.some(
          (f) => f.filterType === FilterTypes.Department && f.filterValue === this.currentEmployee.departmentId
        )
      ) {
        this.indicator.filters = this.indicator.filters.slice();
        this.indicator.filters.push(departmentFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public addCurrentUserAsOwner(): void {
    const predicate = (f: FilterViewModel) => f.filterType === FilterTypes.Owner && f.filterValue === this.currentEmployee.id;

    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);
    if (filters) {
      const empFilter = filters.find(f => predicate(f));
      if (empFilter && !this.indicator.filters.some(f => predicate(f))) {
        this.indicator.filters = this.indicator.filters.slice();
        this.indicator.filters.push(empFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  updateStartDate(dateAsIsoString: string) {
    this.setStartDate(dateAsIsoString);
    this.loadIndicatorUpdates();
  }

  updateDueDate(dateAsIsoString: string) {
    this.setDueDate(dateAsIsoString);
    this.loadIndicatorUpdates();
  }

  public updateTrackingDateType(filters: FilterViewModel[]): void {
    this.indicator.filters = filters;
    const indicatorTrackingDateTypeFilter: FilterViewModel = filters.find(
      (f) => f.filterType === FilterTypes.Indicator_Tracking_Date_Type
    );
    this.indicator.trackingDateType =
      indicatorTrackingDateTypeFilter !== undefined ? +indicatorTrackingDateTypeFilter.filterValue : 0;

    this.changeDetectorRef.detectChanges();
    this.loadIndicatorUpdates();
  }

  public onCarbonEmissionsTrackingChanged($event: boolean) {
    this.indicator.trackCo2Emissions = $event;
    this.trackCarbonEmissions = $event;
  }

  public onClimatiqRegionChanged(region: ClimatiqRegionViewModel) {
    this.selectedClimatiqRegion = region;
    this.getClimatiqEmissionFactorsAndPopulateList();
  }

  public onClimatiqSourceChanged(source: ClimatiqSourceViewModel) {
    this.selectedClimatiqSource = source;
    this.getClimatiqEmissionFactorsAndPopulateList();
  }

  public onEmissionFactorSelected(emissionFactor: ClimatiqIndicatorEmissionsDataSelectorViewModel) {
    this.indicator.emissionsData = emissionFactor;
    this.changeDetectorRef.detectChanges();
  }

  public onSearchEmissionsFactors($event: string) {
    if($event.length > 2) {
      this.filteredClimaticIndicatorEmissionsData = this.climatiqIndicatorEmissionsData
          .filter(e => e.name.toLowerCase().includes($event.toLowerCase()))
          .slice();
    } else {
      const clearSearchFlag = $event.length < this.previousSearchKeywordLength &&
        this.filteredClimaticIndicatorEmissionsData.length < this.climatiqIndicatorEmissionsData.length;

      if(clearSearchFlag) {
        this.filteredClimaticIndicatorEmissionsData = this.climatiqIndicatorEmissionsData.slice();
      }
    }

    this.previousSearchKeywordLength = $event.length;
  }

  onNavigateToIndicatorDetailsPageClicked() {
    void this.router.navigate(['v2/sustainability/indicators/' + String(this.indicator.id)]);
    this.cancel();
  }

  onAddAnotherIndicatorClicked(): void {
    this.indicator = new IndicatorViewModel();

    this.indicatorCreated = false;
    this.indicatorTrackingPeriodFilter = [];
    this.climatiqSectors = [];
    this.climatiqRegions = [];
    this.climatiqUnitTypes = [];
    this.climatiqSources = [];
    this.matchedClimatiqSector = null;
    this.matchedClimatiqUnitType = null;
    this.selectedClimatiqRegion = null;
    this.selectedClimatiqSource = null;
    this.climatiqIndicatorEmissionsData = [];
    this.filteredClimaticIndicatorEmissionsData = [];
    this.climatiqEmisionFactorSearchModel = null;
    this.previousSearchKeywordLength = 0;

    this.initIndicatorForm();
    this.initIndicatorFilters();

    this.currentStep = 0;
    this.updateBeads();

    this.changeDetectorRef.detectChanges();
  }

  public onSave() {
    this.loading = true;
    if (this.indicator.updateType === IndicatorUpdateTypes.Numeric) {
      const filterValue = this.expectedDirectionSelectedFilter.filterValue as IndicatorUpdateExpectedDirections;
      this.indicator.expectedDirection = filterValue;

      this.indicator.filters.push(
        FilterUtilities.GenerateFilter(
          FilterTypes.Expected_Direction,
          this.expectedDirectionSelectedFilter.filterValue,
          this.expectedDirectionSelectedFilter.filterText
        )
      );
    }

    const item = { ...this.indicator, ...this.indicatorForm.value } as IndicatorViewModel;
    item.active = true;

    //Very important to set the trackCo2Emissions flag here, as the user may have not changed the default radio button.
    item.trackCo2Emissions = this.trackCarbonEmissions;

    if(!item.defaultUnitId) {
      item.defaultUnitId = item.conversionUnitId;
    }

    this.subscriptions.add(
      this.indicatorService.add(item).subscribe((res) => {
        const errors = res.errorList;
        const newIndicator = res.returnModel as IndicatorViewModel;
        if (errors?.length > 0) {
          this.validationErrors = errors;
          this.loading = false;
          void this.alertService.error(errors[0]);
          this.decreaseStep();
        } else {
          this.allowedFiltersService.refreshFilters(
            OperationTypes.Create,
            [{ id: newIndicator.id, title: newIndicator.title, relatedObjectId: 0 }],
            FilterTypes.Indicator
          );

          this.loading = false;
          this.indicator = res.returnModel as IndicatorViewModel;
          this.indicatorCreated = true;

          this.submit.next(true);
          void this.alertService.success(
            this.translateService.instant(T.common.item_added, {
              item: this.translateService.instant(T.defaultLocalizations.indicator.one)
            })
          );

          this.changeDetectorRef.detectChanges();
        }
      })
    );
  }

  public getFilterTextByFilterType(filterType: FilterTypes): string {
    const filter = this.indicator.filters.find((f) => f.filterType === filterType);
    return filter ? filter.filterText : '';
  }

  private updateBeads(): void {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i <= this.currentStep; i++) {
      this.beads[i].active = true;
    }
  }

  private initIndicatorForm(): void {
    this.indicatorForm = this.fb.group({
      title: ['', { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])] }],
      description: [''],
      priority: [0, [Validators.required]],
      trackingDateValue: ['1', { validators: [Validators.required, this.wtValidators.isNumeric()] }]
    });
  }

  private initPriorityFilterTitles(): void {
    const one_star = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.One_Star,
      FilterTypes.Indicator_Priority
    );
    const two_stars = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.Two_Stars,
      FilterTypes.Indicator_Priority
    );
    const three_stars = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.Three_Stars,
      FilterTypes.Indicator_Priority
    );

    this.priorityTitles = [one_star, two_stars, three_stars];
  }

  private initIndicatorTemplateFilters() {
    this.indicatorTrackingPeriodFilter = [];
    this.indicatorTrackingPeriodFilter.push(
      FilterUtilities.GenerateFilter(FilterTypes.Indicator_Tracking_Date_Type, IndicatorTrackingDateTypes.Years, 'Years')
    );
  }

  private initIndicatorFilters() {
    this.indicator.filters = [];
    this.indicator.filters.push(
      FilterUtilities.GenerateFilter(FilterTypes.Indicator_Tracking_Date_Type, IndicatorTrackingDateTypes.Months, 'Months')
    );
    this.indicator.trackingDateType = IndicatorTrackingDateTypes.Months;
    this.indicator.trackingDateValue = 1;
    const startDate = moment().toISOString();
    const dueDate = moment().add(5, 'years').toISOString();

    this.setStartDate(startDate);
    this.setDueDate(dueDate);
    this.setExpectedDirectionFilters();
    this.setUnitAndUnitTypes();
  }

  private setStartDate(dateAsIsoString: string): void {
    this.indicator.startDate = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);
  }

  private setDueDate(dateAsIsoString: string): void {
    this.indicator.dueDate = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Due_Date, dateAsIsoString);

    if (!this.indicator.startDate) {
      this.indicator.startDate = this.indicator.dueDate;
      this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);
    }
  }

  private setExpectedDirectionFilters(): void {
    this.expectedDirectionFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Expected_Direction);
    this.expectedDirectionSelectedFilter = this.expectedDirectionFilters[0];
  }

  private setUnitAndUnitTypes(): void {
    this.allowedUnitFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Unit);
    this.unitTypesFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Unit_Type);
  }

  private populateDateInFilter(filterOption: FilterDateOptions, value: any): void {
    const due = this.indicator.filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === filterOption);
    if (!due) {
      this.indicator.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, value, value, filterOption));
    } else {
      due.filterValue = value;
    }

    this.changeDetectorRef.detectChanges();
  }

  private loadIndicatorUpdates(): void {
    this.subscriptions.add(
      this.indicatorService.getAvailableIndicatorUpdates(this.indicator).subscribe((i) => {
        this.indicatorUpdates = i;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private updateTrackingDateValue(value: string): void {
    if (!isNaN(+value)) {
      this.indicator.trackingDateValue = +value;
    }

    this.loadIndicatorUpdates();
  }

  private updateIndicatorTitle(value: string): void {
    this.indicator.title = value;
  }

  private initSubscriptions(): void {
    this.loadIndicatorUpdates();

    const formControls = this.indicatorForm.controls;
    this.subscriptions.add(
      formControls.trackingDateValue.valueChanges.subscribe((value) => this.updateTrackingDateValue(value))
    );
    this.subscriptions.add(
      formControls.title.valueChanges.subscribe((value) => this.updateIndicatorTitle(value))
    );

    this.subscriptions.add(
      this.emissionFactorSearchSubject.pipe(debounceTime(500)).subscribe((search) => {
        this.onSearchEmissionsFactors(search);
      })
    );
  }

  private getClimatiqData() {
    this.climatiqDataIsLoading = true;
    forkJoin([
      this.climatiqService.getClimatiqRegions(),
      this.climatiqService.getClimatiqUnitTypes(),
      this.climatiqService.getClimatiqSectors(),
      this.climatiqService.getClimatiqSources(),
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Indicator_Default_Climatiq_Region),
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Indicator_Default_Climatiq_Source)
    ]).subscribe( ([regions, unitTypes, sectors, sources, defaultRegion, defaultSource]) => {
      this.climatiqSectors = sectors.slice();
      this.climatiqUnitTypes = unitTypes.slice();
      this.climatiqRegions = regions.slice();
      this.climatiqSources = sources.slice();

      if(defaultRegion) {
        this.selectedClimatiqRegion = this.climatiqRegions.find((r) => r.id === +defaultRegion.value);
      }

      if(defaultSource) {
        this.selectedClimatiqSource = this.climatiqSources.find((s) => s.id === +defaultSource.value);
      }

      if(this.indicator.indicatorCategoryId > 0) {
        this.matchedClimatiqSector = this.climatiqSectors.find(
          (c) => c.indicatorCategoryId === this.indicator.indicatorCategoryId
        );
      }

      if(this.indicator.unitTypeId > 0) {
        this.matchedClimatiqUnitType = this.climatiqUnitTypes.find(
          (u) => u.unitTypeId === this.indicator.unitTypeId
        );
      }

      this.trackCarbonEmissions = !!this.matchedClimatiqSector && !!this.matchedClimatiqUnitType;
      if(this.trackCarbonEmissions) {
        this.getClimatiqEmissionFactorsAndPopulateList();
      }

      this.climatiqDataIsLoading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  private getClimatiqEmissionFactorsAndPopulateList() {
    this.emissionsFactorIsLoading = true;

    this.climatiqEmisionFactorSearchModel = {
      region: this.selectedClimatiqRegion?.code,
      sector: this.matchedClimatiqSector?.title,
      unit_type: this.matchedClimatiqUnitType?.title,
      source: this.selectedClimatiqSource?.title
    };

    const flag =
      this.climatiqEmisionFactorSearchModel.region &&
      this.climatiqEmisionFactorSearchModel.sector &&
      this.climatiqEmisionFactorSearchModel.unit_type &&
      this.climatiqEmisionFactorSearchModel.source;

    if(flag) {
      this.subscriptions.add(
        this.climatiqService.getEmissionFactorSearchResults(this.climatiqEmisionFactorSearchModel).subscribe((data) => {
          this.climatiqIndicatorEmissionsData = data;
          this.filteredClimaticIndicatorEmissionsData = data.slice();
          this.emissionsFactorIsLoading = false;
          this.indicator.emissionsData = null;
          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }
}
