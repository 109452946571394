export function generateRandomNumber(length: number) {
  let text = '';

  for (let i = 0; i < length; i++) {
    const typedArray = new Uint8Array(1);
    const randomValueAsString = crypto.getRandomValues(typedArray)[0].toString();
  	const randomNumber = +randomValueAsString.slice(0, 1);

    text += i > 0 && randomNumber === i ? '0' : randomNumber.toString();
  }

  return Number(text);
}
