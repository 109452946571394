<button class="dropdown-btn" [disabled]="disabled" [class.fit-to-container]="fitToContainer">
  <div
    class="dropdown-btn-container"
    [ngClass]="getButtonClasses()"
    ngDropdown
    [templateRef]="dropdownTemplateBtn"
    [adaptToElementWidth]="true"
    [closeUponSelection]="true"
    (dropdownToggled)="isDropdownOpen = $event"
  >
    <div class="button-text" (click)="emitSelection($event)" *ngIf="!loading">{{ selectedOption }}</div>
    <app-button-loader [ngClass]="getButtonClasses()" *ngIf="loading"></app-button-loader>
    <div class="icon-container" (click)="toggleDropdown()">
      <i class="material-icons">arrow_drop_down</i>
    </div>
  </div>
  <ng-template #dropdownTemplateBtn>
    <div class="button-dropdown-options">
      <div
        class="single-dropdown-option"
        *ngFor="let option of options"
        [class.selected-option]="option === selectedOption"
        (click)="changeSelectedOption(option)"
      >
        <span class="dropdown-option-text">{{ option }}</span>
        <span class="material-icons dropdown-check" *ngIf="option === selectedOption">check</span>
      </div>
    </div>
  </ng-template>
</button>
