import { Subject } from 'rxjs';
import { ObjectEventModel } from '../models/objectEventModel';
import { ObjectTypes } from '../enums/objectTypes';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AccountSettingTypes } from '../enums/accountSettingTypes';
import { AccountSettingViewModel } from '../viewModels/accountSettingViewModel';
import { HistoryEntryViewModel } from '../viewModels/history-feed/historyEntryViewModel';

export class ObjectEventEmitters {
  private readonly objectAdded: Subject<ObjectEventModel> = new Subject();
  private readonly objectUpdated: Subject<ObjectEventModel> = new Subject();
  private readonly objectDeleted: Subject<ObjectEventModel> = new Subject();
  private readonly objectArchived: Subject<ObjectEventModel> = new Subject();
  private readonly objectDuplicated: Subject<ObjectEventModel> = new Subject();
  private readonly headerFiltersExternalAdded: Subject<FilterViewModel[]> = new Subject();
  private readonly headerFiltersExternalCleared: Subject<FilterViewModel[]> = new Subject();
  private readonly headerFiltersExternalChanged: Subject<FilterViewModel[]> = new Subject();
  private readonly objectPinned: Subject<ObjectEventModel> = new Subject();
  private readonly objectUnpinned: Subject<ObjectEventModel> = new Subject();
  private readonly objectSubscribedTo: Subject<ObjectEventModel> = new Subject();
  private readonly objectUnbscribedFrom: Subject<ObjectEventModel> = new Subject();
  private readonly ragBreakdownUpdated: Subject<ObjectEventModel> = new Subject();
  private readonly refreshChecklists: Subject<void> = new Subject();
  //lists
  private readonly listAdded: Subject<ObjectEventModel> = new Subject();
  private readonly listUpdated: Subject<ObjectEventModel> = new Subject();
  private readonly listDeleted: Subject<ObjectEventModel> = new Subject();
  private readonly listLightUpdated: Subject<ObjectEventModel> = new Subject();

  private readonly accountSettingUpdated: Subject<{ settingType: AccountSettingTypes; setting: AccountSettingViewModel }> =
    new Subject();

  private readonly listFilterAdded: Subject<FilterViewModel[]> = new Subject();
  private readonly listFilterCleared: Subject<FilterViewModel[]> = new Subject();

  private readonly historyAdded: Subject<{objectId: number, objectType: ObjectTypes, history: HistoryEntryViewModel}> = new Subject();

  public headerFiltersExternalAdded$ = this.headerFiltersExternalAdded.asObservable();
  public headerFiltersExternalCleared$ = this.headerFiltersExternalCleared.asObservable();

  //The current set of filters is replaced with another set.
  public headerFiltersExternalChanged$ = this.headerFiltersExternalChanged.asObservable();

  public objectAdded$ = this.objectAdded.asObservable();
  public objectUpdated$ = this.objectUpdated.asObservable();
  public objectDeleted$ = this.objectDeleted.asObservable();
  public objectArchived$ = this.objectArchived.asObservable();
  public objectDuplicated$ = this.objectDuplicated.asObservable();
  public objectPinned$ = this.objectPinned.asObservable();
  public objectUnpinned$ = this.objectUnpinned.asObservable();
  public objectSubscribedTo$ = this.objectSubscribedTo.asObservable();
  public objectUnbscribedFrom$ = this.objectUnbscribedFrom.asObservable();
  public ragBreakdownUpdated$ = this.ragBreakdownUpdated.asObservable();
  public refreshChecklists$ = this.refreshChecklists.asObservable();
  //lists
  public listUpdated$ = this.listUpdated.asObservable();
  public listDeleted$ = this.listDeleted.asObservable();
  public listAdded$ = this.listAdded.asObservable();
  public listLightUpdated$ = this.listLightUpdated.asObservable();

  public accountSettingUpdated$ = this.accountSettingUpdated.asObservable();

  public listFilterAdded$ = this.listFilterAdded.asObservable();
  public listFilterCleared$ = this.listFilterCleared.asObservable();

  public historyAdded$ = this.historyAdded.asObservable();

  public listFilters: FilterViewModel[] = [];
  /**
   * add  applied filter to global Filters from external components
   * @param filters all new added filters
   */
  public broadcastHeaderFiltersExternalAdded(filters: FilterViewModel[]): void {
    this.headerFiltersExternalAdded.next(filters.slice());
  }

  public broadcastHeaderFiltersExternalCleared(): void {
    this.headerFiltersExternalCleared.next([]);
  }

  public broadcastHeaderFiltersExternalChanged(filters: FilterViewModel[]): void {
    this.headerFiltersExternalChanged.next(filters);
  }

  public broadcastObjectAdded(id: number, objectType: ObjectTypes, model: any): void {
    this.objectAdded.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectUpdated(id: number, objectType: ObjectTypes, model: any): void {
    this.objectUpdated.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectDeleted(id: number, objectType: ObjectTypes, model: any): void {
    this.objectDeleted.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectArchived(id: number, objectType: ObjectTypes, model: any): void {
    this.objectArchived.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectDuplicated(id: number, objectType: ObjectTypes, model: any): void {
    this.objectDuplicated.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectPinned(id: number, objectType: ObjectTypes, model: any): void {
    this.objectPinned.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectUnpinned(id: number, objectType: ObjectTypes, model: any): void {
    this.objectUnpinned.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectSubscribedTo(id: number, objectType: ObjectTypes, model: any): void {
    this.objectSubscribedTo.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastObjectUnsubscribedFrom(id: number, objectType: ObjectTypes, model: any): void {
    this.objectUnbscribedFrom.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastRagBreakdownUpdated(id: number, objectType: ObjectTypes, model: any): void {
    this.ragBreakdownUpdated.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastRefreshChecklistsState(): void {
    this.refreshChecklists.next();
  }

  //lists methods
  public broadcastListAdded(id: number, objectType: ObjectTypes, model: any): void {
    this.listAdded.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastListUpdated(id: number, objectType: ObjectTypes, model: any): void {
    this.listUpdated.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastListDeleted(id: number, objectType: ObjectTypes, model: any): void {
    this.listDeleted.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastAccountSettingUpdated(settingType: AccountSettingTypes, setting: AccountSettingViewModel): void {
    this.accountSettingUpdated.next({ settingType, setting });
  }

  public broadcastListLightUpdated(id: number, objectType: ObjectTypes, model: any): void {
    this.listLightUpdated.next(new ObjectEventModel(id, objectType, model));
  }

  public broadcastListFilterAdded(filters: FilterViewModel[]): void {
    this.listFilters = filters.slice();
    this.listFilterAdded.next(filters.slice());
  }

  public broadcastListFilterCleared(filters: FilterViewModel[]): void {
    this.listFilters = [];
    this.listFilterCleared.next(filters.slice());
  }

  public broadcastHistoryAdded(historyEntry: {objectId: number, objectType: ObjectTypes, history: HistoryEntryViewModel}): void {
    this.historyAdded.next(historyEntry);
  }
}
