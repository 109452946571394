<ng-container *ngIf="showRating">
  <div
    class="status-box"
    [style.background-color]="getStatusBoxColor()"
    ngDropdown
    [templateRef]="filterTemplate"
    *ngIf="canEdit; else staticStatusBoxTempalte"
  >
    <div class="status-stars">
      <rating [(ngModel)]="job.priority" [max]="3" [readonly]="true" [customTemplate]="jobStatusTemplate"></rating>
    </div>
    <div class="status-text">{{ jobStatuses[job.status].toString().replace('_', ' ') }}</div>
  </div>
</ng-container>
<ng-container *ngIf="!showRating">
  <div
    class="status-box-details-page"
    [style.background-color]="getStatusBoxColor()"
    ngDropdown
    [templateRef]="filterTemplate"
    *ngIf="canEdit; else staticStatusBoxDetailsTemplate"
  >
    <div class="status-text-details-page" [class.separator]="showDropdown">
      {{ jobStatuses[job.status].toString().replace('_', ' ') }}
    </div>
    <div *ngIf="showDropdown" class="drodpdown-arrow">
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
  </div>
</ng-container>

<ng-template #staticStatusBoxTempalte>
  <div class="status-box" [style.background-color]="getStatusBoxColor()">
    <div class="status-stars">
      <rating [(ngModel)]="job.priority" [max]="3" [readonly]="true" [customTemplate]="jobStatusTemplate"></rating>
    </div>
    <div class="status-text">{{ jobStatuses[job.status].toString().replace('_', ' ') }}</div>
  </div>
</ng-template>

<ng-template #staticStatusBoxDetailsTemplate>
  <div class="status-box-details-page" [style.background-color]="getStatusBoxColor()">
    <div class="status-text-details-page" [class.separator]="showDropdown">
      {{ jobStatuses[job.status].toString().replace('_', ' ') }}
    </div>
    <div *ngIf="showDropdown" class="drodpdown-arrow">
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
  </div>
</ng-template>

<ng-template #filterTemplate>
  <div class="filters-container">
    <div class="status-container">
      <div class="status-container-title">{{ T.common.select_status | translate }}</div>
      <div class="status-container-entries-container">
        <app-filters-list-entries
          [filterType]="filterTypes.Job_Status"
          [entries]="availableStatusFilters"
          [selectedEntries]="[selectedStatusFilter]"
          [mobile]="mobile"
          [required]="true"
          (entrySelected)="onEntrySelected($event)"
        >
        </app-filters-list-entries>
      </div>
    </div>
    <div class="priority-container">
      <div class="priority-container-title">{{ T.common.select_priority | translate }}</div>
      <div class="priority-container-entries-container">
        <app-filters-list-entries
          [filterType]="filterTypes.Job_Priority"
          [entries]="availablePriorityFilters"
          [selectedEntries]="[selectedPriorityFilter]"
          [mobile]="mobile"
          [required]="true"
          (entrySelected)="onEntrySelected($event)"
        >
        </app-filters-list-entries>
      </div>
    </div>
    <app-filters-desktop-footer
      *forScreen="'desktop'"
      [useClearAllButton]="false"
      [useCancelApplyButtons]="true"
      [clearDisabled]="!filters.length"
      [applyDisabled]="false"
      (clear)="onClear($event)"
      (cancel)="onCancel()"
      (apply)="onApply()"
    >
    </app-filters-desktop-footer>

    <app-filters-mobile-footer
      *forScreen="'mobile'"
      [applyDisabled]="false"
      (apply)="onApply()"
    ></app-filters-mobile-footer>
  </div>
</ng-template>

<ng-template #jobStatusTemplate let-i="index" let-v="value">
  <span
    class="material-icons-outlined star"
    [ngStyle]="{ color: i < v ? '#ffffff' : '#e0e0e0', opacity: i < v ? '1' : '0.2' }"
    >star</span
  >
</ng-template>
