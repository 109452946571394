<div #controlsWrapper class="sidebar-controls-wrapper" [class.active]="active">
  <div [title]="'Sidebar toggle'" class="control-btn" (click)="onToggleSidebar()">
    <i class="material-icons more-icon">more_horiz</i>
  </div>

  <ng-container *ngIf="showZonesAreasBtn">
    <div class="splitter"></div>
    <app-zone-area-toggle-button
      [map]="map"
      (onButtonStateChanged)="onZonesAreasButtonStateChanged($event)"
    ></app-zone-area-toggle-button>
  </ng-container>

  <ng-container *ngIf="showFullscreenBtn">
    <div class="splitter"></div>
    <div [title]="'Fullscreen toggle'" class="control-btn" (click)="onFullScreenClicked($event)">
      <i #fullscreenIcon class="material-icons fullscreen-icon">fullscreen</i>
    </div>
  </ng-container>

  <ng-container *ngIf="showTrafficLayerBtn">
    <div class="splitter"></div>
    <app-traffic-layer-button
      class="control-btn"
      [map]="map">
    </app-traffic-layer-button>
  </ng-container>

  <ng-container *ngIf="showMyLocationBtn">
    <div class="splitter"></div>
    <div class="control-btn">
      <app-my-location-button
        (locationAvailableEvent)="onMyLocationAvailable($event)">
      </app-my-location-button>
    </div>
  </ng-container>

  <ng-container *ngIf="showW3WGridBtn">
    <div class="splitter"></div>
    <div class="control-btn">
      <app-w3w-grid-button [map]="map"></app-w3w-grid-button>
    </div>
  </ng-container>

  <ng-container *ngIf="showReloadBtn">
    <div class="splitter"></div>
    <div [title]="'Refresh/pan out'" class="control-btn" (click)="onRefreshClicked($event)">
      <i #refreshIcon class="material-icons refresh-icon">refresh</i>
    </div>
  </ng-container>
</div>

<div #sidebarWrapper class="sidebar-wrapper">
  <div class="header">
    <h3>Map Options</h3>
    <div class="dismiss" (click)="onCloseSidebar()">
      <i class="material-icons">close</i>
    </div>
  </div>
  <div class="body">
    <div class="section-wrapper">
      <h5>Map Views</h5>
      <app-map-views-group-button
        #mapTypesGroupButton
        [map]="map"
        (mapViewUnitSelected)="onMapViewUnitSelected($event)"
      ></app-map-views-group-button>
      <app-checkbox-with-label *ngIf="displayHybridMapCheckbox"
        class="hybrid-view"
        label="Display labels and additional overlays"
        intialState="unchecked"
        (clicked)="onHybridMapTypeClicked($event)"
      >
      </app-checkbox-with-label>
    </div>
    <div class="section-wrapper" style="display:none">
      <h5>My Locations</h5>
    </div>
    <div class="section-wrapper">
      <h5>Map Levels</h5>
      <app-checkbox-with-label
          class="all-levels"
          label="Show All Levels"
          [intialState]="visibleMapLayers.length ? 'unchecked': 'checked'"
          [disabled]="!visibleMapLayers.length"
          (clicked)="onMapLayerCheckStatusChanged(null, $event)"
        ></app-checkbox-with-label>
      <p class="explain">
        Or select levels below:
      </p>
      <ng-container *ngFor="let layer of mapLayers">
        <app-checkbox-with-label
          class="map-levels"
          label="Level {{ layer.label }}"
          [intialState]="mapLevelCheckState(layer) ? 'checked' : 'unchecked'"
          (clicked)="onMapLayerCheckStatusChanged(layer, $event)"
        ></app-checkbox-with-label>
      </ng-container>
    </div>
    <div class="section-wrapper">
      <h5>Zones & areas</h5>
      <div class="controls-wrapper">
        <app-checkbox-with-label
          label="Show All Zones"
          intialState="{{zonesVisible ? 'checked' : 'unchecked'}}"
          (clicked)="onZonesDisplayButtonStateChanged($event)"
        ></app-checkbox-with-label>
        <app-checkbox-with-label
          label="Show All Areas"
          intialState="{{areasVisible ? 'checked' : 'unchecked'}}"
          (clicked)="onAreasDisplayButtonStateChanged($event)"
        ></app-checkbox-with-label>
      </div>
    </div>
  </div>
</div>
