<div *ngIf="filterType && showHeader" class="filters-inline-edit-lozenges-header">
  <div *ngIf="!label" class="filters-inline-edit-lozenges-header-title">
    {{ filterType | localiseFilterType }}
  </div>
  <div *ngIf="label" class="filters-inline-edit-lozenges-header-title">
    {{ label }}
  </div>
  <i class="material-icons" *ngIf="!disabled"  (click)="onClose()">close</i>
</div>
<ng-container *ngIf="appliedFilters && appliedFilters.length > 0; else noItemsTemplate">
  <div class="filters-inline-edit-lozenges-body">
    <ng-container *ngFor="let filter of appliedFilters">
      <app-chip
      *ngIf="filter.filterText"
      [disabled]="disabled"
      [tooltip]="{message: filter.filterText}"
      (lozengeClicked)="!isActionsGroup ? onFilterRemove(filter) : null"
      [class.primary-chip]="isPrimaryFunctionalityAllowed"
      [isActionsGroup]="isActionsGroup"
      >
      <app-employee-avatar
      class="mr-8"
      *ngIf="filterType === filterTypes.Owner"
      [employee]="getEmployee(filter.filterValue)"
      [size]="2"
       ></app-employee-avatar>
      {{ filter.filterText }}
      <div class="primary-wrapper" *ngIf="isPrimaryFunctionalityAllowed">
        <i
          (mouseover)="isPrimaryIconHovered = true"
          (mouseout)="isPrimaryIconHovered = false"
          class="material-icons material-icons-grade star"
          [class.material-icons-outlined]="!filter.isPrimary || (filter.isPrimary && isPrimaryIconHovered)"
          (click)="$event.stopPropagation(); onPrimaryClick(filter)"
        >
          grade
        </i>
      </div>
      </app-chip>
    </ng-container>
  </div>
</ng-container>
<ng-template #noItemsTemplate>
  <div class="filters-inline-edit-lozenges-text">{{ T.common.no_items_selected | translate }}</div>
</ng-template>
