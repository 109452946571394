<div class="inline-edit-wrapper">
  <i class="material-icons" [ngClass]="!isActionsGroup ? 'material-icons-done' : 'material-icons-done-visible'">done</i>
  <i  *ngIf="!isActionsGroup" class="material-icons material-icons-close">highlight_off</i>
  <div
    class="filters-lozenge-inline-edit-text text-truncate"
    [app-tooltip]="{ message: tooltip?.message}"
    [showTooltip]="!!tooltip"
  >
    <ng-content></ng-content>
  </div>
</div>
