<div #wrapper class="wrapper">
  <div [ngClass]="useSmallBeads ? 'beads-wrapper-small' : 'beads-wrapper'" [class.beads-wrapper-with-bubbles]="showBubbles">
    <div class="bead" [class.active]="bead.active" *ngFor="let bead of beads" (click)="onBeadClicked(bead)">
      <div [ngClass]="useSmallBeads ? 'left-thread-small' : 'left-thread'"></div>
      <div [ngClass]="useSmallBeads ? 'small-beads bead-icon-wrapper' : 'bead-icon-wrapper'" *ngIf="showBubbles">
        <i *ngIf="bead.icon"
           class="{{ getBeadClass(bead) }}">
          {{ bead.icon }}
        </i>
        <i *ngIf="bead.completed" class="material-icons check-icon txt-white">check</i>
        <i class="txt-white" *ngIf="bead.number && !bead.completed">{{ bead.number }}</i>
        <div class="bead-description-wrapper">
          <div class="bead-description">{{ bead.description }}</div>
        </div>
      </div>
      <div [ngClass]="useSmallBeads ? 'right-thread-small' : 'right-thread'"></div>
    </div>
  </div>
</div>
