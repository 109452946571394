import { Injectable } from "@angular/core";
import { WtStorageService } from "../../shared/services/wt-storage.service";
import { GanttColumnSize } from "../utilities/gantt-column-size";

@Injectable()
export class GanttCachingService {
  public static readonly localStorageKey = 'DHX_GANTT_GRID';

  constructor(
    private readonly wtStorage: WtStorageService
  ) {}

  public getCachedGanttColumnsSize(): GanttColumnSize[] {
    if(this.wtStorage.getItem(GanttCachingService.localStorageKey)) {
      return JSON.parse(this.wtStorage.getItem(GanttCachingService.localStorageKey)) as GanttColumnSize[];
    }

    return [];
  }

  public saveCachedGanttColumnsSize(cachedData: GanttColumnSize[]): void {
    this.wtStorage.setItem(GanttCachingService.localStorageKey, JSON.stringify(cachedData));
  }

  public updateCachedGanttColumnSize(columnID: string, width: number) {
    const cachedColsData = this.getCachedGanttColumnsSize();
    const cached = cachedColsData.find(e => e.columnId === columnID);
    if(cached) {
      cached.size = width;
    } else {
      cachedColsData.push({
        columnId: columnID,
        size: width
      });
    }

    this.saveCachedGanttColumnsSize(cachedColsData);
  }

  public getCachedColumnSize(columnId: string): number {
    const cachedColsData = this.getCachedGanttColumnsSize();
    return cachedColsData.find(c => c.columnId === columnId)?.size ?? 0;
  }
}
