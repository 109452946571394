export enum AccountSettingTypes {
  Super_Set_RAGs = 1,
  Objectives_Show_Budget_Fields = 10,
  Show_Venues_In_Grid = 11,
  Sort_Dashboard_Events_By_Delivery_Date = 12,
  Task_Systems_Sort_Default = 13,
  Use_IMS = 20,
  Show_Gantt_In_Menu = 23,
  Show_Gantt_Days = 231,
  Use_Public_Incident_Report = 21,
  Date_Format = 24,
  Sign_In_With_Active_Directory = 25,
  Objectives_Page_View = 26,
  Show_Department_Category_Column_In_Venues_Table = 27,
  Dashboard_View = 28,
  Has_VP_And_SVP_User_Roles = 29,
  AllowSegmentedRAGs = 30,
  Show_Activities_Section = 31,
  Show_Department_Categories = 32,
  Use_Task_Systems = 33,
  Show_IMS_Status_Column = 34,
  Use_IMS_Only = 35,
  Use_Notifications = 36,
  Use_Venue_Tags = 37,
  Account_Time_Zone = 38,
  Show_Venue_Tags_Filter = 39,
  Show_Activity_Feed = 40,
  Show_Trends = 41,
  Use_New_Dashboard = 42,
  Dashboard_Show_Weather = 50,
  Dashboard_Weather_Location = 51,
  Dashboard_Show_Countdown = 52,
  Dashboard_Countdown_Settings = 53,
  Hide_Weather_Widgets = 54,
  Use_Whitelabeling = 55,
  MilestoneType_Standard = 60,
  MilestoneType_Portfolio = 61,
  MilestoneType_Department = 62,
  Fixed_Filters = 63,
  Intregration_Links = 70,
  IMS_Settings_Use_Default_Location = 80,
  IMS_Settings_Default_Location = 81,
  Incident_Dashboard_Info_Widget = 82,
  Incident_Severities = 83,
  Incident_Dashboard_Weather_Locations = 84,
  IMS_Default_Location = 85,
  Use_Runsheets = 90,
  Allow_Runsheet_Items_Dependency = 91,
  Runsheet_Settings_Send_Notification_On_Day_Of_Event = 92,
  Runsheet_Settings_Send_Notification_If_Delayed = 93,
  Venue_Dashboard_Info_Widget = 94,
  Indicator_Default_Climatiq_Region = 95,
  Indicator_Default_Climatiq_Source = 96,
  Use_Jobs = 101,
  Use_AccountHub = 102,
  Default_View = 110,
  Enable_Incident_Channels = 120,
  Use_Peaps = 130,
  Storage_Provider = 140,
  Use_Risk_Assesments = 150,
  Enable_Risk_Search = 151,
  Use_Suggested_Indicators = 160,
  Slack_Token = 500,
  Slack_Bot_Token = 501,
  Slack_Channel = 502,
  PEAP_Send_Frequency = 503,
  Default_Card_Filters = 200,
  Risk_Likelihood = 210,
  Risk_Impact = 211,
  Use_Planning = 300,
  Use_Risk = 301,
  Enforce_AutoUpdate_Blue_RAG_Rule = 400,
  Use_Sustainability = 600,
}
