<div #header class="modal-header">
  <div class="modal-header-content">
    <h4>{{ T.common.crop_image | translate }}</h4>
    <i
      class="material-icons modal-close"
      (click)="bsModalRef.hide()"
      style="font-size: 20px; margin-right: 0; float: unset !important; right: 0; position: absolute; top: 14px"
      >close</i
    >
  </div>
</div>

<div class="modal-body modal-body-margin">
  <div class="row">
    <div class="col-12 mb-2">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageFile]="imageFile"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="aspectRatio"
        [roundCropper]="roundCropper"
        [format]="extension"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      >
      </image-cropper>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-6">
      <button class="btn btn-white btn-block filter-button" (click)="onCancel()">{{ T.common.cancel | translate }}</button>
    </div>
    <div class="col-6">
      <button class="btn action-btn btn-block filter-button" (click)="onConfirm()">{{ T.common.confirm | translate}}</button>
    </div>
  </div>
</div>
