<div #wrapper class="wrapper" [style.max-width]="popup?.maxWidth">
  <ng-container [ngSwitch]="popup?.position">
    <div
      *ngSwitchCase="position.Bottom"
      class="top-arrow-pointer-wrapper"
      [class.left]="axisPosition === position.Left"
      [class.right]="axisPosition === position.Right"
    >
      <div class="top-arrow-pointer"></div>
    </div>
    <div
      *ngSwitchCase="position.Top"
      class="bottom-arrow-pointer-wrapper"
      [class.left]="axisPosition === position.Left"
      [class.right]="axisPosition === position.Right"
    >
      <div class="bottom-arrow-pointer"></div>
    </div>
    <div
      *ngSwitchCase="position.Right"
      class="left-arrow-pointer-wrapper"
      [class.top]="axisPosition === position.Top"
      [class.bottom]="axisPosition === position.Bottom"
    >
      <div class="left-arrow-pointer"></div>
    </div>
    <div
      *ngSwitchCase="position.Left"
      class="right-arrow-pointer-wrapper"
      [class.top]="axisPosition === position.Top"
      [class.bottom]="axisPosition === position.Bottom"
    >
      <div class="right-arrow-pointer"></div>
    </div>
  </ng-container>

  <div class="header">
    <div class="icon-wrapper">
      <i class="material-icons">{{ popup?.icon }}</i>
    </div>
    <div class="title-wrapper">
      <div class="title">{{ popup?.title }}</div>
    </div>
    <div class="close-wrapper"><i class="material-icons" (click)="onCancel()">clear</i></div>
  </div>
  <div class="popup-content" [class.paddingless]="popup?.paddingless" (click)="onClick($event)">
    <ng-template [ngTemplateOutlet]="popup?.templateRef"></ng-template>
  </div>
  <div class="footer" *ngIf="popup?.useFooter">
    <div class="cancel-wrapper">
      <div class="cancel" (click)="onCancel()">Cancel</div>
    </div>
    <div class="save-changes-wrapper">
      <div class="save-changes" (click)="onSaveChanges()">Save Changes</div>
    </div>
  </div>
</div>
