import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

export class DropdownOptionChange<T = any> {
  constructor(
    public value: T,
    // Emits whether the change was selected or deselected. (true or false)
    public isSelected: boolean,
    ) { }
}

@Component({
  selector: 'app-dropdown-option',
  templateUrl: './dropdown-option.component.html',
  styleUrls: ['./dropdown-option.component.scss'],
})
export class DropdownOptionComponent<T = any> {

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    this._selectViaInteraction()
  }

  @Input() value: T;
  @Input() selected: boolean;
  @Input() mobile: boolean;
  @Input() useBorderBottom: boolean;
  /**
   * Whether option can be deselected.Normally used when the select is required (e.g. required single-selection can't be deselected),
   */
  @Input() allowDeselect = true;
  @Input() focused: boolean;
  @Input() disabled: boolean = false;
  @Input() useCheckbox: boolean = false;
  @Input() singleSelect: boolean = false;
  @Input() isActionsGroup: boolean = false;

  @Output() readonly selectionChange = new EventEmitter<DropdownOptionChange>();
  @Output() readonly deselectionChange = new EventEmitter<T>();

  /**
   * `Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.`
   */
  private _selectViaInteraction(): void {
    if (!this.disabled) {
      this._emitChangeEvent();
    }
  }

  private _emitChangeEvent(): void {
    this.selectionChange.emit(new DropdownOptionChange(this.value, !this.selected));
  }

  public onDeselect(event: MouseEvent): void {
    event.stopPropagation();
    if (this.allowDeselect) {
      this.selected = false;
      this.deselectionChange.emit(this.value);
    }
  }
}
