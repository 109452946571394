<header class="header" *ngIf="!hideHeaderBar && isAuthenticated && !isNotFoundRoute">
  <ng-container *ngIf="!isAccountHubRoute; else accountHubWrapper">
    <div class="navigation-wrapper">
      <section *ngIf="searchMode" class="search-wrapper">
        <div class="d-flex">
          <i class="material-icons search-icon" (click)="onSearch()">search</i>
          <app-text-field
            [autofocus]="true"
            [(ngModel)]="searchModel"
            [placeholder]="getPlaceholderText()"
            (keyup.enter)="onSearch()"
            class="w-100"
          ></app-text-field>
        </div>
        <app-button-transparent (clicked)="hideSearch()" icon="close" class="search-close-btn"></app-button-transparent>
      </section>
      <section class="toggle-button-wrapper" *ngIf="isMobileScreen" (click)="toggleSideNav($event)">
        <div class="responsive-flex-wrapper" *ngIf="isMobileScreen">
          <div class="open-sidebar-icon" *ngIf="!isSidebarExpanded">
            <i class="material-icons menu-icon">menu</i>
            <span class="notification-counter" *ngIf="pendingNotifications">{{ pendingNotifications }}</span>
          </div>
          <i class="material-icons menu-icon" *ngIf="isSidebarExpanded">close</i>
        </div>
        <!-- <div class="responsive-flex-wrapper" *ngIf="!isMobileScreen">
          <i class="material-icons menu-icon" [class.desktop-icon]="isSidebarExpanded">menu_open</i>
        </div> -->
      </section>
      <section *ngIf="!isControlDashboardRoute" class="filters-container">
        <app-filters *ngIf="showFilters" [hidePartialBasicFilters]="hidePartialBasicFilters"></app-filters>
      </section>
      <section class="buttons-wrapper">
        <div class="nav-item dropdown" dropdown>
          <app-button-transparent
            *ngIf="isSearchableModule"
            class="search-icon"
            (clicked)="showSearch()"
            icon="search"
            textColor="primary-text"
          ></app-button-transparent>
          <app-button-rectangle
            (click)="$event.stopPropagation()"
            *ngIf="!isMobileScreen && hasAnyAddPermissions && !isSettingsRoute && !isReportRoute"
            class="add-button"
            [text]="T.common.add | translate"
            size="small"
            icon="add"
            aria-controls="dropdown-basic"
            dropdownToggle
          ></app-button-rectangle>
          <app-button-icon-square
            (click)="$event.stopPropagation()"
            buttonTheme="primary"
            class="add-button"
            *ngIf="isMobileScreen && hasAnyAddPermissions && !isReportRoute"
            size="small"
            icon="add"
            aria-controls="dropdown-basic"
            dropdownToggle
          >
          </app-button-icon-square>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu" aria-labelledby="button-basic">
            <ng-container *ngIf="showProjectsAddLinks">
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Planning | canCreate: filterTypes.Project"
                (click)="openProjectsModal({ title: 'Project' })"
              >
                <span>{{ T.common.add_item | translate: { item: objectTypes.Project | localiseObjectType } }}</span>
              </a>
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Planning | canCreate: filterTypes.Task_Group"
                style="display: none"
                (click)="openProjectsModal({ title: 'TaskGroup' })"
              >
                <span>{{ T.common.add_item | translate: { item: objectTypes.Task_Group | localiseObjectType } }}</span>
              </a>
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Planning | canCreate: filterTypes.Task"
                (click)="openProjectsModal({ title: 'Task' })"
              >
                <span>{{ T.common.add_item | translate: { item: objectTypes.Task | localiseObjectType } }}</span>
              </a>
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Planning | canCreate: filterTypes.Task"
                style="display: none"
                (click)="openProjectsModal({ title: 'Sub-Task' })"
              >
                <span>{{ T.common.add_item | translate: { item: localiseSubTask() } }}</span>
              </a>
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Risk | canCreate: filterTypes.Risk"
                style="display: none"
                (click)="openProjectsModal({ title: 'Action' })"
              >
                <span>{{
                  T.common.add_item | translate: { item: objectTypes.Risk_Action_Item | localiseObjectType }
                }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="canSeeRiskAddItems">
              <a
                class="dropdown-item"
                *ngIf="moduleTypes.Risk | canCreate: filterTypes.Risk"
                (click)="openProjectsModal({ title: 'Risk/Issue' })"
              >
                <span>{{ T.common.add_item | translate: { item: objectTypes.Risk | localiseObjectType } }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="canSeeIncidentAddItems">
              <a
                *ngIf="moduleTypes.Incidents | canCreate: filterTypes.Incident"
                class="dropdown-item"
                (click)="openIncidentsModal()"
              >
                {{ T.common.add_item | translate: { item: objectTypes.IncidentItem | localiseObjectType } }}
              </a>

              <a
                *ngIf="moduleTypes.Incidents | canCreate: filterTypes.Incident"
                class="dropdown-item"
                (click)="openLogsModal()"
                >{{ T.common.add_item | translate: { item: T.defaultLocalizations.log.one | translate } }}</a
              >

              <a
                *ngIf="(moduleTypes.Incidents | canCreate: filterTypes.Job) && currentAccount.useJobs"
                class="dropdown-item"
                (click)="openJobsModal()"
                ><span>{{ T.common.add_item | translate: { item: objectTypes.Job | localiseObjectType } }}</span></a
              >
            </ng-container>
            <ng-container *ngIf="canSeeRunsheetsAddItems">
              <a
                *ngIf="moduleTypes.Runsheets | canCreate: filterTypes.Runsheet"
                class="dropdown-item"
                (click)="openAddRunsheetModal()"
                ><span>{{ T.common.add_item | translate: { item: objectTypes.Runsheet | localiseObjectType } }}</span></a
              >
            </ng-container>


          <ng-container *ngIf="canSeeSustainabilityAddItems">
            <a class="dropdown-item" (click)="openAddIndicatorModal()"
              ><span>{{ T.common.add_item | translate: { item: objectTypes.Indicator | localiseObjectType } }}</span></a
            >
            <a class="dropdown-item" (click)="openAddIndicatorTemplateModal()" *ngIf="this.currentAccount.isHubAccount"
              ><span>{{ T.common.add_item | translate: { item: objectTypes.Indicator_Template | localiseObjectType } }}</span></a
            >
          </ng-container>

        </div>
      </div>
    </section>
  </div>
  </ng-container>
</header>

<ng-template #accountHubWrapper>
  <div class="hub-navigation-wrapper">

  </div>
</ng-template>
