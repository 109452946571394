<ng-template #template>
  <div *ngIf="!isReadOnly" class="severity-picker-container">
    <app-modal>
      <app-modal-header title="{{T.control.select_severity | translate}}" (closed)="onCancel()"></app-modal-header>
      <app-modal-body>
        <app-severity-selector
          [currentlySelectedSeverityCheckpoint]="severityCheckpoint"
          [showScaleGuideSelectSeverityText]="false"
          (checkpointSelected)="lastlySelectedSeverityCheckpoint = $event"
        >
        </app-severity-selector>
      </app-modal-body>
      <app-modal-footer>
        <div class="severity-picker-container__footer d-block d-md-flex">
          <app-button-transparent
            class="d-none d-md-block"
            text="{{T.common.cancel | translate}}"
            (clicked)="onCancel()"
          ></app-button-transparent>
          <app-button-transparent
            class="d-none d-md-block"
            text="{{T.common.save_changes | translate}}"
            (clicked)="onSave()"
          ></app-button-transparent>
          <app-button-rectangle
            class="d-block d-md-none"
            [fitToContainer]="true"
            size="large"
            text="{{T.common.save_changes | translate}}"
            (clicked)="onSave()"
          ></app-button-rectangle>
        </div>
      </app-modal-footer>
    </app-modal>
  </div>
</ng-template>

<div #wrapper class="wrapper" (click)="onClick($event)" ngDropdown [templateRef]="template">
  <ng-container *ngIf="!rectangleButton">
    <div class="responsive-flex-wrapper">
      <app-bubble
        [class.severity-dropdown-icon]="!isReadOnly"
        [large]="useLargeBubble"
        [color]="severityCheckpoint.color"
        [value]="severityCheckpoint.severity">
      </app-bubble>
    </div>
  </ng-container>
  <ng-container *ngIf="rectangleButton">
    <div class="status-box-details-page" [style.background-color]="severityCheckpoint.color">
    <div class="status-text-details-page separator">
      Severity {{ severityCheckpoint.severity }}
    </div>
    <div class="dropdown-arrow">
      <i class="material-icons">keyboard_arrow_down</i>
    </div>
  </div>
  </ng-container>
</div>
