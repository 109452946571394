<div class="d-flex m-t-10 mr-2">
  <div class="search-field-wrapper">
    <input
      type="text"
      #search
      *ngIf="searchFieldOpen"
      class="open-search-field"
      placeholder="Start typing..."
      [(ngModel)]="userInput"
      (ngModelChange)="this.userInputUpdate.next($event)"
    />
  </div>
  <div>
    <i
      class="material-icons searchIconNotClicked"
      *ngIf="!searchFieldOpen; else searchOpened"
      (click)="openSearchField()"
      >search</i
    >
    <ng-template #searchOpened>
      <i class="material-icons searchIcon" (click)="openSearchField()">search</i>
    </ng-template>
  </div>
</div>
