<div class="wrapper" *ngIf="modules">
  <div class="row">
    <div class="col-12">
      <div class="editor-section-wrapper">
        <div class="main-image-wrapper" *ngIf="showProfileImage">
          <app-employee-avatar [employee]="employee"></app-employee-avatar>
        </div>
        <div class="editor-wrapper" [class.input-field-disabled]="disabled" (keydown.control.enter)="saveOnCtrlEnter()">
          <quill-editor
            [(ngModel)]="commentModel"
            customToolbarPosition="bottom"
            [formats]="allowedFormats"
            [modules]="modules"
            [placeholder]="placeholder"
            (onFocus)="onEditorFocused($event)"
            (onBlur)="onEditorBlur($event)"
            (onEditorChanged)="changedEditor($event)"
            (onEditorCreated)="setFocus($event)"
            (ngModelChange)="onNgModelChange($event)"
            class="d-block"
          >
            <div quill-editor-toolbar class="toolbar-controls" [ngStyle]="{ display: isFocused ? 'flex' : 'none' }">
              <div *ngIf="!isMobile && (enableCntrEnterSave || enableClickAwaySave)" class="info-wrapper">
                <span *ngIf="enableCntrEnterSave && enableClickAwaySave" class="info">{{
                  T.common.press_ctrl_enter_or_click_to_save | translate
                }}</span>
                <span *ngIf="enableCntrEnterSave && !enableClickAwaySave" class="info">{{
                  T.common.press_ctrl_enter_to_save | translate
                }}</span>
                <span *ngIf="enableClickAwaySave && !enableCntrEnterSave" class="info">{{
                  T.common.click_away_to_save | translate
                }}</span>
              </div>
              <div class="controls-wrapper" [class.mobile]="isMobile">
                <div *ngIf="isMobile">
                  <button class="custom-btn normal-weight" *ngIf="showCancel" (click)="cancel()">
                    <i class="material-icons">close</i>
                  </button>
                  <button class="custom-btn mr-4" [class.disabled]="disableSaveButton" (click)="save()">
                    <i class="material-icons">done</i>
                  </button>
                </div>

                <div>
                  <span class="ql-formats">
                    <button
                      *ngIf="showUndoAndRedo"
                      type="button"
                      (click)="undo()"
                      value='<svg viewbox="0 0 18 18">
                                <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                                <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                            </svg>'
                    >
                      <svg viewBox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                      </svg>
                    </button>
                    <button
                      *ngIf="showUndoAndRedo"
                      type="button"
                      (click)="redo()"
                      value='<svg viewbox="0 0 18 18">
                                <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                                <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                              </svg>'
                    >
                      <svg viewBox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                      </svg>
                    </button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-strike" style="display: none"></button>
                  </span>
                </div>
              </div>
            </div>
          </quill-editor>
        </div>
      </div>
    </div>
  </div>
</div>
