import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { AuthenticationService } from './authentication.service';
import { ShareItemViewModel } from '../viewModels/utilities/shareItemViewModel';
@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  private sidebarToggled = new BehaviorSubject<boolean>(false);
  sidebarToggled$: Observable<boolean> = this.sidebarToggled.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {}

  public Share(shareItemViewModel: ShareItemViewModel): Observable<void> {
    const url = this.configuration.buildEndpoint(`Utilities/shareItem`);
    return this.http.post<void>(url, shareItemViewModel, this.authenticationService.getHttpOptions());
  }

  broadcastSidebarToggle(isToggled: boolean) {
    this.sidebarToggled.next(isToggled);
  }
}
