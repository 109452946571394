<div #icon class="icon" [ngStyle]="{ width: size + 'px', height: size + 'px' }">
  <!-- <svg><use [attr.xlink:href]="iconPath"></use></svg> -->

  <ng-container [ngSwitch]="cardType">
    <ng-container *ngSwitchCase="cardTypes.Project">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <rect [attr.fill]="ragStatusColor" width="40" height="40" x="4" y="4" rx="6" />
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M34.667 14.667H24L21.333 12h-8c-1.472 0-2.666 1.194-2.666 2.667v18.666c0 1.473 1.194 2.667 2.666 2.667h21.334c1.472 0 2.666-1.194 2.666-2.667v-16c0-1.472-1.194-2.666-2.666-2.666zm0 18.666H13.333v-16h21.334v16z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Department">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <rect [attr.fill]="ragStatusColor" width="40" height="40" x="4" y="4" fill="#353F47" rx="6" />
          <g>
            <path d="M0 0L32 0 32 32 0 32z" transform="translate(8 8)" />
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M21.333 14.667c2.214 0 3.987-1.787 3.987-4 0-2.214-1.773-4-3.987-4-2.213 0-4 1.786-4 4 0 2.213 1.787 4 4 4zm-10.666 0c2.213 0 3.986-1.787 3.986-4 0-2.214-1.773-4-3.986-4-2.214 0-4 1.786-4 4 0 2.213 1.786 4 4 4zm0 2.666c-3.107 0-9.334 1.56-9.334 4.667v3.333H20V22c0-3.107-6.227-4.667-9.333-4.667zm10.666 0c-.386 0-.826.027-1.293.067 1.547 1.12 2.627 2.627 2.627 4.6v3.333h8V22c0-3.107-6.227-4.667-9.334-4.667z"
              transform="translate(8 8)"
            />
          </g>
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Department_Category">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <rect [attr.fill]="ragStatusColor" width="40" height="40" x="4" y="4" fill="#353F47" rx="6" />
          <g>
            <path d="M0 0L32 0 32 32 0 32z" transform="translate(8 8)" />
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M20 14.667v-8l-4-4-4 4v2.666H4V28h24V14.667h-8zM9.333 25.333H6.667v-2.666h2.666v2.666zm0-5.333H6.667v-2.667h2.666V20zm0-5.333H6.667V12h2.666v2.667zm8 10.666h-2.666v-2.666h2.666v2.666zm0-5.333h-2.666v-2.667h2.666V20zm0-5.333h-2.666V12h2.666v2.667zm0-5.334h-2.666V6.667h2.666v2.666zm8 16h-2.666v-2.666h2.666v2.666zm0-5.333h-2.666v-2.667h2.666V20z"
              transform="translate(8 8)"
            />
          </g>
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Task">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <rect [attr.fill]="ragStatusColor" width="40" height="40" x="4" y="4" rx="6" />
          <g fill="#FFF" fill-rule="nonzero">
            <path
              d="M7.947 16L1 9 3.8 6.187 7.947 10.347 18.2 0 21 2.813zM.333 20H21.665999999999997V22.667H.333z"
              transform="translate(13 12)"
            />
          </g>
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Sub_Task">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <rect [attr.fill]="ragStatusColor" width="40" height="40" x="4" y="4" rx="6" />
          <g fill="#FFF" fill-rule="nonzero">
            <path
              d="M6.507 14L.852 8.296 3.133 5.999 6.507 9.388 14.867.963 17.148 3.261zM6.507 27.037L.852 21.333 3.133 19.036 6.507 22.425 14.867 14 17.148 16.298z"
              transform="translate(15 10)"
            />
          </g>
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Task_Group">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0L48 0 48 48 0 48z" />
          <path
            [attr.fill]="ragStatusColor"
            fill-rule="nonzero"
            d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z"
          />
          <path
            [attr.fill]="ragStatusColor"
            fill-rule="nonzero"
            d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Risk">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <path
            [attr.fill]="ragStatusColor"
            fill-rule="nonzero"
            d="M45.4 36.98L27.6 4.26c-.668-1.38-2.067-2.256-3.6-2.256s-2.932.876-3.6 2.256L2.6 36.98c-.817 1.378-.87 3.079-.139 4.504C3.192 42.91 4.604 43.86 6.2 44h35.6c1.596-.14 3.008-1.09 3.739-2.516.73-1.425.678-3.126-.139-4.504zM26 36h-4v-4h4v4zm0-8h-4V16h4v12z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Issue">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <path
            [attr.fill]="ragStatusColor"
            fill-rule="nonzero"
            d="M41.08 10.74l-14-7.9c-1.843-1.12-4.157-1.12-6 0l-14 8C5.221 11.903 4.053 13.86 4 16v16c-.015 2.144 1.093 4.14 2.92 5.26l14 7.9c1.843 1.12 4.157 1.12 6 0l14-7.9c1.889-1.081 3.062-3.084 3.08-5.26V16c.015-2.144-1.093-4.14-2.92-5.26zM26 34h-4v-4h4v4zm0-8h-4V14h4v12z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Opportunity">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0H48V48H0z" />
          <path
            [attr.fill]="ragStatusColor"
            fill-rule="nonzero"
            d="M44.4 20.12L27.88 3.6c-2.146-2.136-5.614-2.136-7.76 0L3.6 20.12c-2.136 2.146-2.136 5.614 0 7.76l6.84 6.84L23.18 22H18v-4h12v12h-4v-5.18L13.28 37.56l6.84 6.84c2.146 2.136 5.614 2.136 7.76 0L44.4 27.88c2.136-2.146 2.136-5.614 0-7.76z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Risk_Action">
      <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0L48 0 48 48 0 48z" />
          <path
            [attr.fill]="'#353F47'"
            fill-rule="nonzero"
            d="M40 8v24H16V8h24m0-4H16c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zM24.94 28L18 21l2.8-2.82 4.14 4.16L35.2 12l2.8 2.82L24.94 28zM8 12H4v28c0 2.2 1.8 4 4 4h28v-4H8V12z"
          />
        </g>
      </svg>
    </ng-container>

    <ng-container *ngSwitchCase="cardTypes.Milestone">
      <i class="material-icons" [ngStyle]="{ color: color }">flag</i>
    </ng-container>
    <!-- Sustainability -->
    <ng-container *ngSwitchCase="cardTypes.Indicator_Template">
      <svg
        [attr.width]="size"
        [attr.height]="size"
        height="48"
        width="48"
        viewBox="0 0 48 48"
        [attr.fill]="'#353f47'"
        xmlns="http://www.w3.org/2000/svg"
        height="48"
        width="48"
      >
        <path
          d="M30.2 42v-6.25h-7.7v-20.5h-4.65v6.5H4V6h13.85v6.25H30.2V6H44v15.75H30.2v-6.5h-4.7v17.5h4.7v-6.5H44V42Z"
        />
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="cardTypes.Indicator">
      <ng-container [ngSwitch]="trendStatus">
        <ng-container *ngSwitchCase="trendStatuses.Trending_Up">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1072.000000, -172.000000)">
                <g id="icon_indicator_amber_up" transform="translate(1072.000000, 172.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="ragStatusColor"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g
                    id="trending_down_black_24dp"
                    transform="translate(24.000000, 24.000000) scale(1, -1) translate(-24.000000, -24.000000) translate(8.000000, 8.000000)"
                  >
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Trending_Down">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -173.000000)">
                <g id="icon_indicator_amber_down" transform="translate(992.000000, 173.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="ragStatusColor"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="trending_down_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Pass">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -242.000000)">
                <g id="icon_indicator_green_pass" transform="translate(1152.000000, 242.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="ragStatusColor"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="task_alt_black_24dp" transform="translate(8.000000, 8.000000)">
                    <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                    <path
                      d="M29.3333333,6.90666667 L14.12,22.1333333 L8.46666667,16.48 L10.3466667,14.6 L14.12,18.3733333 L27.4533333,5.04 L29.3333333,6.90666667 Z M26.3866667,13.6266667 C26.56,14.3866667 26.6666667,15.1866667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C18.1066667,5.33333333 20.0533333,5.94666667 21.7066667,7 L23.6266667,5.08 C21.4666667,3.56 18.84,2.66666667 16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,14.4133333 29.04,12.8933333 28.5333333,11.48 L26.3866667,13.6266667 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Fail">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -102.000000)">
                <g id="icon_indicator_red_fail" transform="translate(1152.000000, 102.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="ragStatusColor"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="close_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="25.3333333 8.54666667 23.4533333 6.66666667 16 14.12 8.54666667 6.66666667 6.66666667 8.54666667 14.12 16 6.66666667 23.4533333 8.54666667 25.3333333 16 17.88 23.4533333 25.3333333 25.3333333 23.4533333 17.88 16"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -313.000000)">
                <g id="icon_indicator_blue" transform="translate(992.000000, 313.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="ragStatusColor"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="track_changes_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <path
                      d="M25.4266667,6.57333333 L23.5466667,8.45333333 C25.4666667,10.3866667 26.6666667,13.0533333 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.56 9.4,6.08 14.6666667,5.42666667 L14.6666667,8.12 C10.88,8.76 8,12.04 8,16 C8,20.4133333 11.5866667,24 16,24 C20.4133333,24 24,20.4133333 24,16 C24,13.7866667 23.1066667,11.7866667 21.6533333,10.3466667 L19.7733333,12.2266667 C20.7333333,13.2 21.3333333,14.5333333 21.3333333,16 C21.3333333,18.9466667 18.9466667,21.3333333 16,21.3333333 C13.0533333,21.3333333 10.6666667,18.9466667 10.6666667,16 C10.6666667,13.52 12.3733333,11.4533333 14.6666667,10.8533333 L14.6666667,13.7066667 C13.8666667,14.1733333 13.3333333,15.0133333 13.3333333,16 C13.3333333,17.4666667 14.5333333,18.6666667 16,18.6666667 C17.4666667,18.6666667 18.6666667,17.4666667 18.6666667,16 C18.6666667,15.0133333 18.1333333,14.16 17.3333333,13.7066667 L17.3333333,2.66666667 L16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,12.32 27.84,8.98666667 25.4266667,6.57333333 Z"
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
