<div class="selector-container">
  <div class="always-visible-container" (click)="onExpand()">
    <div class="left-side">
      <span class="selector-title">{{filterSelectorVM.filterTypeText}}</span>
      <ng-container *ngIf="appliedFilters.length">
        <span class="selector-count">{{appliedFilters.length}}</span>
        <app-button-transparent *ngIf="expanded" [text]="'Clear filters'" (clicked)="clearFilters()"></app-button-transparent>
      </ng-container>

    </div>
    <div class="right-side">
      <i class="material-icons expand-icon">expand_more</i>
    </div>
  </div>
  <div class="hidding-container" *ngIf="expanded">
    <app-list-search-input-fixed [placeholder]="T.common.search_filters | translate" [value]="searchText" (inputChanged)="onTextSearch($event)"></app-list-search-input-fixed>
    <ng-container *ngIf="searchFilteredAllowedFilters.length">
      <app-filters-list-entries
      class="custom-scrollbar"
      [entries]="searchFilteredAllowedFilters"
      [filterType]="filterSelectorVM.filterType"
      [useCheckbox]="true"
      [showPrimaryIcon]="filterSelectorVM.filterType === filterTypes.Department"
      [selectedEntries]="appliedFilters"
      (entrySelected)="onFilterSelected($event)"
      (primaryChanged)="onPrimaryChanged($event)"
      ></app-filters-list-entries>
      <app-filters-exclude-selected
        [checkState]="excludeState"
        [disabled]="!appliedFilters.length"
        (excluded)="onExclude($event)"
      >
      </app-filters-exclude-selected>
    </ng-container>
    <div class="empty-filters-message"
      *ngIf="!searchFilteredAllowedFilters.length">
      {{T.common.no_matching_filters | translate}}
    </div>
  </div>
</div>
