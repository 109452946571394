<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center" [ngClass]="{'no-border': !isPublicIncidentReport}">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      text="Cancel"
      (clicked)="confirmClose()"
    ></app-button-transparent>
    <span class="modal-title">{{
      T.common.add_item | translate: { item: T.common.linked_item.many | translate }
    }}</span>
    <span class="material-icons close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <!-- <app-button-transparent size="large" class="d-block d-md-none" text="Save" (clicked)="handleAction(addModalButtonOptions.Save_Close)"></app-button-transparent> -->
  </div>
  <div *ngIf="!isPublicIncidentReport" class="stream-container">
    <div class="stream-container-header">
      <div class="preview-label">{{ T.common.preview | translate }}:</div>
    </div>
    <div class="stream-container-main">
      <div class="stream-container-main-selected-item">
        <app-simple-card [cardData]="currentLinkedObj" [showCheckbox]="false"></app-simple-card>
      </div>
      <div class="stream-container-main-downstream card-stream-container-and-arrow">
        <div class="card-stream-container__arrow">
          <i class="material-icons">swap_vert</i>
        </div>
        <div class="downstream__item-container card-stream-container">
          <span class="selected-stream-text text-truncate" *ngIf="selectedItemsToLink.length > 0">{{
            T.common.count_item_selected
              | keyPlural: selectedItemsToLink.length
              | translate
                : {
                    count: selectedItemsToLink.length,
                    item: T.common.linked_item | keyPlural: selectedItemsToLink.length | translate
                  }
          }}</span>
          <span class="selected-stream-text text-truncate" *ngIf="selectedItemsToLink.length === 0">{{
            T.common.select_items_to_link | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="[isPublicIncidentReport ? 'mt-2' : 'main-selection-container']">
    <div *ngIf="!isPublicIncidentReport" class="main-selection-container-title">{{ T.common.select_item_type_to_link | translate }}:</div>
    <div *ngIf="!isPublicIncidentReport" class="main-selection-container-checkbox">
      <app-search-dropdown-r
        [objects]="allowedItemTypes"
        [bindProperty]="'title'"
        [label]="T.common.item_type_to_link | translate"
        (objectSelected)="onItemTypeSelected($event)"
        [multiSelect]="false"
        [currentlySelectedObject]="currentlySelectedAllowedType"
        [svg]="currentlySelectedAllowedType.svg"
      ></app-search-dropdown-r>
    </div>
    <app-list-header
      [searchPlaceholder]="searchPlaceholder"
      [showRAGBreakdown]="false"
      (onSearch)="onListSearch($event)"
    >
      <app-button-sort-rectangle-new
        [dropdownOptions]="sortDropdownItems"
        [text]="T.common.sort | translate"
        [buttonTheme]="'white'"
        class="mr-8"
      ></app-button-sort-rectangle-new>
    </app-list-header>
    <app-filters-simple
      *ngIf="!isPublicIncidentReport"
      [showBackdrop]="false"
      [appliedFilters]="filters"
      [objectTypes]="[currentlySelectedAllowedType.objectType]"
      (filtersUpdated)="onFiltersChanged($event)"
    ></app-filters-simple>
    <app-linked-items-list
      [redirectableCards]="false"
      [loading]="loading"
      [linkedItems]="filteredallowedItems"
      [selectedItems]="selectedItemsToLink"
      (selectedItemsChange)="onItemSelect($event)"
      [objectLinkDependencyType]="dependencyTypes.Downstream"
      [objectLinkType]="linkTypes.Relation"
    >
    </app-linked-items-list>
  </div>
  <div class="buttons-container">
    <app-button-rectangle
      class="button d-none d-md-block modal-confirnClose"
      [fitToContainer]="true"
      size="large"
      [text]="T.common.close | translate"
      buttonTheme="white"
      (clicked)="confirmClose()"
    ></app-button-rectangle>
    <app-button-rectangle
      class="button d-md-block modal-add"
      [fitToContainer]="true"
      size="large"
      [text]="
        T.common.add_count_item
          | translate
            : {
                count: selectedItemsToLink.length,
                item: T.common.linked_item | keyPlural: selectedItemsToLink.length | translate
              }
      "
      buttonTheme="primary"
      (clicked)="handleAction()"
    ></app-button-rectangle>
  </div>
</div>
