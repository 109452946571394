<div class="content-wrapper">
  <div class="header">
    <ng-container *ngIf="displayFiltersAsLozenges; else noLozengesTemplate">
      <app-details-lozenge [filters]="filters" [filterType]="filterTypes.Zone" [canEdit]="false">
      </app-details-lozenge>

      <app-details-lozenge [filters]="filters" [filterType]="filterTypes.Area" [canEdit]="false">
      </app-details-lozenge>
    </ng-container>
    <ng-template #noLozengesTemplate>
      <div class="filters-container">
        <div class="area-zone mb-3">
          <div class="zone d-flex justify-content-start mb-1">
            <div class="zone-icon">
              <i class="material-icons location-icon">location_searching</i>
            </div>
            <div class="zone-label">{{ objectTypes.Zone | localiseObjectType }}:</div>
            <div class="details-value">
              <ng-container *ngIf="zonesLabels">{{ zonesLabels }}</ng-container>
              <ng-container *ngIf="!zonesLabels">
                <span>{{ T.common.none | translate }}</span>
              </ng-container>
            </div>
          </div>
          <div class="area d-flex justify-content-start">
            <div class="area-icon">
              <i class="material-icons location-icon">gps_fixed</i>
            </div>
            <div class="area-label">{{ objectTypes.Area | localiseObjectType }}:</div>
            <div class="details-value">
              <ng-container *ngIf="areasLabels">{{ areasLabels }}</ng-container>
              <ng-container *ngIf="!areasLabels">
                <span>{{ T.common.none | translate }}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="footer">
    <app-incident-map *ngIf="locationAvailable; else noLocationTemplate"
      #incidentMap
      [objectId]="objectId"
      [markerType]="markerType"
      [markerColor]="markerColor"
      [defaultZoom]="15"
      [lat]="lat"
      [lng]="lng"
      [showMyLocationButton]="false"
      [showNoLocationButton]="false"
      [showSearchButton]="false"
      [editMode]="false"
      [showIncidentZonesUponLoad]="true"
    >
    </app-incident-map>

    <ng-template #noLocationTemplate>
      <div class="empty-space">
        <div class="icon">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="24px"
            height="24px"
            viewBox="0 0 45.354 45.354"
            style="enable-background: new 0 0 45.354 45.354"
            xml:space="preserve"
          >
            <g>
              <path
                fill="#939699"
                d="M22.677,0C12.509,0,4.266,8.243,4.266,18.411c0,9.224,11.471,21.36,16.305,26.065c1.184,1.15,3.056,1.174,4.263,0.047
                c4.863-4.533,16.254-16.211,16.254-26.113C41.087,8.243,32.845,0,22.677,0z M22.677,24.393c-4.204,0-7.61-3.406-7.61-7.609
                s3.406-7.61,7.61-7.61c4.203,0,7.608,3.406,7.608,7.61S26.88,24.393,22.677,24.393z"
              />
            </g>
          </svg>
        </div>
        {{ T.common.no_location_for_this_item | translate }}
      </div>
    </ng-template>

    <div *ngIf="showEditButton" class="mt-3">
      <app-button-rectangle
        [text]="T.common.edit_location | translate"
        icon="edit"
        buttonTheme="white"
        [disabled]="!editButtonEnabled"
        (clicked)="openLocationEditModal()"
      ></app-button-rectangle>
    </div>
  </div>
</div>
