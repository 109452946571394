<div class="inline-edit-wrapper" (click)="onClear($event)">
  <i class="material-icons material-icons-done">done</i>
  <i class="material-icons material-icons-close">highlight_off</i>
  <div
    class="filters-lozenge-inline-edit-text text-truncate"
    [app-tooltip]="{ message: text }"
    [class.primary]="isPrimary && showPrimaryIcon"
  >
    {{ text }}
  </div>
</div>

<ng-container *ngIf="showPrimaryIcon">
  <div class="primary-wrapper">
    <i
      (mouseover)="hovered = true"
      (mouseout)="hovered = false"
      class="material-icons material-icons-grade star"
      [class.material-icons-outlined]="!isPrimary || (isPrimary && hovered)"
      (click)="onStarClick($event)"
    >
      grade
    </i>
  </div>
</ng-container>
