<app-button-rectangle
  *ngIf="buttonType === 'Rectangle'"
  [icon]="icon"
  [text]="getButtonText"
  [size]="size"
  [fitToContainer]="fitToContainer"
  [buttonTheme]="buttonTheme"
  [svg]="getButtonSvg"
  (click)="$event.stopPropagation()"
  ngDropdown
  [templateRef]="dropdownTemplateBtn"
  [closeUponSelection]="closeUponSelection"
  [showDropdownArrow]="showDropdownArrow"
></app-button-rectangle>
<app-button-icon-square
  *ngIf="buttonType === 'IconSquare'"
  [icon]="icon"
  [size]="size"
  [buttonTheme]="buttonTheme"
  (click)="$event.stopPropagation()"
  ngDropdown
  [templateRef]="dropdownTemplateBtn"
  [closeUponSelection]="closeUponSelection"
></app-button-icon-square>
<ng-template #dropdownTemplateBtn>
  <div class="dropdown-options-container" [style.width.px]="dropdownWidth">
    <div
      class="dropdown-option"
      [class.selected]="option[bindProperty] === currentlySelectedOption[bindProperty]"
      *ngFor="let option of dropdownOptions"
      (click)="onDropdownItemSelected(option)"
    >
      <ng-container *ngIf="bindProperty">
        <span class="dropdown-option-text">{{ option[bindProperty] }}</span>
        <span
          *ngIf="option['svg'] && option['svg']?.length"
          class="btn-svg-icon"
          [innerHtml]="option['svg'] | safeHtml"
        ></span>
        <span
          class="material-icons dropdown-check"
          *ngIf="option[bindProperty] === currentlySelectedOption[bindProperty] && showSelectedOption && !option['svg']"
          >check</span
        >
      </ng-container>
    </div>
  </div>
</ng-template>
