<div *ngIf="loading; else contentView">
  <app-loading>
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
</div>

<ng-template #contentView>
  <app-modal>
    <app-modal-header
      [iconType]="modalHeaderIconType.Rich"
      [richIconType]="iconType"
      [title]="headerText"
      (closed)="close()"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="content-wrapper">
        <section class="header">
          <div class="top">
            <div class="item">
              <app-rag-dropdown
                [ragDropdownType]="'Badge'"
                [taskCardType]="'Task'"
                [disabled]="!canEdit"
                [showEditDropdownIcon]="true"
                [filterAccount]="account"
                [appliedFilters]="object.filters"
                (onFilterUpdated)="onRagUpdate($event)"
              >
              </app-rag-dropdown>
            </div>
            <div class="separator"></div>
            <div class="item milestone">
              <i *ngIf="isMilestone" class="material-icons milestone" [ngClass]="milestoneColor">flag</i>
              <span class="label" [ngClass]="{'bold-label': isMilestone}">{{ localizedMilestone }}</span>
            </div>
            <div class="separator"></div>

            <ng-container *ngIf="dueDateIndicator as indicator">
              <div class="item due-date-indicator" [style.background-color]="indicator.color">
                <span>{{ indicator.label }}</span>
              </div>
              <div class="separator"></div>
            </ng-container>

            <div *ngIf="ownersCount > 0" class="item">
              <app-avatar-filter-selector
                [filterType]="filterTypes.Owner"
                [filters]="object.filters"
                [canEdit]="false"
                [maxVisible]="6"
              ></app-avatar-filter-selector>
            </div>
          </div>
          <div class="bottom">
            <div class="title-wrapper">
              <app-simple-editable-field
                [input]="object.title"
                [canEdit]="canEdit"
                [fontSize]="20"
                (onUpdate)="onTitleUpdate($event)"
              ></app-simple-editable-field>
            </div>
            <div class="description-wrapper">
              <app-details-text-area
              [input]="object.description ? object.description : ''"
                [label]="T.defaultLocalizations.description.one | translate"
                [placeholder]="T.common.add_description | translate"
                [canEdit]="canEdit"
                (update)="onDescriptionUpdate($event)"
              ></app-details-text-area>
            </div>
            <div class="details-wrapper">
              <div class="details-row row">
                <div class="col-3 details-label">{{ T.common.start_date.one | translate }}</div>
                <div class="col-9 details-value">
                  <app-details-datepicker
                    [range]="true"
                    [date]="object.start"
                    [endDate]="object.due"
                    [canEdit]="canEdit"
                    [accordionLozenge]="true"
                    [label]="T.common.start_date.one | translate"
                    (datesChanged)="onDatesSaved($event)"
                  >
                  </app-details-datepicker>
                </div>
              </div>
              <div class="details-row row">
                <div class="col-3 details-label">{{ T.defaultLocalizations.due_date.one | translate }}</div>
                <div class="col-9 details-value">
                  <app-details-datepicker
                    [range]="true"
                    [date]="object.due"
                    [startDate]="this.object.start"
                    [endDate]="this.object.due"
                    [canEdit]="canEdit"
                    [accordionLozenge]="true"
                    [label]="T.defaultLocalizations.due_date.one | translate"
                    (datesChanged)="onDatesSaved($event)"
                  >
                  </app-details-datepicker>
                </div>
              </div>
              <div class="details-row row">
                <div class="col-3 details-label">{{ objectTypes.Task_Group | localiseObjectType }}</div>
                <div class="col-9 details-value">
                  <app-details-lozenge
                    [filters]="object.filters"
                    [filterType]="filterTypes.Task_Group"
                    [canEdit]="false"
                    [accordionLozenge]="true"
                    [accountId]="object.accountId"
                    [singleSelect]="true"
                    (onFiltersUpdated)="updateFilters($event, filterTypes.Task_Group)"
                  >
                  </app-details-lozenge>
                  <span class="task-group-details-view">
                    ({{taskGroup}})
                  </span>
                </div>
              </div>
              <div class="details-row row">
                <div class="col-3 details-label">{{ objectTypes.Project | localiseObjectType }}</div>
                <div class="col-9 details-value">
                  <app-details-lozenge
                    [filters]="object.filters"
                    [filterType]="filterTypes.Project"
                    [canEdit]="false"
                    [accordionLozenge]="true"
                    [accountId]="object.accountId"
                    [singleSelect]="true"
                    (onFiltersUpdated)="updateFilters($event, filterTypes.Project)"
                  >
                  </app-details-lozenge>
                  <span class="task-group-details-view">
                    ({{taskGroup}})
                  </span>
                </div>
              </div>
              <div class="details-row row">
                <div class="col-3 details-label">
                  {{ objectTypes.Department | localiseObjectType: true }}
                </div>
                <div class="col-9 details-value">
                  <app-details-lozenge
                    [filters]="object.filters"
                    [filterType]="filterTypes.Department"
                    [canEdit]="canEdit"
                    [accountId]="object.accountId"
                    [accordionLozenge]="true"
                    (onFiltersUpdated)="updateFilters($event, filterTypes.Department)"
                  >
                  </app-details-lozenge>
                </div>
              </div>
              <div class="details-row row">
                <div class="col-3 details-label">{{ T.defaultLocalizations.owner.one | translate }}</div>
                <div class="col-9 details-value">
                  <app-details-lozenge
                    [filters]="object.filters"
                    [filterType]="filterTypes.Owner"
                    [canEdit]="canEdit"
                    [singleSelect]="false"
                    [accountId]="object.accountId"
                    [accordionLozenge]="true"
                    (onFiltersUpdated)="updateFilters($event, filterTypes.Owner)"
                  >
                  </app-details-lozenge>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="body">
          <app-accordion-details [collapsing]="false" (onItemIndexClicked)="onItemIndexClicked($event)">
            <app-accordion-item-details [title]="'Additional Details & Tags'" [expanded]="false">
              <ng-template accordionContent>
                <div *ngIf="additionalDetailsExpanded" class="details-wrapper">
                  <div class="details-row row">
                    <div class="col-3 details-label">
                      {{ T.defaultLocalizations.event.one | translate }}
                    </div>
                    <div class="col-9 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [filters]="object.filters"
                        [filterType]="filterTypes.Event"
                        [canEdit]="false"
                        [accordionLozenge]="true"
                        (onFiltersUpdated)="updateFilters($event, filterTypes.Event)"
                        [singleSelect]="true"
                        [accountId]="object.accountId"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <app-details-tags
                      [accordionTagTitleClass]="'col-3'"
                      [accordionTagValueClass]="'col-9'"
                      [canEdit]="true"
                      [accordionLozenge]="true"
                      [filters]="object.filters"
                      [tagGroupObjectType]="objectTypes.Task"
                      [childAccountId]="object.accountId"
                      (onFiltersUpdated)="updateFilters($event, filterTypes.Tag)"
                    ></app-details-tags>
                  </div>
                </div>
              </ng-template>
            </app-accordion-item-details>
            <app-accordion-item-details [title]="'History'" [expanded]="false">
              <ng-template accordionContent>
                <div *ngIf="historyExpanded" class="history-feed-wrapper">
                  <app-history-feed-new
                    [isModal]="true"
                    [canEdit]="canEdit"
                    [showInput]="true"
                    [objectId]="object.id"
                    [objectType]="objectTypes.Task"
                    [showHeader]="false"
                    [singleItemHistory]="true"
                    [childHubAccountId]="object.accountId"
                    (historyEntriesCount)="onHistoryEntriesUpdate($event)"
                  >
                  </app-history-feed-new>
                </div>
              </ng-template>
            </app-accordion-item-details>
          </app-accordion-details>
        </section>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <div class="footer-wrapper">
        <app-button-rectangle
          [text]="'View Full Details'"
          [size]="'medium'"
          [buttonTheme]="'primary'"
          (clicked)="navigateToDetaislPage()"
        ></app-button-rectangle>
      </div>
    </app-modal-footer>
  </app-modal>
</ng-template>
