<div class="upload-container">
  <ng-container *ngIf="loading">
    <app-loading [displayLogo]="true"> </app-loading>
  </ng-container>
  <input #file type="file" [name]="'file-' + globalObjectId" class="inputfile" (change)="onSelectFile(file.files)" multiple/>
  <label appDragDrop (dropHandler)="onDropHandler($event)">
    <i class="material-icons"> cloud_upload </i>
    <ng-container *ngIf="isNativePlatform">
      <span class="title">{{ T.common.take_photo_or_upload_file | translate }}</span>
      <span class="span-btn" *ngIf="canAddFile" (click)="takePhoto()">{{ T.common.take_a_photo | translate }}</span>
      <span class="span-btn" *ngIf="canAddFile" style="margin-left: 8px" (click)="openFileUpload()">{{
        T.common.browse | translate
      }}</span>
      <span
        class="title"
        *ngIf="canAddLink"
        [innerHTML]="T.common.or_add_a_link | translate"
        (click)="addLink()"
      ></span>
    </ng-container>
    <ng-container *ngIf="!isNativePlatform">
      <span class="title">{{ T.common.drag_or_paste_files_here_to_upload | translate }}</span>
      <span class="span-btn" *ngIf="canAddFile" (click)="openFileUpload()">{{ T.common.browse | translate }}</span>
      <span
        class="title"
        *ngIf="canAddLink"
        [innerHTML]="T.common.or_add_a_link | translate"
        (click)="addLink()"
      ></span>
    </ng-container>
  </label>
</div>
