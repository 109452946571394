import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { IncidentDetailsItemViewModel } from 'src/app/modules/incidents/viewModels/incidentDetailsItemViewModel';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { IncidentLogAddModal } from '../../../../incidents/components/common/modals/incident-log-add/incident-log-add-modal.component';
import { IncidentItemTypes } from '../../../../incidents/enums/incidentItemTypes';
import { IncidentStatuses } from '../../../../incidents/enums/incidentStatuses';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { ActivityFeedItemType } from '../../../enums/activities/activityFeedItemType';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterTypes } from '../../../enums/filterTypes';
import { IncidentSeverities } from '../../../enums/incidents/incidentSeverities';
import { PrivacyStatuses } from '../../../enums/privacyStatuses';
import { Employee } from '../../../models/employee';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { CanCreatePipe } from '../../../pipes/can-create.pipe';
import { AuthenticationService } from '../../../services/authentication.service';
import { CachingService } from '../../../services/caching.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { IncidentsService } from '../../../services/incidents.service';
import { LocalisationService } from '../../../services/localisation.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { IncidentAddModalComponent } from 'src/app/modules/incidents/components/common/modals/incident-add/incident-add-modal.component';
import { ObjectTypes } from '../../../enums/objectTypes';
import { WtValidators } from '../../../reactiveValidators/wtValidators';
import { Account } from '../../../models/account';
import { FixedFilters } from '../../../enums/fixedFilters.enum';
import { ZoneViewModel } from '../../../viewModels/zoneViewModel';
import { LocationService } from '../../../services/location.service';
import { GMapUtilities } from '../../../utilities/gMap.utilities';
import { FilterToViewModelUtilityService } from '../../../services/utilities/filterToViewModelUtilityService';
import { FixedHeaderFiltersService } from '../../../services/fixed-header-filters.service';
import { ObjectEventEmitters } from '../../../events/object.events';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { FixedZoneFiltersService } from '../../../services/fixed-zone-filters.service';
import { QuickAddItemTypes } from '../../../enums/quickAddItemTypes';
import { IncidentCategoriesService } from 'src/app/modules/settings/services/incidents/incident-categories.service';
import { AccountSettingsService } from '../../../services/account-settings.service';
import { AccountSettingTypes } from '../../../enums/accountSettingTypes';

@Component({
  selector: 'app-incident-quick-add',
  templateUrl: './incident-quick-add.component.html',
  styleUrls: ['./incident-quick-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentQuickAddComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  @ViewChild('logInput') logInput: ElementRef<HTMLInputElement>;
  @ViewChild('incidentInput') incidentInput: ElementRef<HTMLInputElement>;
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  @Input() activityFeedItemType: ActivityFeedItemType;
  @Input() showQuickLogAdd: boolean = true;
  // Used in the EventDetailsComponent
  @Input() preselectedEventId: number;

  @Output() logAdded = new EventEmitter<IncidentDetailsItemViewModel>();
  @Output() incidentAdded= new EventEmitter<IncidentDetailsItemViewModel>();

  subscriptions = new Subscription();
  employee: Employee;
  mobile: boolean;
  bsModalRef: BsModalRef;
  dropdownItems: { value: string; activityFeedItemType: ActivityFeedItemType }[] = [
    { value: 'Log', activityFeedItemType: ActivityFeedItemType.Incident_Logs },
    { value: 'Incident', activityFeedItemType: ActivityFeedItemType.Incident },
  ];
  currentlySelectedDropdownItem: { value: string; activityFeedItemType: ActivityFeedItemType } = this.dropdownItems.find(
    (di) => di.activityFeedItemType === ActivityFeedItemType.Incident
  );
  logInputFormControl: UntypedFormControl = new UntypedFormControl('', {
    validators: [Validators.required, this.wtValidators.title(3, 250), this.wtValidators.restrictedChars([';', '<', '>', '!'])],
  });
  incidentInputFormControl: UntypedFormControl = new UntypedFormControl('', {
    validators: [Validators.required, this.wtValidators.title(3, 250), this.wtValidators.restrictedChars([';', '<', '>', '!'])],
  });
  userHasWriteAccess: boolean = false;
  channelId: number = 0;
  hasIncidentInput: boolean = false;
  private hasBeenOnInitExecuted = false;
  private allowedFilters: FilterViewModel[];
  public account: Account;
  public localisedLog: string = 'Log';
  public localisedIncident: string = 'Incident';
  public localisedDepartment: string = '';
  public logDropdownButtonClicked: boolean = false;
  public incidentDropdownButtonClicked: boolean = false;
  public isOwnerSelectedForAddingLog: boolean = true;
  public isDepartmentSelectedForAddingLog: boolean = true;
  public isOwnerSelectedForAddingIncident: boolean = true;
  public isDepartmentSelectedForAddingIncident: boolean = true;
  public filterTypes = FilterTypes;
  public quickAddItemTypes = QuickAddItemTypes;
  public incidentCategories: FilterViewModel[] = [];
  public categoryFilters: FilterViewModel[] = [];
  public mobileAddLogModal: boolean = false;
  public mobileAddIncidentModal: boolean = false;
  public initialSelectedZone: FilterViewModel;
  public logInputDisabled: boolean = false;
  public incidentInputDisabled: boolean = false;
  private modalConfig: ModalOptions = { backdrop: 'static', keyboard: false };
  private currentEmployee: Employee;
  private zones: ZoneViewModel[] = [];
  public readonly T = T;
  private dateFormatSettingValue: string;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bsModalService: BsModalService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly authenticationService: AuthenticationService,
    private readonly incidentsService: IncidentsService,
    private readonly router: Router,
    private readonly fixedHeaderFiltersService: FixedHeaderFiltersService,
    private readonly cachingService: CachingService,
    private readonly canCreatePipe: CanCreatePipe,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly localisationService: LocalisationService,
    private readonly locationService: LocationService,
    private readonly filterToViewModelUtilityService: FilterToViewModelUtilityService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private eRef: ElementRef,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    private readonly fixedZoneFiltersService: FixedZoneFiltersService,
    private readonly wtValidators: WtValidators,
    private readonly incidentCategoriesService: IncidentCategoriesService,
    private readonly accountSettingsService: AccountSettingsService,
  ) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.resetInputValues();
    }
  }

  ngOnInit() {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    const addingLogSettings = this.cachingService.getAddLogSettings();
    const addingIncidentSettings = this.cachingService.getAddIncidentSettings();

    if (addingLogSettings) {
      this.isOwnerSelectedForAddingLog = addingLogSettings.isOwnerSelected;
      this.isDepartmentSelectedForAddingLog = addingLogSettings.isDepartmentSelected;
    }

    if (addingIncidentSettings) {
      this.isOwnerSelectedForAddingIncident = addingIncidentSettings.isOwnerSelected;
      this.isDepartmentSelectedForAddingIncident = addingIncidentSettings.isDepartmentSelected;
    }

    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.userHasWriteAccess = this.canCreatePipe.transform(ModuleTypes.Incidents, FilterTypes.Incident);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);

    this.channelId = this.employee.defaultIncidentChannel;

    this.locationService.getZones().subscribe((res) => {
      this.zones = res;
      this.initAllowedFilters();
      this.initCategories();
      this.initIncidentChannelId();
      this.setSelectedDropdownItem();
      this.hasBeenOnInitExecuted = true;
      this.changeDetectorRef.detectChanges();
    });

    this.subscriptions.add(
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Date_Format)
      .subscribe((dateFormatSetting) => {
      this.dateFormatSettingValue = dateFormatSetting.value.toUpperCase();
    })
  );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    if (this.hasBeenOnInitExecuted) {
      if (!this.userHasWriteAccess) {
        this.activityFeedItemType = ActivityFeedItemType.Incident_Logs;
      }

      if (this.activityFeedItemType) {
        this.currentlySelectedDropdownItem = this.dropdownItems.find(
          (di) => di.activityFeedItemType === this.activityFeedItemType
        );
      }
    }
  }

  ngAfterViewInit() {
    this.initMobile();
  }

  onResized() {
    this.initMobile();
  }

  initIncidentChannelId() {
    this.fixedHeaderFiltersService.filtersChanged$.subscribe((fl) => {
      const channelFl = fl.find((a) => a.filterType === FilterTypes.Incident_Channel);
      if (channelFl) {
        this.channelId = +channelFl.filterValue;
      }
    });
  }

  get isLogsRoute(): boolean {
    return this.router.url.startsWith('/v2/control/logs');
  }

  @HostListener('document:keydown.enter', ['$event']) onEnterPressed() {
    if (!this.wrapper) {
      return;
    }

    if (this.logInputFormControl.value) {
      if (this.logInputFormControl.valid) {
        this.addLog(this.logInputFormControl.value as string);
        this.resetInputValues();
        this.logInput.nativeElement.blur();
      } else {
        this.showErrorToastForInvalidTitle();
      }

      if (this.mobile) {
        this.mobileAddLogModal = false;
        this.mobileAddIncidentModal = false;
        this.bsModalService.hide();
      }
    }

    if (this.incidentInputFormControl.value) {
      if (this.incidentInputFormControl.valid) {
        this.addIncident(this.incidentInputFormControl.value as string);
        this.resetInputValues();
        this.incidentInput.nativeElement.blur();
      } else {
        this.showErrorToastForInvalidTitle();
      }

      if (this.mobile) {
        this.mobileAddLogModal = false;
        this.mobileAddIncidentModal = false;
        this.bsModalService.hide();
      }
    }
  }

  private addLog(title: string) {
    this.logInputDisabled = true;
    let logItem = new IncidentDetailsItemViewModel();
    logItem.accountId = this.employee.accountId;
    logItem.incidentItemType = IncidentItemTypes.Log;
    logItem.title = title;
    logItem.incidentChannelId = this.channelId;
    logItem.filters = [];
    logItem.startTime = moment().toISOString();

    if (this.allowedFilters && this.isOwnerSelectedForAddingLog) {
      const empFilter = this.allowedFilters.find(
        s => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter && logItem.filters.indexOf(empFilter) < 0) {
        logItem.filters = logItem.filters.slice();
        logItem.filters.push(empFilter);
      }
    }

    if (this.allowedFilters && this.isDepartmentSelectedForAddingLog) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (empFilter && logItem.filters.indexOf(empFilter) < 0) {
        logItem.filters = logItem.filters.slice();
        logItem.filters.push(empFilter);
      }
    }

    if (this.account.fixedFilters === FixedFilters.Event) {
      logItem.eventId = +this.cachingService.selectedEventID;
      logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, logItem.eventId, '', undefined));
    } else if (this.account.fixedFilters === FixedFilters.VenueZone) {
      this.initialSelectedZone = this.fixedZoneFiltersService.fixedFilter;

      logItem.filters.push(this.initialSelectedZone);
      logItem = this.setInitialZonePin(logItem);
    }

    if (this.categoryFilters.length) {
      logItem.filters = logItem.filters.concat(this.categoryFilters);
    }

    logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Title, title));
    logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Item_Type, IncidentItemTypes.Log));
    logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Channel, this.channelId));
    logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, logItem.eventId));

    this.incidentsService.add(logItem).subscribe((added) => {
      const model = added.returnModel as IncidentDetailsItemViewModel;

      this.objectEventEmitters.broadcastObjectAdded(model.id, ObjectTypes.IncidentItem, model);
      void this.alertService.success(this.translateService.instant(T.common.item_was_added_successfully, { item: this.localisedLog }));
      this.logInputDisabled = false;
      this.changeDetectorRef.markForCheck();
      this.logAdded.next(model);
    });
  }

  private addIncident(title: string) {
    this.incidentInputDisabled = true;
    let incidentItem = new IncidentDetailsItemViewModel();
    incidentItem.title = title;
    incidentItem.createdBy = this.employee;
    incidentItem.severity = IncidentSeverities.Severity_1;
    incidentItem.accountId = this.employee.accountId;
    incidentItem.privacyStatus = PrivacyStatuses.Open;
    incidentItem.status = IncidentStatuses.Open;
    incidentItem.incidentChannelId = this.channelId;
    incidentItem.incidentItemType = IncidentItemTypes.Incident;
    incidentItem.startTime = moment().toISOString();
    incidentItem.filters = [];

    if (this.allowedFilters && this.isOwnerSelectedForAddingIncident) {
      const empFilter = this.allowedFilters.find(
        s => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter && incidentItem.filters.indexOf(empFilter) < 0) {
        incidentItem.filters = incidentItem.filters.slice();
        incidentItem.filters.push(empFilter);
      }
    }

    if (this.allowedFilters && this.isDepartmentSelectedForAddingIncident) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (empFilter && incidentItem.filters.indexOf(empFilter) < 0) {
        incidentItem.filters = incidentItem.filters.slice();
        incidentItem.filters.push(empFilter);
      }
    }

    incidentItem.filters.push(
      FilterUtilities.GenerateFilter(FilterTypes.Date, moment().toString(), '', FilterDateOptions.Start_Date)
    );
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Title, title));
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Item_Type, IncidentItemTypes.Incident));
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Channel, this.channelId));
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Privacy_Status, PrivacyStatuses.Open));
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Status, IncidentStatuses.Open));
    incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Severity, IncidentSeverities.Severity_1));

    if(this.preselectedEventId) {
      incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event,this.preselectedEventId));
      incidentItem.eventId = this.preselectedEventId;
    }

    if (this.account.fixedFilters === FixedFilters.Event && !this.preselectedEventId) {
      incidentItem.eventId = +this.cachingService.selectedEventID;
      incidentItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, incidentItem.eventId, '', undefined));
    } else if (this.account.fixedFilters === FixedFilters.VenueZone && !this.preselectedEventId) {
      this.initialSelectedZone = FilterUtilities.GenerateFilter(
        FilterTypes.Zone,
        this.cachingService.selectedZoneID,
        '',
        undefined
      );
      incidentItem.filters.push(this.initialSelectedZone);
      incidentItem = this.setInitialZonePin(incidentItem);
    }

    if (this.categoryFilters.length) {
      incidentItem.filters = incidentItem.filters.concat(this.categoryFilters);
      this.categoryFilters.forEach(filter => {
        this.getSelectedIncidentCategory(filter.filterValue as number, incidentItem)
      })
    } else {
      this.saveIncident(incidentItem);
    }
  }

  private saveIncident(incidentItem: IncidentDetailsItemViewModel) {
    this.incidentsService.add(incidentItem).subscribe((added) => {
      const model = added.returnModel as IncidentDetailsItemViewModel;

      this.objectEventEmitters.broadcastObjectAdded(model.id, ObjectTypes.IncidentItem, model);
      void this.alertService.success(
        this.translateService.instant(T.common.item_was_added_successfully, { item: this.localisedIncident })
      );
      this.incidentInputDisabled = false;
      this.changeDetectorRef.markForCheck();
      this.incidentAdded.next(model);
    });
  }

  public getSelectedIncidentCategory(categoryId: number, incident: IncidentDetailsItemViewModel) {
    return this.incidentCategoriesService.details(categoryId).subscribe(category => {

      if(category.severity && category.severity > 0)
      {
        incident.severity = category.severity;
        incident.filters.find(f => f.filterType === FilterTypes.Incident_Severity).filterValue = category.severity;
      }

      if (category.privacyStatus !== PrivacyStatuses.Open) {
        incident.privacyStatus = category.privacyStatus;
        incident.filters.find(x => x.filterType === FilterTypes.Privacy_Status).filterValue = category.privacyStatus;
      }

      category.filters.forEach(filter => {
       if (filter.filterType === FilterTypes.Owner || filter.filterType === FilterTypes.Department || filter.filterType === FilterTypes.User_Group) {
        incident.filters = incident.filters.filter(x => x.filterType !== filter.filterType);
        if(incident.filters.find(x => x.filterType === filter.filterType && x.filterValue == filter.filterValue) === undefined) {
          incident.filters.push(filter);
        }
       }
       if (filter.filterType === FilterTypes.Description && filter.filterValue !== '') {
        incident.filters.push(filter);
        incident.description = filter.filterValue as string;
       }
      });

      this.saveIncident(incident);
    })
  }

  onFocus() {
    if (this.ngDropdownDirective && (this.incidentDropdownButtonClicked || this.logDropdownButtonClicked)) {
      this.ngDropdownDirective.ngDropdownComponentInstance.visible = true;
      this.ngDropdownDirective.ngDropdownComponentInstance.show();
    }

    this.changeDetectorRef.detectChanges();
  }

  onBlur() {
    this.changeDetectorRef.detectChanges();
  }

  isFocused(element: HTMLElement): boolean {
    return element === document.activeElement;
  }

  onAddLogDetails() {
    const initialState = {
      filters: [],
      setDefaultOwner: true,
      setDefaultDepartment: true,
      quickAddTitle: '',
    };

    if (!this.isOwnerSelectedForAddingLog) {
      initialState.setDefaultOwner = false;
    }

    if (!this.isDepartmentSelectedForAddingLog) {
      initialState.setDefaultDepartment = false;
    }

    if (this.categoryFilters.length) {
      initialState.filters = initialState.filters.concat(this.categoryFilters);
    }

    if (this.logInputFormControl.value) {
      initialState.quickAddTitle = this.logInputFormControl.value as string;
    }

    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.bsModalService.show(IncidentLogAddModal, modalParams);

    this.subscriptions.add(
      this.bsModalRef.content.closed.subscribe((res) => {
        this.bsModalService.hide();
      })
    );

    this.bsModalEventsEmitter.broadcastBsModalStateChanged({
      loaded: true,
    });

    this.resetInputValues();
    this.logInput.nativeElement.focus();
  }

  onAddIncidentDetails() {
    const initialState = {
      filters: [],
      quickAddTitle: '',
      preselectedCategoryId: undefined,
    };

    if(this.preselectedEventId) {
      const preselectedEventFilter = FilterUtilities.GenerateFilter(FilterTypes.Event, this.preselectedEventId);
      initialState.filters.push(preselectedEventFilter);
    }

    if (this.incidentInputFormControl.value) {
      initialState.quickAddTitle = this.incidentInputFormControl.value as string;
    }

    if (this.categoryFilters.length) {
      initialState.filters = initialState.filters.concat(this.categoryFilters);
      initialState.preselectedCategoryId = this.categoryFilters[0].filterValue as number;
    }

    if (this.isOwnerSelectedForAddingIncident && !initialState.preselectedCategoryId) {
      const empFilter = this.allowedFilters.find(
        s => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && initialState.filters.indexOf(empFilter) < 0) {
        initialState.filters = initialState.filters.slice();
        initialState.filters.push(empFilter);
      }
    }

    if (this.isDepartmentSelectedForAddingIncident && !initialState.preselectedCategoryId) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (empFilter && initialState.filters.indexOf(empFilter) < 0) {
        initialState.filters = initialState.filters.slice();
        initialState.filters.push(empFilter);
      }
    }

    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.bsModalService.show(IncidentAddModalComponent, modalParams);

    this.subscriptions.add(
      this.bsModalRef.content.closed.subscribe((res) => {
        this.bsModalService.hide();
      })
    );

    this.bsModalEventsEmitter.broadcastBsModalStateChanged({
      loaded: true,
    });

    this.resetInputValues();
    this.incidentInput.nativeElement.focus();
  }

  private initMobile() {
    this.mobile = window.innerWidth <= Constants.sm;

    this.changeDetectorRef.detectChanges();
  }

  setSelectedDropdownItem() {
    if (this.activityFeedItemType === ActivityFeedItemType.Incident) {
      this.currentlySelectedDropdownItem = this.dropdownItems.find(
        (x) => x.activityFeedItemType === ActivityFeedItemType.Incident
      );
    } else if (this.activityFeedItemType === ActivityFeedItemType.Incident_Logs) {
      this.currentlySelectedDropdownItem = this.dropdownItems.find(
        (x) => x.activityFeedItemType === ActivityFeedItemType.Incident_Logs
      );
    }
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
  }

  logDropdownToggled() {
    if (!this.logInputFormControl.value && !this.mobile) {
      this.categoryFilters = [];
      this.logDropdownButtonClicked = !this.logDropdownButtonClicked;
      if(this.incidentDropdownButtonClicked) {
        this.incidentDropdownButtonClicked = false;
      } else {
        this.incidentDropdownButtonClicked = undefined;
      }

      if (this.logInputFormControl.value && this.logDropdownButtonClicked === false) {
        this.logInputFormControl.reset();
      }
      if (this.incidentInputFormControl.value && this.incidentDropdownButtonClicked === false) {
        this.incidentInputFormControl.reset();
      }
    } else {
      this.logInput.nativeElement.focus();
    }

    if (this.logDropdownButtonClicked) {
      this.onFocus();
    }
  }

  incidentDropdownToggled() {
    if (!this.incidentInputFormControl.value && !this.mobile) {
      this.categoryFilters = [];
      this.incidentDropdownButtonClicked = !this.incidentDropdownButtonClicked;
      if (this.logDropdownButtonClicked) {
        this.logDropdownButtonClicked = false;
      }
      else {
        this.logDropdownButtonClicked = undefined;
      }

      if (this.incidentInputFormControl.value && this.incidentDropdownButtonClicked === false) {
        this.incidentInputFormControl.reset();
      }
      if (this.logInputFormControl.value && this.logDropdownButtonClicked === false) {
        this.logInputFormControl.reset();

      }
    } else {
      this.incidentInput.nativeElement.focus();
    }

    if (this.incidentDropdownButtonClicked) {
      this.hasIncidentInput = false;
      this.onFocus();
    }
  }

  initCategories() {
    this.incidentCategories = this.allowedFilters
      .filter((x) => x.filterType === this.filterTypes.Incident_Category)
      .sort((a, b) => a.filterText.localeCompare(b.filterText));
  }

  objectIsSelected(object: FilterViewModel) {
    return !!this.categoryFilters.find((x) => x.filterValue === object.filterValue);
  }

  onObjectSelected(object: FilterViewModel, type: QuickAddItemTypes) {
    if (this.categoryFilters.find((x) => x.filterValue === object.filterValue)) {
      this.categoryFilters = this.categoryFilters.filter((x) => x.filterValue !== object.filterValue);
    }
    else {
      if (type === QuickAddItemTypes.Incident) {
        this.categoryFilters = [];
        if (!this.incidentInputFormControl.value) {
          this.hasIncidentInput = true;
        }
        else if (this.hasIncidentInput === true) {
          this.incidentInputFormControl.reset();
        }
        this.categoryFilters.push(object);
      }
      else {
        this.categoryFilters.push(object);
      }
    }

    if (!this.logInputFormControl.value && type === QuickAddItemTypes.Log && this.categoryFilters.length) {
      this.logInputFormControl.setValue(
        this.translateService.instant(T.control.category_log_on_date, {
          category: object.filterText,
          date: moment().format(this.dateFormatSettingValue),
        })
      );
      this.logInputFormControl.markAsDirty();
    }

    if (!this.incidentInputFormControl.value && type === QuickAddItemTypes.Incident && this.categoryFilters.length) {
      this.incidentInputFormControl.setValue(
        this.translateService.instant(T.control.category_incident_on_date, {
          category: object.filterText,
          date: moment().format(this.dateFormatSettingValue),
        })
      );
      this.incidentInputFormControl.markAsDirty();
    }

    this.changeDetectorRef.markForCheck();
  }

  openMobileAddModal(type: QuickAddItemTypes | null) {
    if (type === null) {
      this.mobileAddLogModal = false;
      this.mobileAddIncidentModal = false;
      this.bsModalService.hide();
    }
    else if (type === QuickAddItemTypes.Log) {
      const initialState = { mobileAddLogModal: true };
      const modalParams = Object.assign({}, this.modalConfig, { initialState });
      this.bsModalRef = this.bsModalService.show(IncidentQuickAddComponent, modalParams);
    } else if (QuickAddItemTypes.Incident) {
      const initialState = { mobileAddIncidentModal: true };
      const modalParams = Object.assign({}, this.modalConfig, { initialState });
      this.bsModalRef = this.bsModalService.show(IncidentQuickAddComponent, modalParams);
    }
  }

  toggleLogsOwnerCheckbox(): void {
    this.isOwnerSelectedForAddingLog = !this.isOwnerSelectedForAddingLog;
    this.saveSettings();
  }

  toggleLogsDepartmentCheckbox(): void {
    this.isDepartmentSelectedForAddingLog = !this.isDepartmentSelectedForAddingLog;
    this.saveSettings();
  }

  toggleIncidentsOwnerCheckbox(): void {
    this.isOwnerSelectedForAddingIncident = !this.isOwnerSelectedForAddingIncident;
    this.saveSettings();
  }

  toggleIncidentsDepartmentCheckbox(): void {
    this.isDepartmentSelectedForAddingIncident = !this.isDepartmentSelectedForAddingIncident;
    this.saveSettings();
  }

  private saveSettings(): void {
    this.cachingService.setAddLogSettings({
      isOwnerSelected: this.isOwnerSelectedForAddingLog,
      isDepartmentSelected: this.isDepartmentSelectedForAddingLog,
    });
    this.cachingService.setAddIncidentSettings({
      isOwnerSelected: this.isOwnerSelectedForAddingIncident,
      isDepartmentSelected: this.isDepartmentSelectedForAddingIncident,
    });
  }

  resetInputValues() {
    this.logDropdownButtonClicked = false;
    this.incidentDropdownButtonClicked = false;
    this.logInputFormControl.reset();
    this.incidentInputFormControl.reset();
  }

  addButtonPressed(e: MouseEvent) {
    e.stopPropagation();

    if (this.incidentDropdownButtonClicked || this.logDropdownButtonClicked) {
      this.onEnterPressed();
    }
  }

  setInitialZonePin(item: IncidentDetailsItemViewModel) {
    const itemZone = this.filterToViewModelUtilityService.getZoneViewModelsFromFilters(item.filters, this.zones);

    if (!itemZone || itemZone.length === 0) {
      item.filters = item.filters.filter((x) => x.filterType !== this.filterTypes.Zone);
      return item;
    }

    const polygon = GMapUtilities.buildPolygonFromZoneModel(itemZone[0].zoneShapePoints, null);
    const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
    const polygonCenterLatLng = polyLatLngBounds.getCenter();

    item.latitude = polygonCenterLatLng.lat();
    item.longitude = polygonCenterLatLng.lng();

    return item;
  }

  /**
   * Shows error toast when title contains invalid special characters ( ; < > !).
   */
  private showErrorToastForInvalidTitle(): void {
    void this.alertService.error(this.translateService.instant(T.common.title_cant_contain_special_chars));
  }
}
