<div class="lozenge-time-range" [class.error-range-lozenge]="errorTimeOffset">
  <div class="start-time">
    <span class="hours-minutes">
      <span *ngIf="startDateISO">{{ startDateISO | localisedTime }}</span>
      <span *ngIf="!startDateISO">--:--</span>
    </span>
    <span class="day-offset">
      <span *ngIf="startTimeOffset">{{ startTimeOffset }}</span>
    </span>
  </div>
  <div class="end-time">
    <span class="hours-minutes">
      <span *ngIf="endDateISO">{{ endDateISO | localisedTime }}</span>
      <span *ngIf="!endDateISO">--:--</span>
    </span>
    <span class="day-offset">
      <span *ngIf="endTimeOffset">{{ endTimeOffset }}</span>
    </span>
  </div>
  <div class="icon-container">
    <i class="material-icons" *ngIf="showLock">lock</i>
  </div>
</div>
