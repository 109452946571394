<div
  class="wrapper"
  [class.lozenge-height]="lozengeHeight"
  [class.edit-mode-border]="editMode"
  [class.input-error]="!fieldIsValid && editMode"
>
  <ng-container *ngIf="editMode">
    <div #inputWrapper class="input-simple-wrapper">
      <input *ngIf="editStateRows === 1" autoFocusDirective="true" #inputElement type="text" [(ngModel)]="input" />
      <textarea *ngIf="editStateRows > 1" autoFocusDirective="true" #inputElement [(ngModel)]="input" rows="3">

      </textarea>
    </div>
  </ng-container>
  <ng-container *ngIf="!editMode">
    <div
      class="text-field text-truncate"
      [style.-webkit-line-clamp]="lineClamp"
      [style.width]="customWidth ? customWidth : '100%'"
      [style.font-size]="fontSize + 'px'"
      [class.lozenge-padding]="lozengePadding"
      [app-tooltip]="{ message: input, position: ['top'] }"
      (click)="toggleEditMode()"
    >
      {{ input }}
      <i *ngIf="canEdit"
      class="material-icons normal-icon ms-2"
      style="font-size: 16px; line-height: 16px; color: var(--wt-primary);"
       >edit</i>
    </div>
  </ng-container>
</div>
